import { IonCol, IonContent, IonGrid, IonPage, IonRow } from "@ionic/react";
import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import ResponsiveHeader from "../components/ResponsiveHeader";
import { ContentItem } from "../models/GeneralModels";
import { endpoints, getItem } from "../services/data";
import './Item.css';
interface UserDetailPageProps extends RouteComponentProps<{
  id: string;
  type:string;
}> {}
const Item: React.FC<UserDetailPageProps> = ({match, history}) => {
  const [item, setItem] = useState<any>(null);
  useEffect(() => {
    const getData = async () => {
      let item:ContentItem = await getItem(match.params.id, match.params.type);
      setItem(item);
    }
    getData();
  }, [])

  return (
    item == null ? <></> :
    <IonPage>
      <ResponsiveHeader />
      {item.type == 'games' ? 
      <iframe className="game" src={endpoints[item.type]+item.link} ></iframe> :
      <IonContent>
        <IonGrid fixed>
          <IonRow className="testrow ion-margin-top">
            <h1 className="w100"><strong>{item.type.toUpperCase()}</strong> / {item.title}</h1>
            <p>{item.desc}</p>

              <IonCol size="12">
                <div>
                  <video className="w100" controls>
                    <source src={endpoints[item.type]+item.link} type="video/mp4" />
                  </video>
                </div>
              </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      }
    </IonPage>
    
  );
}

export default Item;