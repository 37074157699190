import {
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRouterOutlet,
  IonRow,
} from "@ionic/react";
import {
  ReactChild,
  ReactFragment,
  ReactPortal,
  useEffect,
  useState,
} from "react";
import {
  Route,
  RouteComponentProps,
  useLocation,
  useParams,
  withRouter,
} from "react-router";
import ItemContainer from "../components/ItemContainer";
import { ProtectedRoute } from "../components/ProtectedRoute";
import ResponsiveHeader from "../components/ResponsiveHeader";
import { ContentItem } from "../models/GeneralModels";
import { Config } from "../services/config";
import {
  ContentCategory,
  endpoints,
  getCategories,
  getRandomItemsFromType,
} from "../services/data";
import "./Categories.css";
import Category from "./Category";
import Item from "./Item";
interface Categories {
  cat: string;
  name: string;
}

interface CategoriesProps extends RouteComponentProps {
  category: string;
  categories: any;
}

const MainCategory: React.FC<CategoriesProps> = ({
  history,
  match,
  categories,
  category,
}) => {
  const { pathname } = useLocation();
  const renderCategories = (categories: any[]) => {
    if (categories !== null) {
      return categories.map((category: Categories, index: number) => {
        return (
          <IonCol
            onClick={() => {
              history.push(`${pathname}/${category.cat}`);
            }}
            key={category.cat}
            size="12"
            size-md="3"
          >
            <div>
              <p>{category.name}</p>
            </div>
          </IonCol>
        );
      });
    }
  };
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    const getAll = async () => {
      let data = await Promise.all([
        await getRandomItemsFromType(category, 100),
      ]);
      console.log(data[0][0].name);
      setData({
        data: {
          name: "كل المحتوى",
          type: "fashion",
          items: data[0],
        },
      });
    };

    getAll();
  }, []);
  return data == null ? (
    <></>
  ) : (
    <IonPage>
      <ResponsiveHeader />
      <IonContent>
        <IonGrid fixed>
          {Config().brand.name === "iq.boxplay" && (
            <ItemContainer
              key={data.data.type}
              isHorizontal={true}
              items={data.data.items}
              containerName={data.data.name}
            />
          )}
          {Config().brand.name !== "iq.boxplay" && (
            <>
              <h1 id="home">
                {category.charAt(0).toUpperCase() + category.slice(1)}
              </h1>
              <IonRow className="categorybg">
                {categories !== null ? (
                  renderCategories(categories)
                ) : (
                  <>
                    <div>No items in {category} found.</div>
                  </>
                )}
              </IonRow>
            </>
          )}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

const Categories: React.FC<RouteComponentProps> = ({ match }) => {
  const { pathname } = useLocation();
  const category = pathname.replace("/", "");
  const [categories, setCategories] = useState(null);
  //Check if user is logged in
  useEffect(() => {
    const category = pathname.replace("/", "");
    const getData = async () => {
      if (category in ContentCategory) {
        let categories: any = await getCategories(category);
        setCategories(categories);
      }
    };

    getData();
  }, []);

  return (
    <>
      <IonRouterOutlet>
        <Route
          exact
          path={match.url}
          render={(props) => (
            <MainCategory
              category={category}
              categories={categories}
              {...props}
            />
          )}
        />
        <Route
          path={`/:category/:subcategory`}
          render={(props) => <Category {...props} />}
        />
      </IonRouterOutlet>
    </>
  );
};

export default Categories;
