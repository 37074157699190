import { GlobalActions } from "./global.actions";
import { GlobalState } from "./global.state";

export function globalReducer(
  state: GlobalState,
  action: GlobalActions
): GlobalState {
  switch (action.type) {
    case "set-user-loading":
      return { ...state, loading: action.isLoading };
    case "set-dark-mode":
      console.log("setting dark/light mode");
      return { ...state, darkMode: action.darkMode };
  }
}
