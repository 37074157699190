import { IonCol, IonContent, IonGrid, IonPage, IonRow } from "@ionic/react";
import { useEffect, useState } from "react";
import { RouteComponentProps, useLocation, useParams } from "react-router";
import ItemContainer from "../components/ItemContainer";
import ResponsiveHeader from "../components/ResponsiveHeader";
import { getCategoryItems } from "../services/data";
import "./Category.css";

interface CategoryProps
  extends RouteComponentProps<{ subcategory: string; category: string }> {}

const Category: React.FC<CategoryProps> = ({ match }) => {
  //Check if user is logged in
  const [subcategory, setSubcategory] = useState<any>(null);
  useEffect(() => {
    const getData = async () => {
      let subcategory: any = await getCategoryItems(
        match.params.category,
        match.params.subcategory
      );
      setSubcategory(subcategory);
    };
    getData();
  }, []);

  return subcategory == null ? (
    <></>
  ) : (
    <IonPage>
      <ResponsiveHeader />
      <IonContent>
        <IonGrid fixed className="ion-margin-bottom">
          <ItemContainer
            isHorizontal={false}
            items={subcategory.items}
            containerName={subcategory.name}
          />
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Category;
