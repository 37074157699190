import { RouteComponentProps, withRouter } from "react-router";
import { ContentItem } from "../models/GeneralModels";
import { endpoints } from "../services/data";

interface ItemContainerProps extends RouteComponentProps {
  containerName: string;
  isHorizontal: boolean;
  items?: ContentItem[];
}
const ItemContainer: React.FC<ItemContainerProps> = ({match, containerName, isHorizontal, history, items}) =>{
  const redirectToItem = (id:any, type:any) => {
    history.push('/home/'+type+'/'+id);
  }

  const renderItems = (items:ContentItem[]) => {
    return items.map((item:any, index:number) => {
      let imgUrl =  endpoints[item.type]+item.name;
      return (
        <img onClick={()=>{redirectToItem(item.id, item.type)}} key={index+'item'} src={imgUrl} />
      )
    });
  }

  return (
    !items? <></> :
    <>
    <h1 id="home">{containerName}</h1>
    <div className={isHorizontal? 'box':'box noHorizontal'}>
      {renderItems(items)}
    </div>
    </>
  )
};
export default  withRouter(ItemContainer);;