import { IonContent, IonGrid, IonPage } from "@ionic/react";
import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import ItemContainer from "../components/ItemContainer";
import ResponsiveHeader from "../components/ResponsiveHeader";
import { Config } from "../services/config";
import {
  getRandomItemsFromType,
  getRandomItemsFromTypes,
} from "../services/data";
import Modal from "react-modal";

Modal.setAppElement("#root");
const Home: React.FC<RouteComponentProps> = ({ history }) => {
  const [sections, setSections] = useState<any>(null);
  const [isOpen, setIsOpen] = useState(false);

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  const strings = {
    en: {
      fashion: "Hot in Fashion",
      fitness: "Popular Fitness",
      games: "Fun for all",
      recipes: "Recommended Recipes",
      popular: `Popular on ${Config().brand.title}`,
    },
    fr: {
      fashion: "Chaud dans la mode",
      fitness: "Remise en forme populaire",
      games: "Amusement pour tous",
      recipes: "Recettes recommandées",
      popular: `Populaire sur ${Config().brand.title}`,
    },
    pl: {
      fashion: "Gorąco w modzie",
      fitness: "Popularne fitness",
      games: "Zabawa dla wszystkich",
      recipes: "Polecane przepisy",
      popular: `Popularne w ${Config().brand.title}`,
    },
    ua: {
      fashion: "حار في الموضة",
      fitness: "شعبية لياقة",
      games: "متعة للجميع",
      recipes: "الوصفات الموصى بها",
      popular: `شعبية على ${Config().brand.title}`,
    },
    hr: {
      fashion: "Vruće u modi",
      fitness: "Popularna kondicija",
      games: "Zabava za sve",
      recipes: "Preporučeni recepti",
      popular: `Popularno na ${Config().brand.title}`,
    },
    fi: {
      fashion: "Kuuma muodissa",
      fitness: "Suosittu kuntoilu",
      games: "Hauskaa kaikille",
      recipes: "Suositellut reseptit",
      popular: `Suosittu ${Config().brand.title}`,
    },
  };

  let defaultStrings = strings.en;

  if (Config().brand.name === "pl.boxplay") {
    defaultStrings = strings.pl;
  } else if (Config().brand.name === "pl.finestscreen") {
    defaultStrings = strings.pl;
  } else if (Config().brand.name === "fr.finestscreen") {
    defaultStrings = strings.fr;
  } else if (Config().brand.name === "fr.boxplay") {
    defaultStrings = strings.fr;
  } else if (Config().brand.name === "iq.boxplay") {
    defaultStrings = strings.ua;
  } else if (Config().brand.name === "hr.boxplay") {
    defaultStrings = strings.hr;
  } else if (Config().brand.name === "fi.boxplay") {
    defaultStrings = strings.fi;
  } else {
    defaultStrings = strings.en;
  }
  console.log(Config().brand.name);

  useEffect(() => {
    const getData = async () => {
      let data = await Promise.all([
        await getRandomItemsFromType("fashion", 8),
        await getRandomItemsFromType("fitness", 8),
        await getRandomItemsFromType("games", 8),
        await getRandomItemsFromType("recipes", 8),
        await getRandomItemsFromTypes(
          ["fashion", "fitness", "games", "recipes"],
          8
        ),
      ]);
      setSections({
        fashion: {
          name: defaultStrings.fashion,
          type: "fashion",
          items: data[0],
        },
        fitness: {
          name: defaultStrings.fitness,
          type: "fitness",
          items: data[1],
        },
        games: { name: defaultStrings.games, type: "games", items: data[2] },
        recipes: {
          name: defaultStrings.recipes,
          type: "recipes",
          items: data[3],
        },
        popular: {
          name: defaultStrings.popular,
          type: "popular",
          items: data[4],
        },
      });
      console.log(data);
    };
    getData();
  }, []);

  return sections == null ? (
    <></>
  ) : (
    <IonPage>
      <ResponsiveHeader />
      <IonContent>
        <IonGrid fixed className="ion-margin-bottom">
          <ItemContainer
            key={sections.games.type}
            isHorizontal={true}
            items={sections.games.items}
            containerName={sections.games.name}
          />
          <ItemContainer
            key={sections.popular.type}
            isHorizontal={true}
            items={sections.popular.items}
            containerName={sections.popular.name}
          />
          <ItemContainer
            key={sections.fashion.type}
            isHorizontal={true}
            items={sections.fashion.items}
            containerName={sections.fashion.name}
          />
          <ItemContainer
            key={sections.recipes.type}
            isHorizontal={true}
            items={sections.recipes.items}
            containerName={sections.recipes.name}
          />
          <ItemContainer
            key={sections.fitness.type}
            isHorizontal={true}
            items={sections.fitness.items}
            containerName={sections.fitness.name}
          />
          {Config().brand.name === "pl.boxplay" && (
            <>
              <h4
                onClick={toggleModal}
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  textDecoration: "underline",
                }}
              >
                Regulamin
              </h4>
              <Modal
                isOpen={isOpen}
                onRequestClose={toggleModal}
                contentLabel="My dialog"
                className={`shadow p-4`}
                style={{
                  overlay: {
                    position: "fixed",
                    zIndex: 1020,
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: "100vh",
                    background: "rgba(255, 255, 255, 0.75)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  },
                  content: {
                    background: "white",
                    padding: "30px",
                    color: "black",
                    width: "45rem",
                    maxWidth: "calc(100vw - 2rem)",
                    maxHeight: "calc(100vh - 2rem)",
                    overflowY: "auto",
                    position: "relative",
                    border: "1px solid #ccc",
                    borderRadius: "0.3rem",
                  },
                }}
              >
                <div>
                  <button
                    onClick={toggleModal}
                    style={{
                      padding: "10px 10px",
                      backgroundColor: "black",
                      float: "right",
                      color: "white",
                      fontSize: "20px",
                      borderRadius: "5px",
                    }}
                  >
                    X
                  </button>
                  <h2>Regulamin</h2>
                  <h4>1. OGÓLNE</h4>
                  a. Niniejsze Regulamin określa warunki, na jakich można
                  uzyskać dostęp i używać serwisu Boxplay. Usługi oferowane
                  przez dostawcę są skierowane wyłącznie do osób fizycznych lub
                  konsumentów. b. Korzystanie z usługi Boxplay podlega
                  niniejszemu Regulaminowi.
                  <h4 className="mt-3">2. INFORMACJE O USŁUGODAWCY</h4>
                  Usługodawcą jest: <br />
                  Armada Digital Ltd Pte,100D Pasir Panjang Road #03-04, Meissa,
                  Singapore 118520
                  <h4 className="mt-3">3. INFORMACJE O SERWISIE</h4>
                  Oferowany przez dostawcę serwis Boxplay oferuje użytkownikowi
                  dostęp do ponad 100 ciekawych gier HTML5 w różnych
                  kategoriach. Serwis Boxplay jest przeznaczony do użytku na
                  urządzeniach mobilnych, które mają ważną kartę SIM i / lub
                  komputerach PC / Mac z dostępem do Internetu. Serwis Boxplay
                  jest kompatybilne z systemem operacyjnym, Androidem (dla
                  urządzeń mobilnych) lub systemem operacyjnym i Windows (dla
                  komputerów PC / Mac). Aby uzyskać dostęp do serwisu Boxplay
                  należy wysłać SMS o treści BOX na numer 92574. Koszt usługi to
                  30,75 PLN z VAT. Jest to opłata jednorazowa
                  <h4 className="mt-3">4. ZAPYTANIA / REKLAMACJE</h4>
                  Wszelkie pytania / reklamacje dotyczące usługi Boxplay należy
                  kierować na adres: <br />
                  E-mail: boxplay.pl@silverlines.info <br />
                  Tel.: +48 221 253 450 <br />
                  Jeśli masz jakiekolwiek pytania lub wątpliwości dotyczące
                  usługi Boxplay, przygotuj swój numer telefonu komórkowego. Bez
                  numeru telefonu komórkowego nie możemy przetworzyć Twojego
                  zapytania.
                </div>
              </Modal>
            </>
          )}
          {Config().brand.name === "pl.finestscreen" && (
            <>
              <h4
                onClick={toggleModal}
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  textDecoration: "underline",
                }}
              >
                Regulamin
              </h4>
              <Modal
                isOpen={isOpen}
                onRequestClose={toggleModal}
                contentLabel="My dialog"
                className={`shadow p-4`}
                style={{
                  overlay: {
                    position: "fixed",
                    zIndex: 1020,
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: "100vh",
                    background: "rgba(255, 255, 255, 0.75)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  },
                  content: {
                    background: "white",
                    padding: "30px",
                    color: "black",
                    width: "45rem",
                    maxWidth: "calc(100vw - 2rem)",
                    maxHeight: "calc(100vh - 2rem)",
                    overflowY: "auto",
                    position: "relative",
                    border: "1px solid #ccc",
                    borderRadius: "0.3rem",
                  },
                }}
              >
                <div>
                  <button
                    onClick={toggleModal}
                    style={{
                      padding: "10px 10px",
                      backgroundColor: "black",
                      float: "right",
                      color: "white",
                      fontSize: "20px",
                      borderRadius: "5px",
                    }}
                  >
                    X
                  </button>
                  <h2>Regulamin</h2>
                  <h4>1. OGÓLNE</h4>
                  a. Niniejsze Regulamin określa warunki, na jakich można
                  uzyskać dostęp i używać serwisu finestscreen. Usługi oferowane
                  przez dostawcę są skierowane wyłącznie do osób fizycznych lub
                  konsumentów. b. Korzystanie z usługi finestscreen podlega
                  niniejszemu Regulaminowi.
                  <h4 className="mt-3">2. INFORMACJE O USŁUGODAWCY</h4>
                  Usługodawcą jest: <br />
                  Armada Digital Ltd Pte,100D Pasir Panjang Road #03-04, Meissa,
                  Singapore 118520
                  <h4 className="mt-3">3. INFORMACJE O SERWISIE</h4>
                  Oferowany przez dostawcę serwis finestscreen oferuje
                  użytkownikowi dostęp do ponad 100 ciekawych gier HTML5 w
                  różnych kategoriach. Serwis finestscreen jest przeznaczony do
                  użytku na urządzeniach mobilnych, które mają ważną kartę SIM i
                  / lub komputerach PC / Mac z dostępem do Internetu. Serwis
                  finestscreen jest kompatybilne z systemem operacyjnym,
                  Androidem (dla urządzeń mobilnych) lub systemem operacyjnym i
                  Windows (dla komputerów PC / Mac). Aby uzyskać dostęp do
                  serwisu finestscreen należy wysłać SMS o treści BOX na numer
                  92574. Koszt usługi to 30,75 PLN z VAT. Jest to opłata
                  jednorazowa
                  <h4 className="mt-3">4. ZAPYTANIA / REKLAMACJE</h4>
                  Wszelkie pytania / reklamacje dotyczące usługi finestscreen
                  należy kierować na adres: <br />
                  E-mail: finestscreen.pl@silverlines.info <br />
                  Tel.: +48 221 253 450 <br />
                  Jeśli masz jakiekolwiek pytania lub wątpliwości dotyczące
                  usługi finestscreen, przygotuj swój numer telefonu
                  komórkowego. Bez numeru telefonu komórkowego nie możemy
                  przetworzyć Twojego zapytania.
                </div>
              </Modal>
            </>
          )}
          {Config().brand.name === "hr.boxplay" && (
            <>
              <h4
                onClick={toggleModal}
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  textDecoration: "underline",
                }}
              >
                UVJETI KORIŠTENJA
              </h4>
              <Modal
                isOpen={isOpen}
                onRequestClose={toggleModal}
                contentLabel="My dialog"
                className={`shadow p-4`}
                style={{
                  overlay: {
                    position: "fixed",
                    zIndex: 1020,
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: "100vh",
                    background: "rgba(255, 255, 255, 0.75)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  },
                  content: {
                    background: "white",
                    padding: "30px",
                    color: "black",
                    width: "45rem",
                    maxWidth: "calc(100vw - 2rem)",
                    maxHeight: "calc(100vh - 2rem)",
                    overflowY: "auto",
                    position: "relative",
                    border: "1px solid #ccc",
                    borderRadius: "0.3rem",
                  },
                }}
              >
                <div>
                  <button
                    onClick={toggleModal}
                    style={{
                      padding: "10px 10px",
                      backgroundColor: "black",
                      float: "right",
                      color: "white",
                      fontSize: "20px",
                      borderRadius: "5px",
                    }}
                  >
                    X
                  </button>
                  <h2> UVJETI KORIŠTENJA</h2>
                  <h4>1. OPĆENITO</h4>
                  a. Ovim uvjetima i odredbama utvrđuju se uvjeti pod kojima ste
                  vi može pristupiti i koristiti Boxplay. Usluge koje nudi
                  pružatelj usluga namijenjene su samo pojedincima ili
                  potrošačima. b. Korištenje usluge Boxplay podliježe ovim
                  uvjetima i odredbama.
                  <h4 className="mt-3">2. INFORMACIJE O DAVATELJU USLUGA</h4>
                  Davatelj usluga je : Armada Digital Ltd Pte,100D Pasir Panjang
                  Road #03-04, Meissa, Singapore 118520
                  <h4 className="mt-3">3. INFORMACIJE O USLUZI</h4>
                  Usluga Boxplay koju nudi pružatelj usluga nudi korisniku
                  pristup više od 100 igara HTML5 zanimljiv u različitim
                  kategorijama. Boxplay je namijenjen za upotrebu na mobilni
                  uređaji s valjanom SIM karticom i/ili PC/Mac računalima s
                  pristupom Internet. Boxplay je kompatibilan s operativnim
                  sustavom, ndroidom (za uređaje mobiteli) ili operativni sustav
                  i Windows (za PC/Mac). Pristup usluzi Boxplay, pošalji SMS s
                  tekstom BOX na 866866. Cijena usluge je 2,65€. On Ovo je
                  jednokratna naknada.
                  <h4 className="mt-3">4. UPITI / PRITUŽBE</h4>
                  Sva pitanja /pritužbe u vezi s uslugom Boxplay treba usmjeriti
                  na: E-pošta: boxplay.hr@silverlines.info Tel. : +385 1 6388160
                  Ako imate bilo kakvih pitanja ili nedoumica u vezi Boxplaya,
                  molimo vas da imate svoj Broj mobitela na dohvat ruke.
                </div>
              </Modal>
            </>
          )}
          {Config().brand.name === "fi.boxplay" && (
            <>
              <h4
                onClick={toggleModal}
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  textDecoration: "underline",
                }}
              >
                Säännöt
              </h4>
              <Modal
                isOpen={isOpen}
                onRequestClose={toggleModal}
                contentLabel="My dialog"
                className={`shadow p-4`}
                style={{
                  overlay: {
                    position: "fixed",
                    zIndex: 1020,
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: "100vh",
                    background: "rgba(255, 255, 255, 0.75)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  },
                  content: {
                    background: "white",
                    padding: "30px",
                    color: "black",
                    width: "45rem",
                    maxWidth: "calc(100vw - 2rem)",
                    maxHeight: "calc(100vh - 2rem)",
                    overflowY: "auto",
                    position: "relative",
                    border: "1px solid #ccc",
                    borderRadius: "0.3rem",
                  },
                }}
              >
                <div>
                  <button
                    onClick={toggleModal}
                    style={{
                      padding: "10px 10px",
                      backgroundColor: "black",
                      float: "right",
                      color: "white",
                      fontSize: "20px",
                      borderRadius: "5px",
                    }}
                  >
                    X
                  </button>
                  <h2>Säännöt</h2>
                  <h4>1. TAVALLISESTI</h4>
                  a. Näissä ehdoissa määritellään ehdot, joiden mukaisesti voit
                  olla käytä ja käytä Boxplayta. Palveluntarjoajan tarjoamat
                  palvelut ne on tarkoitettu vain yksityishenkilöille tai
                  kuluttajille. b. Palvelun käyttö Boxplayhin sovelletaan näitä
                  käyttöehtoja.
                  <h4 className="mt-3">2. TIEDOT PALVELUNTARJOAJASTA</h4>
                  Palveluntarjoaja on : Armada Digital Ltd Pte,100D Pasir
                  Panjang Road #03-04, Meissa, Singapore 118520
                  <h4 className="mt-3">3. PALVELUN TIEDOT</h4>
                  Palveluntarjoajan tarjoama laatikkopelipalvelu tarjoaa
                  käyttäjälle pääsyn yli 100 peliä HTML5 mielenkiintoinen eri
                  kategorioissa. Boxplay on tarkoitettu käytettäväksi
                  mobiililaitteissa, joissa on voimassa oleva SIM-kortti ja/tai
                  PC-/Mac-tietokoneet, joissa on Internet-yhteys. Boxplay on
                  yhteensopiva seuraavien kanssa: käyttöjärjestelmä, Android
                  (laitteille matkapuhelimet) tai käyttö järjestelmä ja Windows
                  (PC/Mac). Käytä Boxplayta, lähetä tekstiviestejä teksti BOX
                  osoitteessa 17271. Palvelun hinta on 9,90 €. Hän on
                  kertaluonteinen maksu.
                  <h4 className="mt-3">4. TIEDUSTELUT / KANTELUT</h4>
                  Kaikki Boxplayta koskevat kysymykset/valitukset tulee osoittaa
                  osoitteeseen: Sähköposti: boxplay.pl@silverlines.info Puh. :
                  +48 221 253 450 Jos sinulla on jos sinulla on kysyttävää tai
                  huolenaiheita Boxplaysta, ole hyvä ja ole hyvä
                  Matkapuhelinnumerosi käden ulottuvilla. Ilman
                  matkapuhelinnumeroasi emme Voin hoitaa sinun.
                </div>
              </Modal>
            </>
          )}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
export default Home;
