import React from "react";
import {
  IonMenu,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonToggle,
  IonIcon,
  IonLabel,
  IonMenuToggle,
  IonListHeader,
} from "@ionic/react";
import "./Menu.css";
import {
  gameController,
  fastFood,
  fitness,
  star,
  albums,
  moonOutline,
  fingerPrint,
  book,
  mail,
  trashBin,
} from "ionicons/icons";
import { useLocation, RouteComponentProps } from "react-router";
import { Pages } from "../models/GeneralModels";
import { setDarkMode } from "../data/global/global.actions";
import { connect } from "../data/connect";
import { Config } from "../services/config";

const menuStrings = {
  en: {
    home: "Home",
    fashion: "Fashion",
    fitness: "Fitness",
    games: "Games",
    recipes: "Recipes",
    terms: "Terms",
    privacy: "Privacy",
    contact: "Contact",
    unsubscribe: "Unsubscribe",
  },
  fr: {
    home: "Accueil",
    fashion: "Mode",
    fitness: "Fitness",
    games: "Jeux",
    recipes: "Recettes",
    terms: "Conditions",
    privacy: "Confidentialité",
    contact: "Contact",
    unsubscribe: "Désabonnement",
  },
  pl: {
    home: "Strona główna",
    fashion: "Moda",
    fitness: "Fitness",
    games: "Gry",
    recipes: "Przepisy",
    terms: "Warunki",
    privacy: "Prywatność",
    contact: "Kontakt",
    unsubscribe: "Wypisz się",
  },
  ua: {
    home: "مسكن",
    fashion: "موضة",
    fitness: "اللياقه البدنيه",
    games: "ألعاب",
    recipes: "وصفات",
    terms: "شروط",
    privacy: "خصوصية",
    contact: "اتصال",
    unsubscribe: "إلغاء الاشتراك",
  },
  hr: {
    home: "Kalup",
    fashion: "Moda",
    fitness: "Prikladnost",
    games: "Igre",
    recipes: "Recepata",
    terms: "Terminima",
    privacy: "Privatnost",
    contact: "Kontakt",
    unsubscribe: "Otkazivanje pretplate",
  },
  fi: {
    home: "Koti",
    fashion: "Muoti",
    fitness: "Kunto",
    games: "Pelit",
    recipes: "Reseptit",
    terms: "Hinta",
    privacy: "Yksityisyys",
    contact: "Kontakti",
    unsubscribe: "Peruuta tilaus",
  },
};

let defaultmenuStrings = menuStrings.en;

if (Config().brand.name === "pl.boxplay") {
  defaultmenuStrings = menuStrings.pl;
} else if (Config().brand.name === "pl.finestscreen") {
  defaultmenuStrings = menuStrings.pl;
} else if (Config().brand.name === "fr.finestscreen") {
  defaultmenuStrings = menuStrings.fr;
} else if (Config().brand.name === "fr.boxplay") {
  defaultmenuStrings = menuStrings.fr;
} else if (Config().brand.name === "iq.boxplay" || Config().brand.name === "m.ae.finestscreen") {
  defaultmenuStrings = menuStrings.ua;
} else if (Config().brand.name === "hr.boxplay") {
  defaultmenuStrings = menuStrings.hr;
} else if (Config().brand.name === "fi.boxplay") {
  defaultmenuStrings = menuStrings.fi;
} else {
  defaultmenuStrings = menuStrings.en;
}

export const routes = {
  appPages: [
    { title: defaultmenuStrings.home, path: "/home", icon: albums },
    { title: defaultmenuStrings.fashion, path: "/fashion", icon: star },
    { title: defaultmenuStrings.fitness, path: "/fitness", icon: fitness },
    { title: defaultmenuStrings.recipes, path: "/recipes", icon: fastFood },
    { title: defaultmenuStrings.games, path: "/games", icon: gameController },
  ],
  accountPages: [
    // { title: 'Account', path: '/account', icon: personCircle },
    {
      title: defaultmenuStrings.terms,
      path: Config().brand.terms_link,
      icon: book,
    },
    {
      title: defaultmenuStrings.privacy,
      path: Config().brand.privacy_link,
      icon: fingerPrint,
    },
    {
      title: defaultmenuStrings.unsubscribe,
      path: Config().brand.unsub_link,
      icon: trashBin,
    },
    {
      title: defaultmenuStrings.contact,
      path: Config().brand.contact_link,
      icon: mail,
    },
  ],
};
interface StateProps {
  darkMode: boolean;
}

interface DispatchProps {
  setDarkMode: typeof setDarkMode;
}

interface MenuProps extends StateProps, DispatchProps, RouteComponentProps {}

const Menu: React.FC<MenuProps> = ({ darkMode, setDarkMode }) => {
  const location = useLocation();
  const renderMenuItems = (list: Pages[]) => {
    return list
      .filter((route) => !!route.path)
      .map((p) => (
        <IonMenuToggle key={p.title} auto-hide="false">
          <IonItem
            detail={false}
            routerLink={p.path}
            routerDirection="none"
            className={
              location.pathname.startsWith(p.path) ? "selected" : undefined
            }
          >
            <IonIcon slot="start" icon={p.icon} />
            <IonLabel>{p.title}</IonLabel>
          </IonItem>
        </IonMenuToggle>
      ));
  };

  const renderExternalMenuItems = (list: Pages[]) => {
    return list
      .filter((route) => !!route.path)
      .map((p) => (
        <IonMenuToggle key={p.title} auto-hide="false">
          <IonItem
            detail={false}
            onClick={() => (window.location.href = p.path)}
            routerDirection="none"
            className={
              location.pathname.startsWith(p.path) ? "selected" : undefined
            }
          >
            <IonIcon slot="start" icon={p.icon} />
            <IonLabel>{p.title}</IonLabel>
          </IonItem>
        </IonMenuToggle>
      ));
  };

  return (
    <>
      <IonMenu side="start" contentId="main">
        <IonHeader>
          <IonToolbar color="primary">
            <IonTitle>{Config().brand.title}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList lines="none">{renderMenuItems(routes.appPages)}</IonList>
          <IonList lines="none">
            <IonListHeader>Account</IonListHeader>
            {renderExternalMenuItems(routes.accountPages)}
            <IonItem>
              <IonIcon slot="start" icon={moonOutline}></IonIcon>
              <IonLabel>Dark Mode</IonLabel>
              <IonToggle
                checked={darkMode}
                onClick={() => setDarkMode(!darkMode)}
              />
            </IonItem>
          </IonList>
        </IonContent>
      </IonMenu>
    </>
  );
};

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
  }),
  mapDispatchToProps: {
    setDarkMode,
  },
  component: Menu,
});
