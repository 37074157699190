import { IonRouterOutlet} from '@ionic/react';
import { Route, RouteComponentProps } from 'react-router-dom';
import './Dashboard.css';

import Item from './Item';
import Home from './Home';


const Dashboard:React.FC<RouteComponentProps> = ({match})=> {
  console.log(match.url)
  return (
    <IonRouterOutlet>
      <Route exact path={match.url} component={Home} />
      <Route path="/home/:type/:id" component={Item} />
    </IonRouterOutlet>
  )
}

export default Dashboard;
