import { combineReducers } from "./combineReducers";
import { globalReducer } from "./global/global.reducer";

export const initialState: AppState = {
  data: {
    foo: "bar",
  },
  user: {
    darkMode: true,
    loading: false,
  },
};

export const reducers = combineReducers({
  user: globalReducer,
});

export type AppState = ReturnType<typeof reducers>;
