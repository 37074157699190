import { IonButton, IonIcon } from "@ionic/react";
import { useContext } from "react";
import { AuthContext, logout } from "../services/auth";
import { logOutOutline } from 'ionicons/icons';
const Logout: React.FC = () => {
  const authContext = useContext(AuthContext);

  const logOut =  async () => {
    await logout();
    authContext.setIsAuthenticated(false);
  }

  return (
    <IonButton className="contrast" onClick={()=>logOut()} shape="round"><IonIcon icon={logOutOutline}  /></IonButton>
  );
}

export default Logout;