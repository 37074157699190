import { IonApp, IonPage, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/global.css';
import Login from './pages/Login';
import { ProtectedRoute } from './components/ProtectedRoute';
import React, { useEffect, useState } from 'react';
import { setupStorage, store } from './services/storage';
import { AuthContext, isLoggedIn } from './services/auth';
import { AppContextProvider } from './data/AppContext';
import { connect } from './data/connect';
import { loadUserData } from './data/global/global.actions';
import Menu, { routes } from './components/Menu';
import { Pages } from './models/GeneralModels';
import Category from './pages/Category';
import Categories from './pages/Categories';
import {Config, loadTheme} from './services/config';

setupIonicReact();
setupStorage();

const App: React.FC = () => {
  return (
    <AppContextProvider>
      <IonicAppConnected />
    </AppContextProvider>
  );
};

interface StateProps {
  darkMode: boolean;
}

interface DispatchProps {
  loadUserData: typeof loadUserData;
}
interface IonicAppProps extends StateProps, DispatchProps { }
const IonicApp: React.FC<IonicAppProps> = ({darkMode, loadUserData}) => {
  //Assume User logged out
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const authValue = { isAuthenticated, setIsAuthenticated };

  //Check if user is logged in
  useEffect(() => {
    loadTheme(Config().brand);
    loadUserData();
    document.title = Config().brand.title;
    // Luna Authentication Check
    const setAuthStatus = async () => {
      let status: any = await isLoggedIn();
      setIsAuthenticated(status);
    }
    setAuthStatus();
  }, [])

  const renderProtectedRoutes = (routes:Pages[]) => {
    return routes
      .filter(route => route.path !== '/home')
      .map(p => (
        <ProtectedRoute key={p.title} path={p.path} component={Categories} />
      ));

    //
  }
  return (
    (isAuthenticated == null) ? <IonApp></IonApp> :
    <AuthContext.Provider value={authValue}>
    <IonApp className={`${darkMode ? 'dark-theme' : ''}`}>
      <IonReactRouter>
      <Menu />
        <IonRouterOutlet id="main">
          <ProtectedRoute path="/home" component={Dashboard} />
          <Route path="/login" component={Login} exact={true} />
          {renderProtectedRoutes(routes.appPages)}
          <Route exact path="/" render={() => <Redirect to="/home" />} />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
    </AuthContext.Provider>
  );
};

export default App;


const IonicAppConnected = connect<{}, StateProps>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
  }),
  mapDispatchToProps: { loadUserData },
  component: IonicApp
});