import { IonButton, IonButtons, IonHeader, IonIcon, IonItem, IonLabel, IonMenuButton, IonMenuToggle, IonTitle, IonToolbar } from "@ionic/react";
import { isPlatform } from '@ionic/react';
import { star, logOutOutline } from 'ionicons/icons';
import Logout from "./Logout";
import './ResponsiveHeader.css';
import { routes } from "./Menu";
import { useLocation } from "react-router";
import { Pages } from "../models/GeneralModels";
import { endpoints } from "../services/data";
import { Config } from "../services/config";
const ResponsiveHeader: React.FC = () => {
  const location = useLocation();
  const renderMenuItems = (list: Pages[]) => {
    return list
      .filter(route => !!route.path)
      .map(p => (
        <div key={p.title} className="desktop-menuitem">
          <IonItem lines="none"  color="primary" detail={false} routerLink={p.path} routerDirection="none" className={location.pathname.startsWith(p.path) ? 'selected' : undefined}>
            <IonIcon className="contrast" slot="start" icon={p.icon} />
            <IonLabel className="contrast">{p.title}</IonLabel>
          </IonItem>
        </div>
      ));
  }

  return (
    isPlatform('desktop') ? 
    <div className="desktop-header">
      <div className="desktop-toolbar">
        <div className="desktop-logo">
          <img src={`${endpoints.cdn}logos/${Config().brand.name}.png`} alt="logo" />
        </div>
        <div className="dekstop-menu">
          <IonButtons>
            {renderMenuItems(routes.appPages)}
            <IonMenuButton className="contrast"></IonMenuButton>
            <Logout />
          </IonButtons>
        </div>
      </div>
    </div>
    
    : <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton className="contrast"></IonMenuButton>
          </IonButtons>
          <IonButtons slot="end">
            <Logout />
          </IonButtons>
          <IonTitle className='ion-text-center ion-justify-content-center ion-align-items-center'>
            <img className="mobile-logo" src={`${endpoints.cdn}logos/${Config().brand.name}.png`} alt="logo" />
          </IonTitle>
        </IonToolbar>
      </IonHeader>
  );
};

export default ResponsiveHeader;