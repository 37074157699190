import { getBrand, getBrandDetails } from "./data";
export const Config = () => {
  const config = {
    brand: getBrandDetails(getBrand()),
    loginURL: "https://lunalive.azurewebsites.net/melodi/checksubscription?m=",
    unsubURL: "https://lunalive.azurewebsites.net/melodi/unsubscribe?m=",
  };
  return config;
};

export const loadTheme = (brand: any) => {
  if (brand.primary_color) {
    document.documentElement.style.setProperty(
      "--ion-color-primary",
      brand.primary_color
    );
  }
  if (brand.secondary_color) {
    document.documentElement.style.setProperty(
      "--ion-color-secondary",
      brand.secondary_color
    );
  }
  if (brand.tertiary_color) {
    document.documentElement.style.setProperty(
      "--ion-color-tertiary",
      brand.tertiary_color
    );
    document.documentElement.style.setProperty(
      "--ion-color-dark",
      brand.tertiary_color
    );
  }
  document.styleSheets[0].insertRule(
    `.dark-theme{--ion-color-primary:${brand.primary_color} !important;--ion-color-secondary:${brand.secondary_color} !important;--ion-color-tertiary:${brand.tertiary_color} !important}`
  );
};
