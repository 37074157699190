import { User } from "../models/GeneralModels";
import { Config } from "./config";

export const getUser = async (username: string) => {
  const response = await fetch(Config().loginURL + username);
  const data = (await response.json()) as User;
  return data;
};

export const getBrand = () => {
  const brands: any = [];
  appBrands.map((b) => {
    brands.push(b.name);
  });
  const urlBrand: any = brands.filter((x: any) =>
    window.location.host.includes(x)
  )[0];
  if (urlBrand) {
    return urlBrand;
  } else {
    //nobrandfound
  }
};

export const getBrandDetails = (brand: string) => {
  const brandDetails = appBrands.filter((b) => b.name == brand)[0];
  return brandDetails;
};

export enum ContentCategory {
  fashion = "fashion",
  fitness = "fitness",
  recipes = "recipes",
  games = "games",
}

function shuffle(array: any) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

export const getCategories = async (category: string) => {
  let c = getBrand() == "iq.boxplay" ? content_ua : content;
  return c[category] ? c[category].categories : null;
};

export const getCategoryItems = async (
  category: string,
  subcategory: string
) => {
  let c = getBrand() == "iq.boxplay" ? content_ua : content;
  return {
    name: c[category].categories.filter((c: any) => c.cat === subcategory)[0]
      .name,
    items: c[category].data.filter((d: any) => d.category === subcategory),
  };
};

export const getRandomItemsFromType = async (
  category: string,
  count: number
) => {
  let c = getBrand() == "iq.boxplay" ? content_ua : content;
  return shuffle(c[category].data).slice(0, count);
};

export const getRandomItemsFromTypes = async (
  category: string[],
  count: number
) => {
  let cont = getBrand() == "iq.boxplay" ? content_ua : content;
  let items: any[] = [];
  category.map((c: string) => {
    items = [
      ...items,
      ...shuffle(cont[c].data).slice(0, count / category.length),
    ];
  });
  return items;
};

export const getItem = async (id: string, type: string) => {
  let c = getBrand() == "iq.boxplay" ? content_ua : content;
  return c[type].data.filter((d: any) => d.id == id)[0];
};

export const endpoints: any = {
  fashion:
    "https://6a24fc5ec303f681dd6e-5cdb55fe981c88c11a30ce7a9683dc39.ssl.cf3.rackcdn.com/",
  fitness:
    "https://e467fbb5cac9bbad9e56-759be4b9f246e7894c6129a84222bf6d.ssl.cf3.rackcdn.com/",
  recipes:
    "https://1a4f1023ba05f3dabdc3-33810d66e1c31499d4a786f550c0188e.ssl.cf3.rackcdn.com/",
  games:
    "https://contentstoragewesteu1.blob.core.windows.net/cdnstorage/games/",
  cdn: "https://contentstoragewesteu1.blob.core.windows.net/cdnstorage/selene/apps/",
};
export const content: any = {
  fashion: {
    categories: [
      { cat: "makeup_trends", name: "Makeup Trends" },
      { cat: "hair_trends", name: "Hair Trends" },
      { cat: "photoshoots", name: "Photoshoots" },
      { cat: "model_interviews", name: "Model Interviews" },
      { cat: "runway", name: "Runway" },
      { cat: "first_look", name: "First Look" },
      { cat: "victorias_secret_2017", name: "Victoria's Secret" },
    ],
    data: [
      {
        id: 109,
        type: "fashion",
        name: "victorias_secret_2017/101.jpg",
        link: "victorias_secret_2017/101.mp4",
        category: "victorias_secret_2017",
        title: "*NEW* Victorias Secret  ",
        desc: "*NEW* Backstage at Victorias Secret  - Part 1 ",
      },
      {
        id: 110,
        type: "fashion",
        name: "victorias_secret_2017/102.jpg",
        link: "victorias_secret_2017/102.mp4",
        category: "victorias_secret_2017",
        title: "*NEW* Victorias Secret  ",
        desc: "*NEW* Backstage at Victorias Secret  - Part 2 ",
      },
      {
        id: 111,
        type: "fashion",
        name: "victorias_secret_2017/103.jpg",
        link: "victorias_secret_2017/103.mp4",
        category: "victorias_secret_2017",
        title: "*NEW* Victorias Secret  ",
        desc: "*NEW* Backstage at Victorias Secret  - Part 3 ",
      },
      {
        id: 112,
        type: "fashion",
        name: "victorias_secret_2017/104.jpg",
        link: "victorias_secret_2017/104.mp4",
        category: "victorias_secret_2017",
        title: "*NEW* Victorias Secret  ",
        desc: "*NEW* Backstage at Victorias Secret  - Part 4 ",
      },
      {
        id: 113,
        type: "fashion",
        name: "victorias_secret_2017/105.jpg",
        link: "victorias_secret_2017/105.mp4",
        category: "victorias_secret_2017",
        title: "*NEW* Victorias Secret  ",
        desc: "*NEW* Backstage at Victorias Secret  - Part 5 ",
      },
      {
        id: 114,
        type: "fashion",
        name: "victorias_secret_2017/106.jpg",
        link: "victorias_secret_2017/106.mp4",
        category: "victorias_secret_2017",
        title: "*NEW* Victorias Secret  ",
        desc: "*NEW* Backstage at Victorias Secret  - Part6 ",
      },
      {
        id: 115,
        type: "fashion",
        name: "victorias_secret_2017/107.jpg",
        link: "victorias_secret_2017/107.mp4",
        category: "victorias_secret_2017",
        title: "*NEW* Victorias Secret  ",
        desc: "*NEW* Backstage at Victorias Secret  - Part 7 ",
      },
      {
        id: 116,
        type: "fashion",
        name: "victorias_secret_2017/108.jpg",
        link: "victorias_secret_2017/108.mp4",
        category: "victorias_secret_2017",
        title: "*NEW* Victorias Secret  ",
        desc: "*NEW* Backstage at Victorias Secret  - Part 8 ",
      },
      {
        id: 117,
        type: "fashion",
        name: "victorias_secret_2017/109.jpg",
        link: "victorias_secret_2017/109.mp4",
        category: "victorias_secret_2017",
        title: "*NEW* Victorias Secret  ",
        desc: "*NEW* Backstage at Victorias Secret  - Part 9 ",
      },
      {
        id: 118,
        type: "fashion",
        name: "victorias_secret_2017/110.jpg",
        link: "victorias_secret_2017/110.mp4",
        category: "victorias_secret_2017",
        title: "*NEW* Victorias Secret  ",
        desc: "*NEW* Backstage at Victorias Secret  - Part 10 ",
      },
      {
        id: 119,
        type: "fashion",
        name: "victorias_secret_2017/111.jpg",
        link: "victorias_secret_2017/111.mp4",
        category: "makeup_trends",
        title: "*NEW* Makeup Trends - Spring/ Summer 2018 ",
        desc: "*NEW* Makeup Trends - Spring/ Summer 2018 - Part 1 ",
      },
      {
        id: 120,
        type: "fashion",
        name: "victorias_secret_2017/112.jpg",
        link: "victorias_secret_2017/112.mp4",
        category: "makeup_trends",
        title: "*NEW* Makeup Trends - Spring/ Summer 2018 ",
        desc: "*NEW* Makeup Trends - Spring/ Summer 2018 - Part 2 ",
      },
      {
        id: 121,
        type: "fashion",
        name: "victorias_secret_2017/113.jpg",
        link: "victorias_secret_2017/113.mp4",
        category: "makeup_trends",
        title: "*NEW* Makeup Trends - Spring/ Summer 2018 ",
        desc: "*NEW* Makeup Trends - Spring/ Summer 2018 - Part 3 ",
      },
      {
        id: 122,
        type: "fashion",
        name: "victorias_secret_2017/114.jpg",
        link: "victorias_secret_2017/114.mp4",
        category: "makeup_trends",
        title: "*NEW* Makeup Trends - Spring/ Summer 2018 ",
        desc: "*NEW* Makeup Trends - Spring/ Summer 2018 - Part 4 ",
      },
      {
        id: 123,
        type: "fashion",
        name: "victorias_secret_2017/115.jpg",
        link: "victorias_secret_2017/115.mp4",
        category: "runway",
        title: "*NEW* Milan 2018 - Runway Show",
        desc: "*NEW* Runway show at the 2018 Milan Fashion Show - 1/4",
      },
      {
        id: 124,
        type: "fashion",
        name: "victorias_secret_2017/116.jpg",
        link: "victorias_secret_2017/116.mp4",
        category: "runway",
        title: "*NEW* Milan 2018 - Runway Show",
        desc: "*NEW* Runway show at the 2018 Milan Fashion Show - 2/4",
      },
      {
        id: 125,
        type: "fashion",
        name: "victorias_secret_2017/117.jpg",
        link: "victorias_secret_2017/117.mp4",
        category: "runway",
        title: "*NEW* Milan 2018 - Runway Show",
        desc: "*NEW* Runway show at the 2018 Milan Fashion Show - 3/4",
      },
      {
        id: 126,
        type: "fashion",
        name: "victorias_secret_2017/118.jpg",
        link: "victorias_secret_2017/118.mp4",
        category: "runway",
        title: "*NEW* Milan 2018 - Runway Show",
        desc: "*NEW* Runway show at the 2018 Milan Fashion Show - 4/4",
      },
      {
        id: 127,
        type: "fashion",
        name: "victorias_secret_2017/119.jpg",
        link: "victorias_secret_2017/119.mp4",
        category: "runway",
        title: "*NEW* Runway show - Spring Summer 2018 ",
        desc: "*NEW*Runway Show for Spring/ Summer 2018 fashion trends ",
      },
      {
        id: 128,
        type: "fashion",
        name: "victorias_secret_2017/120.jpg",
        link: "victorias_secret_2017/120.mp4",
        category: "runway",
        title: "*NEW* Runway show - Spring Summer 2018 ",
        desc: "*NEW* Runway Show for Spring/ Summer 2018 fashion trends part 2",
      },
      {
        id: 129,
        type: "fashion",
        name: "victorias_secret_2017/121.jpg",
        link: "victorias_secret_2017/121.mp4",
        category: "runway",
        title: "*NEW* Runway show - Spring Summer 2018 ",
        desc: "*NEW*Runway Show for Spring/ Summer 2018 fashion trends part 3",
      },
      {
        id: 130,
        type: "fashion",
        name: "victorias_secret_2017/122.jpg",
        link: "victorias_secret_2017/122.mp4",
        category: "runway",
        title: "*NEW* Runway show - Spring Summer 2018 ",
        desc: "*NEW*Runway Show for Spring/ Summer 2018 fashion trends part 4",
      },
      {
        id: 1,
        type: "fashion",
        name: "first_look/101.jpg",
        link: "first_look/101.mp4",
        category: "first_look",
        title: "First Look Paris",
        desc: "Spring/ Summer Part 1.",
      },
      {
        id: 2,
        type: "fashion",
        name: "first_look/102.jpg",
        link: "first_look/102.mp4",
        category: "first_look",
        title: "First Look Paris - II",
        desc: "Spring/ Summer Part 2.",
      },
      {
        id: 3,
        type: "fashion",
        name: "first_look/103.jpg",
        link: "first_look/103.mp4",
        category: "first_look",
        title: "First Look Paris - The Full Report",
        desc: "Spring/ Summer Part 3.",
      },
      {
        id: 4,
        type: "fashion",
        name: "first_look/104.jpg",
        link: "first_look/104.mp4",
        category: "first_look",
        title: "Caroline de Maigret Interview",
        desc: "Trendy Urban Fashion Show Part 1",
      },
      {
        id: 5,
        type: "fashion",
        name: "first_look/105.jpg",
        link: "first_look/105.mp4",
        category: "first_look",
        title: "Caroline de Maigret Interview II",
        desc: "Trendy Urban Fashion Show Part 2",
      },
      {
        id: 6,
        type: "fashion",
        name: "first_look/106.jpg",
        link: "first_look/106.mp4",
        category: "first_look",
        title: "First Look",
        desc: "Runway Part 1",
      },
      {
        id: 7,
        type: "fashion",
        name: "first_look/107.jpg",
        link: "first_look/107.mp4",
        category: "first_look",
        title: "First Look",
        desc: "Runway Part 2",
      },
      {
        id: 8,
        type: "fashion",
        name: "first_look/108.jpg",
        link: "first_look/108.mp4",
        category: "first_look",
        title: "First Look Couture",
        desc: "Couture Runway Part 1",
      },
      {
        id: 9,
        type: "fashion",
        name: "first_look/109.jpg",
        link: "first_look/109.mp4",
        category: "first_look",
        title: "First Look Couture",
        desc: "Couture Runway Part 2",
      },
      {
        id: 10,
        type: "fashion",
        name: "first_look/110.jpg",
        link: "first_look/110.mp4",
        category: "first_look",
        title: "First Look Spring/ Summer",
        desc: "2017 Spring/ Summer Runway Show",
      },
      {
        id: 11,
        type: "fashion",
        name: "first_look/111.jpg",
        link: "first_look/111.mp4",
        category: "first_look",
        title: "First Look Spring/ Summer",
        desc: "2017 Spring/ Summer Runway Show - Part 2",
      },
      {
        id: 12,
        type: "fashion",
        name: "first_look/112.jpg",
        link: "first_look/112.mp4",
        category: "first_look",
        title: "First Look Couture Backstage",
        desc: "Interview with Deborah Hung",
      },
      {
        id: 13,
        type: "fashion",
        name: "first_look/113.jpg",
        link: "first_look/113.mp4",
        category: "first_look",
        title: "First Look Spring/ Summer",
        desc: "Interview with Blanca Li",
      },
      {
        id: 14,
        type: "fashion",
        name: "first_look/114.jpg",
        link: "first_look/114.mp4",
        category: "first_look",
        title: "First Look Spring/ Summer",
        desc: "The closing moments of the Spring/ Summer First Look Fashion Show Paris.",
      },
      {
        id: 15,
        type: "fashion",
        name: "makeup_trends/101.jpg",
        link: "makeup_trends/101.mp4",
        category: "makeup_trends",
        title: "First Look Fall/ Winter",
        desc: "Marco De Vincenzo Interview.",
      },
      {
        id: 16,
        type: "fashion",
        name: "makeup_trends/102.jpg",
        link: "makeup_trends/102.mp4",
        category: "makeup_trends",
        title: "Makeup Trends Fall/ Winter Part 1",
        desc: "Natural Look Makeup Runway.",
      },
      {
        id: 17,
        type: "fashion",
        name: "makeup_trends/103.jpg",
        link: "makeup_trends/103.mp4",
        category: "makeup_trends",
        title: "Makeup Trends Fall/ Winter Part 2",
        desc: "Red Lips inside Look",
      },
      {
        id: 18,
        type: "fashion",
        name: "makeup_trends/104.jpg",
        link: "makeup_trends/104.mp4",
        category: "makeup_trends",
        title: "Makeup Trends Fall/ Winter Part 3",
        desc: "Strong Eyebrows - Inside Look!",
      },
      {
        id: 19,
        type: "fashion",
        name: "makeup_trends/105.jpg",
        link: "makeup_trends/105.mp4",
        category: "makeup_trends",
        title: "Makeup Trends Fall/ Winter Part 4",
        desc: "Strong Eyes - Inside Look!",
      },
      {
        id: 20,
        type: "fashion",
        name: "model_interviews/101.jpg",
        link: "model_interviews/101.mp4",
        category: "model_interviews",
        title: "Model Interviews Paris",
        desc: "Taylor Hill - IMG",
      },
      {
        id: 21,
        type: "fashion",
        name: "model_interviews/102.jpg",
        link: "model_interviews/102.mp4",
        category: "model_interviews",
        title: "Model Interviews Paris",
        desc: "Luna Bijl - OUI",
      },
      {
        id: 22,
        type: "fashion",
        name: "model_interviews/103.jpg",
        link: "model_interviews/103.mp4",
        category: "model_interviews",
        title: "Model Interviews Paris",
        desc: "Mayowa Nicholas - ELITE",
      },
      {
        id: 23,
        type: "fashion",
        name: "model_interviews/104.jpg",
        link: "model_interviews/104.mp4",
        category: "model_interviews",
        title: "Model Interviews Paris",
        desc: "Euphoria",
      },
      {
        id: 24,
        type: "fashion",
        name: "model_interviews/105.jpg",
        link: "model_interviews/105.mp4",
        category: "model_interviews",
        title: "Model Interviews Paris",
        desc: "Louis Verdad New York",
      },
      {
        id: 25,
        type: "fashion",
        name: "photoshoots/101.jpg",
        link: "photoshoots/101.mp4",
        category: "photoshoots",
        title: "Jenna Kelly ",
        desc: "Bedroom Fashion shoot",
      },
      {
        id: 26,
        type: "fashion",
        name: "photoshoots/102.jpg",
        link: "photoshoots/102.mp4",
        category: "photoshoots",
        title: "Mary Grace Swimwear",
        desc: "Bikini Fashion shoot",
      },
      {
        id: 27,
        type: "fashion",
        name: "photoshoots/103.jpg",
        link: "photoshoots/103.mp4",
        category: "photoshoots",
        title: "The Empress of China",
        desc: "Oriental makeup and fashion",
      },
      {
        id: 28,
        type: "fashion",
        name: "photoshoots/104.jpg",
        link: "photoshoots/104.mp4",
        category: "photoshoots",
        title: "Without a Touch",
        desc: "Beautiful landscape panoramic fashion photoshoot.",
      },
      {
        id: 29,
        type: "fashion",
        name: "photoshoots/105.jpg",
        link: "photoshoots/105.mp4",
        category: "photoshoots",
        title: "Desiree Kendricks",
        desc: "Colourful fashion photo shoot with Desiree Kendricks",
      },
      {
        id: 30,
        type: "fashion",
        name: "photoshoots/106.jpg",
        link: "photoshoots/106.mp4",
        category: "photoshoots",
        title: "Yuri Space Princess",
        desc: "Artistic, trippy photo shoot by Mistuki Kinushi",
      },
      {
        id: 31,
        type: "fashion",
        name: "photoshoots/107.jpg",
        link: "photoshoots/107.mp4",
        category: "photoshoots",
        title: "Plus Sized Beauty",
        desc: "Beach Swimwear photo shoot for plus sized models.",
      },
      {
        id: 32,
        type: "fashion",
        name: "photoshoots/108.jpg",
        link: "photoshoots/108.mp4",
        category: "photoshoots",
        title: "LMNO.",
        desc: "Behind the scenes look at a fashion shoot.",
      },
      {
        id: 33,
        type: "fashion",
        name: "photoshoots/109.jpg",
        link: "photoshoots/109.mp4",
        category: "photoshoots",
        title: "Waterfall Bikini",
        desc: "A bikini and one piece swimwear photoshoot shot on location at a waterfall.",
      },
      {
        id: 34,
        type: "fashion",
        name: "photoshoots/110.jpg",
        link: "photoshoots/110.mp4",
        category: "photoshoots",
        title: "Noir Fashion",
        desc: "Black and White Fashion shoot",
      },
      {
        id: 35,
        type: "fashion",
        name: "photoshoots/111.jpg",
        link: "photoshoots/111.mp4",
        category: "photoshoots",
        title: "Neon Beauty",
        desc: "Modern, Urban Neon fashion shoot.",
      },

      {
        id: 36,
        type: "fashion",
        name: "runway/101.jpg",
        link: "runway/101.mp4",
        category: "runway",
        title: " Aqua Clara ",
        desc: "Runway - Miami Beach Funkshion  Aqua Clara Swimwear",
      },
      {
        id: 37,
        type: "fashion",
        name: "runway/102.jpg",
        link: "runway/102.mp4",
        category: "runway",
        title: " Lume ",
        desc: "Runway - Miami Beach Funkshion  Lume Swimwear",
      },
      {
        id: 38,
        type: "fashion",
        name: "runway/103.jpg",
        link: "runway/103.mp4",
        category: "runway",
        title: " Beach Riot ",
        desc: "Runway - Miami Beach Funkshion  Beach Riot Swimwear",
      },
      {
        id: 39,
        type: "fashion",
        name: "runway/104.jpg",
        link: "runway/104.mp4",
        category: "runway",
        title: " Blue Life ",
        desc: "Runway - Miami Beach Funkshion  Blue Life Swimwear",
      },
      {
        id: 40,
        type: "fashion",
        name: "runway/105.jpg",
        link: "runway/105.mp4",
        category: "runway",
        title: " Kai Lani ",
        desc: "Runway - Miami Beach Funkshion  Kai Lani Swimwear",
      },
      {
        id: 41,
        type: "fashion",
        name: "runway/106.jpg",
        link: "runway/106.mp4",
        category: "runway",
        title: " Moschino ",
        desc: " Moschino Bizarre Futurustic Fashion",
      },
      {
        id: 42,
        type: "fashion",
        name: "runway/107.jpg",
        link: "runway/107.mp4",
        category: "runway",
        title: " Moschino - II",
        desc: " Moschino Bizarre Futurustic Fashion Part 2",
      },
      {
        id: 43,
        type: "fashion",
        name: "runway/108.jpg",
        link: "runway/108.mp4",
        category: "runway",
        title: " Moschino - III ",
        desc: " Moschino Bizarre Futurustic Fashion Part 3",
      },
      {
        id: 44,
        type: "fashion",
        name: "runway/109.jpg",
        link: "runway/109.mp4",
        category: "runway",
        title: " Lingerie Mon Amour ",
        desc: " French Lingerie Nouveau Fashion ",
      },
      {
        id: 45,
        type: "fashion",
        name: "runway/110.jpg",
        link: "runway/110.mp4",
        category: "runway",
        title: " Lingerie Mon Amour - II",
        desc: " French Lingerie Nouveau Fashion Part 2 ",
      },
      {
        id: 46,
        type: "fashion",
        name: "runway/111.jpg",
        link: "runway/111.mp4",
        category: "runway",
        title: " Lingerie Mon Amour - III ",
        desc: " French Lingerie Nouveau Fashion Part 3 ",
      },
      {
        id: 47,
        type: "fashion",
        name: "runway/112.jpg",
        link: "runway/112.mp4",
        category: "runway",
        title: " Desigual New York ",
        desc: " Desigual NY Fashion Runway Show ",
      },
      {
        id: 48,
        type: "fashion",
        name: "runway/113.jpg",
        link: "runway/113.mp4",
        category: "runway",
        title: " Desigual New York - III ",
        desc: " Desigual NY Fashion Runway Show Part 2 ",
      },
      {
        id: 49,
        type: "fashion",
        name: "runway/114.jpg",
        link: "runway/114.mp4",
        category: "runway",
        title: " La Perla New York ",
        desc: " La Perla NY Fashion Runway Show ",
      },
      {
        id: 50,
        type: "fashion",
        name: "first_look/115.jpeg",
        link: "first_look/115.mp4",
        category: "first_look",
        title: "First Look Milan",
        desc: "Fall/ Winter looks from Milan Fashion Show",
      },
      {
        id: 51,
        type: "fashion",
        name: "first_look/116.jpeg",
        link: "first_look/116.mp4",
        category: "first_look",
        title: "First Look Milan Part 2",
        desc: "Fall/ Winter looks from Milan Fashion Show",
      },
      {
        id: 52,
        type: "fashion",
        name: "first_look/117.jpeg",
        link: "first_look/117.mp4",
        category: "first_look",
        title: "First Look Milan 2017",
        desc: "Fall/ Winter looks from Milan Fashion Show 2017",
      },
      {
        id: 53,
        type: "fashion",
        name: "first_look/118.jpeg",
        link: "first_look/118.mp4",
        category: "first_look",
        title: "Interview with designer Charlotte Stockdale",
        desc: "Interview with Charlotte Stockdale from the Milan Fashion Show",
      },
      {
        id: 54,
        type: "fashion",
        name: "first_look/119.jpeg",
        link: "first_look/119.mp4",
        category: "first_look",
        title: "First Look Milan Part 3",
        desc: "Fall/ Winter looks from Milan Fashion Show - Part 3",
      },
      {
        id: 55,
        type: "fashion",
        name: "first_look/120.jpeg",
        link: "first_look/120.mp4",
        category: "first_look",
        title: "Stefano Tonchi Interwiew",
        desc: "Interview with fashoin designer Stefano Tochi from the Milan Fashion Show",
      },
      {
        id: 56,
        type: "fashion",
        name: "first_look/121.jpeg",
        link: "first_look/121.mp4",
        category: "first_look",
        title: "Fall/ Winter 2017 Looks",
        desc: "All the latest hairstyles and clothes from the Milan Fashion Show.",
      },
      {
        id: 57,
        type: "fashion",
        name: "first_look/122.jpeg",
        link: "first_look/122.mp4",
        category: "first_look",
        title: "Fall/ Winter 2017 Looks - II",
        desc: "All the latest hairstyles and clothes from the Milan Fashion Show - Part 2",
      },
      {
        id: 58,
        type: "fashion",
        name: "first_look/123.jpeg",
        link: "first_look/123.mp4",
        category: "first_look",
        title: "Fall/ Winter 2017 Looks - III",
        desc: "All the latest hairstyles and clothes from the Milan Fashion Show - Part 3",
      },
      {
        id: 59,
        type: "fashion",
        name: "first_look/124.jpeg",
        link: "first_look/124.mp4",
        category: "first_look",
        title: "Fall/ Winter 2017 Looks - IV",
        desc: "All the latest hairstyles and clothes from the Milan Fashion Show - Part 4",
      },
      {
        id: 60,
        type: "fashion",
        name: "first_look/125.jpeg",
        link: "first_look/125.mp4",
        category: "first_look",
        title: "Fall/ Winter 2017 Looks - V",
        desc: "All the latest hairstyles and clothes from the Milan Fashion Show - Part V",
      },
      {
        id: 61,
        type: "fashion",
        name: "first_look/126.jpeg",
        link: "first_look/126.mp4",
        category: "first_look",
        title: "Fall/ Winter 2017 Looks - VI",
        desc: "All the latest hairstyles and clothes from the Milan Fashion Show - Part 6",
      },
      {
        id: 62,
        type: "fashion",
        name: "first_look/127.jpeg",
        link: "first_look/127.mp4",
        category: "first_look",
        title: "Fall/ Winter 2017 Looks - VII",
        desc: "All the latest hairstyles and clothes from the Milan Fashion Show - Part 7",
      },
      {
        id: 63,
        type: "fashion",
        name: "first_look/128.jpeg",
        link: "first_look/128.mp4",
        category: "first_look",
        title: "Fall/ Winter 2017 Looks - VIII",
        desc: "All the latest hairstyles and clothes from the Milan Fashion Show - Part 8",
      },
      {
        id: 64,
        type: "fashion",
        name: "first_look/129.jpeg",
        link: "first_look/129.mp4",
        category: "first_look",
        title: "Fall/ Winter 2017 Looks - The Full Report",
        desc: "All the latest hairstyles and clothes from the Milan Fashion Show - Interview with Guido Paulu (Hairstylist)",
      },
      {
        id: 65,
        type: "fashion",
        name: "first_look/130.jpeg",
        link: "first_look/130.mp4",
        category: "first_look",
        title: "Fall/ Winter 2017 Looks - IX",
        desc: "All the latest hairstyles and clothes from the Milan Fashion Show - Part 9",
      },
      {
        id: 66,
        type: "fashion",
        name: "first_look/131.jpeg",
        link: "first_look/131.mp4",
        category: "first_look",
        title: "Fall/ Winter 2017 Looks - X",
        desc: "All the latest hairstyles and clothes from the Milan Fashion Show - Part 10",
      },
      {
        id: 67,
        type: "fashion",
        name: "first_look/132.jpeg",
        link: "first_look/132.mp4",
        category: "first_look",
        title: "Fall/ Winter 2017 Looks - XI",
        desc: "All the latest hairstyles and clothes from the Milan Fashion Show - Part 11",
      },
      {
        id: 68,
        type: "fashion",
        name: "hair_trends/101.jpeg",
        link: "hair_trends/101.mp4",
        category: "hair_trends",
        title: "Hair Trends - 2017",
        desc: "All the latest hairstyles for Spring/ Summer 2017",
      },
      {
        id: 69,
        type: "fashion",
        name: "hair_trends/102.jpeg",
        link: "hair_trends/102.mp4",
        category: "hair_trends",
        title: "Hair Trends - 2017",
        desc: "All the latest hairstyles for Spring/ Summer 2017 plus bonus interview with Dean and Dan Caten!",
      },
      {
        id: 70,
        type: "fashion",
        name: "hair_trends/103.jpeg",
        link: "hair_trends/103.mp4",
        category: "hair_trends",
        title: "Latest Hairstyle Trends",
        desc: "Backstage interview with Tom Marcireau and the latest hairstyles for Spring/ Summer 2017",
      },
      {
        id: 71,
        type: "fashion",
        name: "hair_trends/104.jpeg",
        link: "hair_trends/104.mp4",
        category: "hair_trends",
        title: "All the latest hairstyles",
        desc: "Wet Look hairstyles for Spring/ Summer 2017",
      },
      {
        id: 72,
        type: "fashion",
        name: "makeup_trends/107.jpeg",
        link: "makeup_trends/107.mp4",
        category: "makeup_trends",
        title: "Makeup Trends - 2017",
        desc: "Interview with Julie Hoomans from NEXT",
      },
      {
        id: 73,
        type: "fashion",
        name: "makeup_trends/108.jpeg",
        link: "makeup_trends/108.mp4",
        category: "makeup_trends",
        title: "Interview: Tom Pecheux",
        desc: "Interview with Tom Pecheux, Makeup Artist",
      },
      {
        id: 74,
        type: "fashion",
        name: "makeup_trends/109.jpeg",
        link: "makeup_trends/109.mp4",
        category: "makeup_trends",
        title: "Makeup Trends - Pale Pink Lips",
        desc: "All the models on the runway wearing pale pink lipstick",
      },
      {
        id: 75,
        type: "fashion",
        name: "makeup_trends/110.jpeg",
        link: "makeup_trends/110.mp4",
        category: "makeup_trends",
        title: "Makeup Trends - Red Lips",
        desc: "All the models on the runway wearing Red lipstick",
      },
      {
        id: 76,
        type: "fashion",
        name: "photoshoots/112.jpeg",
        link: "photoshoots/112.mp4",
        category: "photoshoots",
        title: "Beauty through the ages",
        desc: "All of the past influences and cultures that have made modern beauty.",
      },
      {
        id: 77,
        type: "fashion",
        name: "photoshoots/113.jpeg",
        link: "photoshoots/113.mp4",
        category: "photoshoots",
        title: "Black and White Photoshoot with Swimwear model",
        desc: "Brunette beauty poses in beachwear",
      },
      {
        id: 78,
        type: "fashion",
        name: "photoshoots/114.jpeg",
        link: "photoshoots/114.mp4",
        category: "photoshoots",
        title: "Interview in NYC with model.",
        desc: "Stunning model discusses her day to day job on a New York rooftop photoshoot",
      },
      {
        id: 79,
        type: "fashion",
        name: "photoshoots/115.jpeg",
        link: "photoshoots/115.mp4",
        category: "photoshoots",
        title: "Mary Grace Swimwear ",
        desc: "Stunning model shows off on the beach in new Mary Grace Swimwear",
      },
      {
        id: 80,
        type: "fashion",
        name: "photoshoots/116.jpeg",
        link: "photoshoots/116.mp4",
        category: "photoshoots",
        title: "Mary Grace Swimwear Part 2",
        desc: "Stunning model shows off on the beach in new Mary Grace Swimwear - Part 2",
      },
      {
        id: 81,
        type: "fashion",
        name: "photoshoots/117.jpeg",
        link: "photoshoots/117.mp4",
        category: "photoshoots",
        title: "Mary Grace Swimwear Part 3 ",
        desc: "Stunning model shows off on the beach in new Mary Grace Swimwear - Part 3",
      },
      {
        id: 82,
        type: "fashion",
        name: "photoshoots/118.jpeg",
        link: "photoshoots/118.mp4",
        category: "photoshoots",
        title: "Mary Grace Swimwear Part 4 ",
        desc: "Stunning model shows off on the beach in new Mary Grace Swimwear - Part 4",
      },
      {
        id: 83,
        type: "fashion",
        name: "photoshoots/119.jpeg",
        link: "photoshoots/119.mp4",
        category: "photoshoots",
        title: "Treat it like its Glass",
        desc: "Beachside fashion photoshoot",
      },
      {
        id: 84,
        type: "fashion",
        name: "photoshoots/120.jpeg",
        link: "photoshoots/120.mp4",
        category: "photoshoots",
        title: "Urban photo shoot by the bed ",
        desc: "City apartment photoshoot",
      },
      {
        id: 85,
        type: "fashion",
        name: "photoshoots/121.jpeg",
        link: "photoshoots/121.mp4",
        category: "photoshoots",
        title: " Roadside casual fashion ",
        desc: " Modern casual fashion trends  ",
      },
      {
        id: 86,
        type: "fashion",
        name: "photoshoots/122.jpeg",
        link: "photoshoots/122.mp4",
        category: "photoshoots",
        title: " My Universe ",
        desc: " Photoshoot in a muscle car  ",
      },
      {
        id: 87,
        type: "fashion",
        name: "photoshoots/123.jpeg",
        link: "photoshoots/123.mp4",
        category: "photoshoots",
        title: "  Jonas B Photoshoot ",
        desc: " A year in review with a collection of fashion models  ",
      },
      {
        id: 88,
        type: "fashion",
        name: "photoshoots/124.jpeg",
        link: "photoshoots/124.mp4",
        category: "photoshoots",
        title: " Black and White Lake Photoshoot ",
        desc: " A modern black and white fashion shoot by a lake.  ",
      },
      {
        id: 89,
        type: "fashion",
        name: "photoshoots/125.jpeg",
        link: "photoshoots/125.mp4",
        category: "photoshoots",
        title: " KS Photoshoot ",
        desc: " New York photoshoot with KS  ",
      },
      {
        id: 90,
        type: "fashion",
        name: "photoshoots/126.jpeg",
        link: "photoshoots/126.mp4",
        category: "photoshoots",
        title: " Lingerie photoshoot in black and ",
        desc: " Modern lingerie photoshoot ",
      },
      {
        id: 91,
        type: "fashion",
        name: "runway/115.jpeg",
        link: "runway/115.mp4",
        category: "runway",
        title: " Lingerie Runway show - Paris ",
        desc: " Salon de la lingerie, Paris 2017 ",
      },
      {
        id: 92,
        type: "fashion",
        name: "runway/116.jpeg",
        link: "runway/116.mp4",
        category: "runway",
        title: " Vix Paula Hermanny ",
        desc: " Vix Paula Hermanny at the Miami Beach Funkshion  ",
      },
      {
        id: 93,
        type: "fashion",
        name: "runway/117.jpeg",
        link: "runway/117.mp4",
        category: "runway",
        title: " Anna Kosturova ",
        desc: " Anna Kosturova at the Miami Beach Funkshion  ",
      },
      {
        id: 94,
        type: "fashion",
        name: "runway/118.jpeg",
        link: "runway/118.mp4",
        category: "runway",
        title: " Luli Fama ",
        desc: " Luli Fama at the Miami Beach Funkshion  ",
      },
      {
        id: 95,
        type: "fashion",
        name: "runway/119.jpeg",
        link: "runway/119.mp4",
        category: "runway",
        title: " Sinesia Karol ",
        desc: " Sinesia Karol at the Miami Beach Funkshion  ",
      },
      {
        id: 96,
        type: "fashion",
        name: "runway/120.jpeg",
        link: "runway/120.mp4",
        category: "runway",
        title: " Tori Praver ",
        desc: " Tori Praver at the Miami Beach Funkshion  ",
      },
      {
        id: 97,
        type: "fashion",
        name: "runway/121.jpeg",
        link: "runway/121.mp4",
        category: "runway",
        title: " Maaji ",
        desc: " Maaji at the Miami Beach Funkshion  ",
      },
      {
        id: 98,
        type: "fashion",
        name: "runway/122.jpeg",
        link: "runway/122.mp4",
        category: "runway",
        title: " Lila Nikole ",
        desc: " Lila Nikole at the Miami Beach Funkshion  ",
      },
      {
        id: 99,
        type: "fashion",
        name: "runway/123.jpeg",
        link: "runway/123.mp4",
        category: "runway",
        title: " Victorias Secret ",
        desc: " Victorias Secret at the Pink Carpet, Paris  ",
      },
      {
        id: 100,
        type: "fashion",
        name: "runway/124.jpeg",
        link: "runway/124.mp4",
        category: "runway",
        title: " Victorias Secret Part 2",
        desc: " Victorias Secret at the Pink Carpet, Paris  - Part 2",
      },
      {
        id: 101,
        type: "fashion",
        name: "runway/125.jpeg",
        link: "runway/125.mp4",
        category: "runway",
        title: " Victorias Secret Part 3",
        desc: " Victorias Secret at the Pink Carpet, Paris  - Part 3",
      },
      {
        id: 102,
        type: "fashion",
        name: "runway/126.jpeg",
        link: "runway/126.mp4",
        category: "runway",
        title: " Victorias Secret Part 4",
        desc: " Victorias Secret at the Pink Carpet, Paris  - Part 4",
      },
      {
        id: 103,
        type: "fashion",
        name: "runway/127.jpeg",
        link: "runway/127.mp4",
        category: "runway",
        title: " Salon de la Lingerie ",
        desc: " Salon de la Lingerie, Paris 2017 - Part 1 ",
      },
      {
        id: 104,
        type: "fashion",
        name: "runway/128.jpeg",
        link: "runway/128.mp4",
        category: "runway",
        title: " Salon de la Lingerie ",
        desc: " Salon de la Lingerie, Paris 2017 - Part 2 ",
      },
      {
        id: 105,
        type: "fashion",
        name: "runway/129.jpeg",
        link: "runway/129.mp4",
        category: "runway",
        title: " For Love & Lemons ",
        desc: " For Love & Lemons at the Miami Beach Funkshion  ",
      },
      {
        id: 106,
        type: "fashion",
        name: "runway/130.jpeg",
        link: "runway/130.mp4",
        category: "runway",
        title: " Salon de la Lingerie ",
        desc: " Salon de la Lingerie at Paris 2017 ",
      },
      {
        id: 107,
        type: "fashion",
        name: "runway/131.jpeg",
        link: "runway/131.mp4",
        category: "runway",
        title: " Salon de la Lingerie ",
        desc: " Salon de la Lingerie at Paris 2017 - Part 3 ",
      },
      {
        id: 108,
        type: "fashion",
        name: "runway/132.jpeg",
        link: "runway/132.mp4",
        category: "runway",
        title: " Salon de la Lingerie ",
        desc: " Salon de la Lingerie at Paris 2017 - Part 4 ",
      },
    ],
  },
  fitness: {
    categories: [{ cat: "fitness", name: "Fitness Series" }],
    data: [
      {
        id: 1,
        type: "fitness",
        name: "Videos/101_m.jpg",
        link: "Videos/101.mp4",
        category: "fitness",
        title: "Get Faster Gains!",
        desc: "3 quick tips and tricks to get bigger muscle gains.",
      },
      {
        id: 2,
        type: "fitness",
        name: "Videos/102_m.jpg",
        link: "Videos/102.mp4",
        category: "fitness",
        title: "Biggest Dumbell Mistakes",
        desc: "Here's a list of the biggest mistakes made by beginners",
      },
      {
        id: 3,
        type: "fitness",
        name: "Videos/103_m.jpg",
        link: "Videos/103.mp4",
        category: "fitness",
        title: "Get Great Abs in 10 days",
        desc: "Easy workout to get great ab muscles",
      },
      {
        id: 4,
        type: "fitness",
        name: "Videos/104_m.jpg",
        link: "Videos/104.mp4",
        category: "fitness",
        title: "Beginner Mistakes To Avoid!",
        desc: "Methods to avoid in order to get the most out of your workout",
      },
      {
        id: 5,
        type: "fitness",
        name: "Videos/105_m.jpg",
        link: "Videos/105.mp4",
        category: "fitness",
        title: "Best Tricep Workout",
        desc: "Get bigger, more defined triceps today!",
      },
      {
        id: 6,
        type: "fitness",
        name: "Videos/106_m.jpg",
        link: "Videos/106.mp4",
        category: "fitness",
        title: "Lose those Man Boobs",
        desc: "Best exercises to build chest muscles",
      },
      {
        id: 7,
        type: "fitness",
        name: "Videos/107_m.jpg",
        link: "Videos/107.mp4",
        category: "fitness",
        title: "Biggest Push Up Mistakes",
        desc: "Get the most out of your push ups with this technique",
      },
      {
        id: 8,
        type: "fitness",
        name: "Videos/108_m.jpg",
        link: "Videos/108.mp4",
        category: "fitness",
        title: "Sculpt your Chest NOW",
        desc: "Get the pecs you've always dreamed of now",
      },
      {
        id: 9,
        type: "fitness",
        name: "Videos/109_m.jpg",
        link: "Videos/109.mp4",
        category: "fitness",
        title: "Extreme Cardio-Training",
        desc: "Burn off over 650 calories easily!",
      },
      {
        id: 10,
        type: "fitness",
        name: "Videos/110_m.jpg",
        link: "Videos/110.mp4",
        category: "fitness",
        title: "5 Best Squat Exercises",
        desc: "Learn to squat properly and get defined glutes",
      },
      {
        id: 11,
        type: "fitness",
        name: "Videos/111_m.jpg",
        link: "Videos/111.mp4",
        category: "fitness",
        title: "Zumba Workout ",
        desc: "Dance yourself fit with this fun workout you can do at home",
      },
      {
        id: 12,
        type: "fitness",
        name: "Videos/112_m.jpg",
        link: "Videos/112.mp4",
        category: "fitness",
        title: "Ultimate Ab Routine",
        desc: "Get the abs of your dreams now with these sets of tough ab blasting exercises.",
      },
      {
        id: 13,
        type: "fitness",
        name: "Videos/113_m.jpg",
        link: "Videos/113.mp4",
        category: "fitness",
        title: "Best Kettlebell Exercises",
        desc: "Check out this kettlebell workout routine now!",
      },
      {
        id: 14,
        type: "fitness",
        name: "Videos/114_m.jpg",
        link: "Videos/114.mp4",
        category: "fitness",
        title: "16 Bench Exercises for Women",
        desc: "16 amazing core workouts for your abs you can do any time on a work bench",
      },
      {
        id: 15,
        type: "fitness",
        name: "Videos/115_m.jpg",
        link: "Videos/115.mp4",
        category: "fitness",
        title: "Womens Chest Workout!",
        desc: "Grow your chest with these pec blasting exercises",
      },
      {
        id: 16,
        type: "fitness",
        name: "Videos/116_m.jpg",
        link: "Videos/116.mp4",
        category: "fitness",
        title: "XTREME Pecs Workout!!",
        desc: "Get chiseled looking pecs in no time with these simple tricks to doing pec workouts.",
      },
      {
        id: 17,
        type: "fitness",
        name: "Videos/117.jpg",
        link: "Videos/117.mp4",
        category: "fitness",
        title: "Quick Calf Workout",
        desc: "Get defined calves in just 5 minutes!",
      },
      {
        id: 18,
        type: "fitness",
        name: "Videos/118.jpg",
        link: "Videos/118.mp4",
        category: "fitness",
        title: "7 Exercises for a flat tummy",
        desc: "7 exercises with wonderful tips and tricks to engage your core and get a flat tummy",
      },
      {
        id: 19,
        type: "fitness",
        name: "Videos/119.jpg",
        link: "Videos/119.mp4",
        category: "fitness",
        title: "8 Minute Morning Workout",
        desc: "Blast calories with this intense 8 minute workout",
      },
      {
        id: 20,
        type: "fitness",
        name: "Videos/120.jpg",
        link: "Videos/120.mp4",
        category: "fitness",
        title: "Full Body Fat Burning!",
        desc: "Great workout you can do at home to get your heart rate up ",
      },
      {
        id: 21,
        type: "fitness",
        name: "Videos/121.jpg",
        link: "Videos/121.mp4",
        category: "fitness",
        title: "Get Thinner Thighs",
        desc: "Tone up your thighs with these easy to do exercises!",
      },
      {
        id: 22,
        type: "fitness",
        name: "Videos/122.jpg",
        link: "Videos/122.mp4",
        category: "fitness",
        title: "Ultimate Quad Workout",
        desc: "Target and build all 4 of your quad muscles",
      },
      {
        id: 23,
        type: "fitness",
        name: "Videos/123.jpg",
        link: "Videos/123.mp4",
        category: "fitness",
        title: "5 Shoulder Exercises",
        desc: "Get strong, well built shoulders with these superman exercises",
      },
      {
        id: 24,
        type: "fitness",
        name: "Videos/124.jpg",
        link: "Videos/124.mp4",
        category: "fitness",
        title: "Worlds best forearms?",
        desc: "Are these the worlds best forearms? Watch and find out!",
      },
      {
        id: 25,
        type: "fitness",
        name: "Videos/125.jpg",
        link: "Videos/125.mp4",
        category: "fitness",
        title: "The 5 Best Tricep Workouts",
        desc: "No arms are complete without lean strong triceps. Find out how to get those in this video!",
      },
      {
        id: 26,
        type: "fitness",
        name: "Videos/126.jpg",
        link: "Videos/126.mp4",
        category: "fitness",
        title: "Bicep Peaks - How to build mass!",
        desc: "Build towering bicep peaks in this intense bicep workout",
      },
      {
        id: 27,
        type: "fitness",
        name: "Videos/127.jpg",
        link: "Videos/127.mp4",
        category: "fitness",
        title: "Better Posture Workout",
        desc: "Walk straighter with this feel good workout routine to improve your posture.",
      },
      {
        id: 28,
        type: "fitness",
        name: "Videos/128.jpg",
        link: "Videos/128.mp4",
        category: "fitness",
        title: "Better Lat Muscle Gains!",
        desc: "Get stronger, more defined lats by engaging these muscles instead",
      },
      {
        id: 29,
        type: "fitness",
        name: "Videos/129.jpg",
        link: "Videos/129.mp4",
        category: "fitness",
        title: "One Weird Trick to Abs!",
        desc: "Doctors hate him! Find out how this mom of 6 discovered this one weird trick to better abs",
      },
      {
        id: 30,
        type: "fitness",
        name: "Videos/130.jpg",
        link: "Videos/130.mp4",
        category: "fitness",
        title: "30 Day Fat to Fit",
        desc: "Burn a tonne of fat with this intense, sweat pumping workout routine!",
      },
    ],
  },
  recipes: {
    categories: [{ cat: "food", name: "Food Series" }],
    data: [
      {
        id: 1,
        type: "recipes",
        name: "Video/food/101_m.JPG",
        link: "Video/food/101.mp4",
        category: "food",
        title: "Chicken Salad w/ Grapes",
        ingredients: [
          "Walnuts,",
          "Pine Nuts,",
          "Chicken Fillet,",
          "Red Grapes,",
          "Ground Black Pepper,",
          "Olive Oil,",
          "Mustard,",
          "Capers,",
          "Oregano,",
          "Sea Salt.",
        ],
        desc: "Delicious food recipe",
      },
      {
        id: 2,
        type: "recipes",
        name: "Video/food/102_m.JPG",
        link: "Video/food/102.mp4",
        category: "food",
        title: "Omelet w/ Ham Souffle",
        ingredients: [
          "Cream 20%",
          "Eggs",
          "Butter",
          "Ham",
          "Sour Cream",
          "Cheese",
          "Paprika",
          "Paray Onion",
          "Sea Salt",
          "Ground Black Pepper",
        ],
        desc: "Delicious food recipe",
      },
      {
        id: 3,
        type: "recipes",
        name: "Video/food/103_m.JPG",
        link: "Video/food/103.mp4",
        category: "food",
        title: "Ratatouille",
        ingredients: [
          "Zucchini",
          "Bay Leaves",
          "Garlic",
          "Butter",
          "Aubergine",
          "Parmesan",
          "Onions",
          "Tomatoes",
          "Bulgarian Pepper",
          "Ground Sea Salt",
          "Ground Black pepper",
          "Olive Oil",
        ],
        desc: "Delicious food recipe",
      },
      {
        id: 4,
        type: "recipes",
        name: "Video/food/104_m.JPG",
        link: "Video/food/104.mp4",
        category: "food",
        title: "Nicoise Salad w/ Arugula",
        ingredients: [
          "Red Peppers",
          "Olives",
          "Olive Oil",
          "Red onion",
          "Tomatoes",
          "Green Beans",
          "Capers",
          "Lemons",
          "Anchovy fillets",
          "Radish",
          "Balsamic sauce",
        ],
        desc: "Delicious food recipe",
      },
      {
        id: 5,
        type: "recipes",
        name: "Video/food/105_m.JPG",
        link: "Video/food/105.mp4",
        category: "food",
        title: "Baked Eggs w/ Shrimp",
        ingredients: [
          "Eggs",
          "Shrimp",
          "Cream 30%",
          "Sour cream",
          "Olive Oil",
          "Butter",
          "Ground black pepper",
          "Sea Salt",
        ],
        desc: "Delicious food recipe",
      },
      {
        id: 6,
        type: "recipes",
        name: "Video/food/106_m.JPG",
        link: "Video/food/106.mp4",
        category: "food",
        title: "Fish Provencal",
        ingredients: [
          "Green Beans",
          "Red Onions",
          "Dijon Mustard",
          "Lemons",
          "Parsley",
          "Ground Black Pepper",
          "Olive Oil",
          "White Wine",
          "Tomatoes",
          "White Fish Fillets",
        ],
        desc: "Delicious food recipe",
      },
      {
        id: 7,
        type: "recipes",
        name: "Video/food/107_m.JPG",
        link: "Video/food/107.mp4",
        category: "food",
        title: "Mushrooms from Greece",
        ingredients: [
          "Mushrooms",
          "Parsley",
          "Dill",
          "Celery",
          "Lemons",
          "Fennel",
          "Thyme",
          "Coriander",
          "Sea Salt",
        ],
        desc: "Delicious food recipe",
      },
      {
        id: 8,
        type: "recipes",
        name: "Video/food/108_m.JPG",
        link: "Video/food/108.mp4",
        category: "food",
        title: "Pico de Gallo",
        ingredients: [
          "Jalapeno Pepper",
          "Olive Oil",
          "Cilantro",
          "Tomatoes",
          "Red Onions",
          "Corn",
          "Avocado",
          "Lemons",
        ],
        desc: "Delicious food recipe",
      },
      {
        id: 9,
        type: "recipes",
        name: "Video/food/109_m.JPG",
        link: "Video/food/109.mp4",
        category: "food",
        title: "Pistou",
        ingredients: [
          "Black basil",
          "Green basil",
          "Garlic",
          "Parmesan",
          "Oliven oil",
        ],
        desc: "Delicious food recipe",
      },
      {
        id: 10,
        type: "recipes",
        name: "Video/food/110_m.JPG",
        link: "Video/food/110.mp4",
        category: "food",
        title: "Bitter Chocolate Orange Mousse",
        ingredients: [
          "Chocolate",
          "Butter",
          "Eggs",
          "Cream 40%",
          "Orange Liqueur",
          "Orange Zest",
        ],
        desc: "Delicious food recipe",
      },
      {
        id: 11,
        type: "recipes",
        name: "Video/food/111_m.JPG",
        link: "Video/food/111.mp4",
        category: "food",
        title: "Omelet w/ Cheese Gruyere",
        ingredients: [
          "Eggs",
          "Gruyere Cheese",
          "Baguette",
          "Garlic",
          "Parsley",
          "Olive Oil",
        ],
        desc: "Delicious food recipe",
      },
      {
        id: 12,
        type: "recipes",
        name: "Video/food/112_m.JPG",
        link: "Video/food/112.mp4",
        category: "food",
        title: "Quiche w/ Spinach",
        ingredients: [
          "Spinach",
          "Eggs",
          "Zucchini",
          "Puff Pastry",
          "Ground Black Pepper",
          "Cream 30%",
          "Butter",
          "Sea Salt",
          "Lemons",
        ],
        desc: "Delicious food recipe",
      },
      {
        id: 13,
        type: "recipes",
        name: "Video/food/113_m.JPG",
        link: "Video/food/113.mp4",
        category: "food",
        title: "Salad w/ Bananas",
        ingredients: [
          "Apples 200g",
          "Bananas 200g",
          "Celery 200g",
          "Yoghurt 50g",
          "Sour Cream 50g",
          "Mint Leaves",
        ],
        desc: "Delicious food recipe",
      },
      {
        id: 14,
        type: "recipes",
        name: "Video/food/114_m.JPG",
        link: "Video/food/114.mp4",
        category: "food",
        title: "Sandwich w/ Pear & Brie Cheese",
        ingredients: ["Bun", "Brie Cheese", "Pear", "Parma Ham", "Olive Oil"],
        desc: "Delicious food recipe",
      },
      {
        id: 15,
        type: "recipes",
        name: "Video/food/115_m.JPG",
        link: "Video/food/115.mp4",
        category: "food",
        title: "Spaghetti Carbonara",
        ingredients: [
          "Eggs",
          "Cream 20%",
          "Bacon",
          "Spaghetti",
          "White Onionl",
          "Ground Black Pepper",
          "Sea Salt",
          "Parmesan",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 16,
        type: "recipes",
        name: "Video/food/116_m.jpg",
        link: "Video/food/116_m.mp4",
        category: "food",
        title: "Homemade Cheesecake",
        ingredients: [
          "Sugar 25g",
          "Semolina 50g",
          "Cottage Cheese 200g",
          "Flour 200g",
          "Jam",
          "Joghurt",
          "Mint 10g",
          "Dessicated Coconut 10g",
          "2 Eggs",
          "Olive Oil",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 17,
        type: "recipes",
        name: "Video/food/117_m.jpg",
        link: "Video/food/117_m.mp4",
        category: "food",
        title: "Oatmeal Pancake w/ Cocoa",
        ingredients: [
          "Nuts 100g",
          "Sugar 25g",
          "Oatmeal 250g",
          "Eggs 3",
          "Cinammon",
          "Cocoa 50g",
          "Vanillin 20g",
          "Milk",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 18,
        type: "recipes",
        name: "Video/food/118_m.jpg",
        link: "Video/food/118_m.mp4",
        category: "food",
        title: "Spanish Toast & Orange Juice",
        ingredients: [
          "Buns",
          "7 Quail Eggs",
          "Olive Oil",
          "Lettuce",
          "Fresh chilli peppers",
          "Ham",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 19,
        type: "recipes",
        name: "Video/food/119_m.jpg",
        link: "Video/food/119_m.mp4",
        category: "food",
        title: "Pancakes w/ Apples",
        ingredients: [
          "Cinammon",
          "Coconuts",
          "2 Apples",
          "Flour 200g",
          "2 Eggs",
          "Sugar 50g",
          "Olive Oil",
          "Baking Soda",
          "Milk 200ml",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 20,
        type: "recipes",
        name: "Video/food/120_m.jpg",
        link: "Video/food/120_m.mp4",
        category: "food",
        title: "Scrambled Eggs w/ Garlic",
        ingredients: [
          "Hard cheese",
          "2 cucumbers",
          "Lemons",
          "Parsley",
          "Sugar 25g",
          "Garlic",
          "Butter",
          "Olive Oil",
          "Buns",
          "3 Eggs",
          "Soda Water",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 21,
        type: "recipes",
        name: "Video/food/121_m.jpg",
        link: "Video/food/121_m.mp4",
        category: "food",
        title: "Oatmeal w/ Apple & Black Tea Latte",
        ingredients: [
          "Cinammon",
          "Oatmeal",
          "2 Apples",
          "Butter",
          "Sugar",
          "Yoghurt",
          "Honey",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 22,
        type: "recipes",
        name: "Video/food/122_m.jpg",
        link: "Video/food/122_m.mp4",
        category: "food",
        title: "Danish Sandwich w/ Herring Cranberry Juice",
        ingredients: [
          "Lemons",
          "Onion",
          "Red Onion",
          "2 Potatoes",
          "Green Onions",
          "Buns",
          "Arugula",
          "Salted Salmon",
          "Herring Fillet",
          "Mustard",
          "Olive Oil",
          "Honey",
          "Hot Pepper",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 23,
        type: "recipes",
        name: "Video/food/123_m.jpg",
        link: "Video/food/123_m.mp4",
        category: "food",
        title: "Toast w/ Green Peas & Apple Juice",
        ingredients: [
          "Green peas 150g",
          "2 Tomatoes",
          "Lemons",
          "Butter 20g",
          "Toast (3 slices)",
          "2 Eggs",
          "Garlic",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 24,
        type: "recipes",
        name: "Video/food/124_m.jpg",
        link: "Video/food/124.mp4",
        category: "food",
        title: "Italian Summer Salad",
        ingredients: [
          "Lettuce",
          "2 Oranges",
          "Lemons",
          "Basil 20g",
          "2 Tomatoes",
          "Salad 50g",
          "Parma ham 20g",
          "Mozzarella",
          "Ciabatta",
          "Olive Oil",
          "Balsamic",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 25,
        type: "recipes",
        name: "Video/food/125_m.jpg",
        link: "Video/food/125.mp4",
        category: "food",
        title: "Salad w/ White Beans",
        ingredients: [
          "Celery 200g",
          "Carrots 100g",
          "1 Onion",
          "White beans 100g",
          "4 Slices of Ham",
          "Garlic",
          "Grapefruit",
          "Red Onion",
          "Wine Vinegar",
          "Black Pepper",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 26,
        type: "recipes",
        name: "Video/food/126_m.jpg",
        link: "Video/food/126.mp4",
        category: "food",
        title: "Bruschetta w/ Tomatoes",
        ingredients: [
          "Ham 25g",
          "Cane Sugar 15g",
          "1 Tomato",
          "1 Lemons",
          "Mint",
          "Basil",
          "Quail Eggs",
          "1 Lime",
          "Balsamic",
          "Garlic",
          "Baguette",
          "Olive Oil",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 27,
        type: "recipes",
        name: "Video/food/127_m.jpg",
        link: "Video/food/127.mp4",
        category: "food",
        title: "Shakshuks w/ Orange Juice & Lemonade",
        ingredients: [
          "Tumeric 5gr",
          "1 Tomato",
          "Garlic",
          "Onion",
          "Parsley 25g",
          "1 Chilli",
          "Olive Oil",
          "2 Eggs",
          "Cumin",
          "2 Paprika",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 28,
        type: "recipes",
        name: "Video/food/128_m.jpg",
        link: "Video/food/128.mp4",
        category: "food",
        title: "Banana Pancakes & Dry Apple Juice",
        ingredients: [
          "2 Bananas",
          "Sugar 25g",
          "Dry Apple Juice 100g",
          "Bran Flour 150g",
          "Nuts 25g",
          "Walnuts 25g",
          "Honey",
          "Milk 100ml",
          "Olive Oil 15ml",
          "2 Eggs",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 29,
        type: "recipes",
        name: "Video/food/129_m.jpg",
        link: "Video/food/129.mp4",
        category: "food",
        title: "Muesli Cookies & Masala Tea",
        ingredients: [
          "Milk 200ml",
          "Müsli 300g",
          "2 Eggs",
          "Jam 50g",
          "Ginger",
          "Sugar 20g",
          "Cardamom",
          "Instant Coffee",
          "Nutmeg",
          "Butter 15g",
          "Cloves",
          "Cinammon",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 30,
        type: "recipes",
        name: "Video/food/130_m.jpg",
        link: "Video/food/130.mp4",
        category: "food",
        title: "Homemade Burgers",
        ingredients: [
          "Chicken breast 200g",
          "Buns",
          "Lettuce 100g",
          "Mushrooms 200g",
          "Butter 100g",
          "2 Tomatoes",
          "Sugar 50g",
          "Wine Vinegar",
        ],
        desc: "Delicious food recipe",
      },
      {
        id: 31,
        type: "recipes",
        name: "Video/food/139_m.jpg",
        link: "Video/food/139.mp4",
        category: "food",
        title: "Bruschetta w/ Pesto",
        ingredients: [
          "red pepper",
          "yellow pepper",
          "baguette",
          "pine nuts",
          "garlic",
          "olive oil",
        ],
        desc: "Delicious food recipe",
      },
      {
        id: 32,
        type: "recipes",
        name: "Video/food/140_m.jpg",
        link: "Video/food/140.mp4",
        category: "food",
        title: "Pasta w/ Tomatoes and Cheese",
        ingredients: [
          "Pasta",
          "cheese",
          "Tomatoes  ",
          "garlic",
          "Yoghurt ",
          "ground black pepper",
        ],
        desc: "Delicious food recipe",
      },
      {
        id: 33,
        type: "recipes",
        name: "Video/food/141_m.jpg",
        link: "Video/food/141.mp4",
        category: "food",
        title: "Pasta w/ Tuna & Spinach",
        ingredients: [
          "Tagliattele Pasta",
          " Spinach",
          "tuna in oil",
          "olive oil",
          "garlic",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 34,
        type: "recipes",
        name: "Video/food/142_m.jpg",
        link: "Video/food/142.mp4",
        category: "food",
        title: "Salad w/ Arugula & Parmesan",
        ingredients: [
          "Fennel",
          "Parmesan",
          "Arugula",
          "Pine Nuts",
          "ground black pepper",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 35,
        type: "recipes",
        name: "Video/food/143_m.jpg",
        link: "Video/food/143.mp4",
        category: "food",
        title: "Tagliatelle w/ Zucchini",
        ingredients: [
          "Tagliatelle  pasta",
          "zucchini",
          "Chili Pepper ",
          "garlic",
          "lemon",
          "olive oil",
          "Parmesan  ",
          "ground black pepper",
          "sea salt",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 36,
        type: "recipes",
        name: "Video/food/145_m.jpg",
        link: "Video/food/145.mp4",
        category: "food",
        title: "Greek Salad w/ Pasta & Tuna",
        ingredients: [
          "tuna in oil",
          "lemon",
          "cucumber",
          "cherry tomatoes",
          "feta cheese",
          "olive oil",
          "onion",
          "ground black pepper",
          "sea salt",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 37,
        type: "recipes",
        name: "Video/food/146_m.jpg",
        link: "Video/food/146.mp4",
        category: "food",
        title: "Baked Pasta w/ Zucchini & Cheese",
        ingredients: [
          "Tagliatelle Pasta",
          "Olive oil",
          "Chili Pepper",
          "Thyme",
          "butter",
          "lemon",
          "ground black pepper",
          "sea salt",
        ],
        desc: "Delicious food recipe",
      },
      {
        id: 38,
        type: "recipes",
        name: "Video/food/147_m.jpg",
        link: "Video/food/147.mp4",
        category: "food",
        title: "Italian Salad w/ Pasta & Tuna",
        ingredients: [
          "Bulgarian  Pepper",
          "olive oil",
          "Arugula",
          "Sun dried tomatoes",
          "Mozzarella cheese",
          "tuna in oil",
          "Pasta farfalle",
          "ground black pepper",
          "sea salt",
        ],
        desc: "Delicious food recipe",
      },
      {
        id: 39,
        type: "recipes",
        name: "Video/food/148_m.jpg",
        link: "Video/food/148.mp4",
        category: "food",
        title: "Spinach Soup w/ Meatballs",
        ingredients: [
          "spinach",
          "milk",
          "Parmesan",
          "baguette",
          "minced beef",
          "egg",
          "ground black pepper",
          "sea salt",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 40,
        type: "recipes",
        name: "Video/food/149_m.jpg",
        link: "Video/food/149.mp4",
        category: "food",
        title: "Salad w/ Roast Beets",
        ingredients: [
          "Beets",
          "olive oil",
          "pine nuts",
          "ground black pepper",
          "sea salt",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 41,
        type: "recipes",
        name: "Video/food/150_m.jpg",
        link: "Video/food/150.mp4",
        category: "food",
        title: "Grilled Salmon w/ Salad of Arugula",
        ingredients: [
          "Fresh salmon",
          "cherry tomatoes",
          "arugula",
          "red onion",
          "olive oil",
          "lemon",
          "ground black pepper",
          "sea salt",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 42,
        type: "recipes",
        name: "Video/food/151_m.jpg",
        link: "Video/food/151.mp4",
        category: "food",
        title: "Bread Soup",
        ingredients: [
          "White bread",
          "red onion",
          "cucumber",
          "tomatoes",
          "yoghurt",
          "Balsamic  vinegar",
          "ground black pepper",
          "sea salt",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 43,
        type: "recipes",
        name: "Video/food/152_m.jpg",
        link: "Video/food/152.mp4",
        category: "food",
        title: "Rice Salad",
        ingredients: [
          "Red onion",
          "rice",
          "Bulgarian pepper",
          "green basil",
          "olive oil",
          "Balsamic  vinegar",
          "sea salt",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 44,
        type: "recipes",
        name: "Video/food/153_m.jpg",
        link: "Video/food/153.mp4",
        category: "food",
        title: "Fish Salad w/ Oranges",
        ingredients: [
          "oranges",
          "lemon",
          "leek",
          "walnuts",
          "red wine vinegar",
          "chili paste",
          "smoked salmon",
          "sour cream",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 45,
        type: "recipes",
        name: "Video/food/154_m.jpg",
        link: "Video/food/154.mp4",
        category: "food",
        title: "Corn Salad",
        ingredients: [
          "Corn",
          "cherry tomatoes",
          "apples",
          "olives",
          "olive oil",
          "red wine vinegar",
          "ground black pepper",
          "sea salt",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 46,
        type: "recipes",
        name: "Video/food/155_m.jpg",
        link: "Video/food/155.mp4",
        category: "food",
        title: "Cheese & Citrus Salad",
        ingredients: [
          "lemon",
          "Grapefruit",
          "oranges",
          "cheese lambert",
          "lime",
          "canned artichokes",
          "cinnamon",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 47,
        type: "recipes",
        name: "Video/food/156_m.jpg",
        link: "Video/food/156.mp4",
        category: "food",
        title: "White Fish w/ Smoked Salmon",
        ingredients: [
          "smoked salmon",
          "white fish fillets",
          "green basil",
          "sour cream",
          "olive oil",
          "Balsamic  vinegar",
          "ground black pepper",
          "sea salt",
          "white wine",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 48,
        type: "recipes",
        name: "Video/food/157_m.jpg",
        link: "Video/food/157.mp4",
        category: "food",
        title: "Shrimps w/ Garlic",
        ingredients: [
          "Shrimp",
          "Garlic",
          "Lemon",
          "Chili Papper",
          "Parsley",
          "olive oil",
          "Sea Salt",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 49,
        type: "recipes",
        name: "Video/food/158_m.jpg",
        link: "Video/food/158.mp4",
        category: "food",
        title: "Braised Quail w/ Thyme",
        ingredients: [
          "Thyme",
          "Quail",
          "bay Leaf",
          "White onion",
          "garlic",
          "Chili Pepper",
          "mustard",
          "olive oil",
          "red wine vinegar",
          "ground black pepper",
          "sea salt",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 50,
        type: "recipes",
        name: "Video/food/159_m.jpg",
        link: "Video/food/159.mp4",
        category: "food",
        title: "Cucumber & Melon Cold Soup",
        ingredients: [
          "Melon",
          "Garlic",
          "Lemon",
          "Olive Oil",
          "Chili Pepper",
          "Arugula",
          "Mint",
          "Cucumber",
          "Yoghurt",
          "ground black pepper",
          "sea salt",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 51,
        type: "recipes",
        name: "Video/food/160_m.jpg",
        link: "Video/food/160.mp4",
        category: "food",
        title: "Fish Balls w/ Peanut Sauce",
        ingredients: [
          "White fish fillet",
          "Onion",
          "Eggs",
          "Parsley",
          "White Bread",
          "Wheat Flour",
          "Olive oil",
          "Ground Black Pepper",
          "Almonds",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 52,
        type: "recipes",
        name: "Video/food/161_m.jpg",
        link: "Video/food/161.mp4",
        category: "food",
        title: "Pintxos of Padron",
        ingredients: [
          "Carrot",
          "Chicken Fillet",
          "Lemon juice",
          "olive oil",
          "Mushrooms ",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 53,
        type: "recipes",
        name: "Video/food/162_m.jpg",
        link: "Video/food/162.mp4",
        category: "food",
        title: "Tartilya",
        ingredients: [
          "Onion",
          "Eggs",
          "Chili Pepper",
          "Green basil",
          "Olive oil",
          "ground black pepper",
          "sea salt",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 54,
        type: "recipes",
        name: "Video/food/163_m.jpg",
        link: "Video/food/163.mp4",
        category: "food",
        title: "Tartilya w/ Zucchini",
        ingredients: [
          "Zucchini",
          "Onion",
          "Eggs",
          "Green Basil",
          "olive oil",
          "Chili Pepper",
          "ground black pepper",
          "sea salt",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 55,
        type: "recipes",
        name: "Video/food/164_m.jpg",
        link: "Video/food/164.mp4",
        category: "food",
        title: "Cauliflower w/ Parmesan ",
        ingredients: [
          "Parmesan",
          "Cauliflower",
          "Cream 40%",
          "ground black pepper",
          "sea salt",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 56,
        type: "recipes",
        name: "Video/food/166_m.jpg",
        link: "Video/food/166.mp4",
        category: "food",
        title: "Salad of Grilled Zucchini",
        ingredients: [
          "Zucchini ",
          "Parsley",
          "Garlic",
          "Parmesan",
          "Olive oil",
          "Red wine vinegar",
          "ground black pepper",
          "sea salt",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 57,
        type: "recipes",
        name: "Video/food/167_m.jpg",
        link: "Video/food/167.mp4",
        category: "food",
        title: "Smoked Salmon w/ orange sauce",
        ingredients: [
          "smoked salmon",
          "onion",
          "parsley",
          "saffron",
          "oranges",
          "butter",
          "olive oil",
          "lemon",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 58,
        type: "recipes",
        name: "Video/food/168_m.jpg",
        link: "Video/food/168.mp4",
        category: "food",
        title: "Minestrone Soup",
        ingredients: [
          "Yellow pepper",
          "tomatoes",
          "paray onion",
          "peas",
          "green beans",
          "olive oil",
          "sea salt",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 59,
        type: "recipes",
        name: "Video/food/169_m.jpg",
        link: "Video/food/169.mp4",
        category: "food",
        title: "Tartar Sauce",
        ingredients: [
          "Capers",
          "Yoghurt",
          "Parsely",
          "White Onion",
          "Thyme",
          "Lemon Juice",
          "Tabasco Sauce",
          "Mustard",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 60,
        type: "recipes",
        name: "Video/food/170_m.jpg",
        link: "Video/food/170.mp4",
        category: "food",
        title: "Potato Salad w/ Tuna",
        ingredients: [
          "Tuna in oil",
          "Red onion",
          "Pine nuts",
          "Parsley",
          "Garlic",
          "boiled beans",
          "Paprika",
          "olive oil",
          "potatoes",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 61,
        type: "recipes",
        name: "Video/food/171_m.jpg",
        link: "Video/food/171.mp4",
        category: "food",
        title: "Salted Lemons",
        ingredients: [
          "Lemons",
          "Bay Leaf",
          "Olive Oil",
          "Sea salt",
          "Ground black pepper",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 62,
        type: "recipes",
        name: "Video/food/172_m.jpg",
        link: "Video/food/172.mp4",
        category: "food",
        title: "Mediterranean Salad of Lentils",
        ingredients: [
          "Lentils",
          "Tomatoes",
          "Garlic",
          "Bulgarian pepper",
          "Onions",
          "olive oil",
          "Balsamic vinegar",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 63,
        type: "recipes",
        name: "Video/food/173_m.jpg",
        link: "Video/food/173.mp4",
        category: "food",
        title: "Oriental Salad Tabbouleh",
        ingredients: [
          "Rice",
          "Tomatoes",
          "Couscous",
          "Lemon",
          "Cucubmber",
          "ground black pepper",
          "sea salt",
          "olive oil",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 64,
        type: "recipes",
        name: "Video/food/174_m.jpg",
        link: "Video/food/174.mp4",
        category: "food",
        title: "Baked Omelet w/ Peas",
        ingredients: [
          "Peas",
          "Eggs",
          "Parmesan",
          "butter",
          "eggs",
          "olive oil",
          "Milk",
          "Green string beans",
          "ground black pepper",
          "sea salt",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 65,
        type: "recipes",
        name: "Video/food/175_m.jpg",
        link: "Video/food/175.mp4",
        category: "food",
        title: "Couscous w/ Vegetables",
        ingredients: [
          "Couscous",
          "Avocado",
          "Lemon",
          "Olive Oil",
          "Sea Salt",
          "Chicken Fillet",
          "Cherry Tomatoes",
          "Tomatoes",
          "Lime",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 66,
        type: "recipes",
        name: "Video/food/176_m.jpg",
        link: "Video/food/176.mp4",
        category: "food",
        title: "Caster Frittata",
        ingredients: [
          "Tomatoes",
          "Eggs",
          "Offal Lamb and Turkey",
          "Butter",
          "Olive Oil",
          "ground black pepper",
          "sea salt",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 67,
        type: "recipes",
        name: "Video/food/177_m.jpg",
        link: "Video/food/177.mp4",
        category: "food",
        title: "Risotto w/ Salmon",
        ingredients: [
          "Rice",
          "Olive Oil",
          "Butter",
          "Onion",
          "TOmatoes",
          "Red Pepper",
          "salmon",
          "cucumber",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 68,
        type: "recipes",
        name: "Video/food/178_m.jpg",
        link: "Video/food/178.mp4",
        category: "food",
        title: "Tuna Salad w/ Fennel",
        ingredients: [
          "White Onion",
          "Red pepper",
          "Yellow Pepper",
          "Lettuce",
          "Fennel",
          "Tuna",
          "Celery",
          "Lemon",
          "Olive Oil",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 69,
        type: "recipes",
        name: "Video/food/179_m.jpg",
        link: "Video/food/179.mp4",
        category: "food",
        title: "Pear w/ Chocolate Sauce",
        ingredients: ["Pear", "Chocolate", "Cream 20%", "Parmesan"],
        desc: "Delicious food recipe",
      },

      {
        id: 70,
        type: "recipes",
        name: "Video/food/180_m.jpg",
        link: "Video/food/180.mp4",
        category: "food",
        title: "Danish Sandwich ",
        ingredients: [
          "Red onion",
          "Mustard",
          "Salmon",
          "Green onion",
          "rye bread",
          "Arugula",
          "Lemon",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 71,
        type: "recipes",
        name: "Video/food/181_m.jpg",
        link: "Video/food/181.mp4",
        category: "food",
        title: "Kiwi Smoothies",
        ingredients: ["Strawberry", "Kiwi", "Bananas"],
        desc: "Delicious food recipe",
      },

      {
        id: 72,
        type: "recipes",
        name: "Video/food/182_m.jpg",
        link: "Video/food/182.mp4",
        category: "food",
        title: "Spread Avocado & Olives",
        ingredients: [
          "olives",
          "Avocado  ",
          "Garlic",
          "Breadcrumbs",
          "olive oil",
          "sea salt",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 73,
        type: "recipes",
        name: "Video/food/183_m.jpg",
        link: "Video/food/183.mp4",
        category: "food",
        title: "Steak Roll w/ Avocado ",
        ingredients: [
          "Red onion",
          "avocado",
          "garlic",
          "sour cream",
          "paprika",
          "olive oil",
          "horseradish",
          "steak",
          "arugula",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 74,
        type: "recipes",
        name: "Video/food/184_m.jpg",
        link: "Video/food/184.mp4",
        category: "food",
        title: "Cool Red Tea",
        ingredients: ["Lemons", "Pomegranate", "Hibiscus", "Tea"],
        desc: "Delicious food recipe",
      },

      {
        id: 75,
        type: "recipes",
        name: "Video/food/185_m.jpg",
        link: "Video/food/185.mp4",
        category: "food",
        title: "Bananas in Chocolate  ",
        ingredients: ["Bananas", "Chocolate", "Peanuts"],
        desc: "Delicious food recipe",
      },

      {
        id: 76,
        type: "recipes",
        name: "Video/food/187_m.jpg",
        link: "Video/food/187.mp4",
        category: "food",
        title: "Strawberry Cream Souffle",
        ingredients: ["Milk", "Strawberry", "Gelatin"],
        desc: "Delicious food recipe",
      },

      {
        id: 77,
        type: "recipes",
        name: "Video/food/188_m.jpg",
        link: "Video/food/188.mp4",
        category: "food",
        title: "Rafaello Cream",
        ingredients: ["White Chocolate", "Coconut", "Condensed Milk", "butter"],
        desc: "Delicious food recipe",
      },

      {
        id: 78,
        type: "recipes",
        name: "Video/food/189_m.jpg",
        link: "Video/food/189.mp4",
        category: "food",
        title: "Sweets w/ Honey & Seeds",
        ingredients: [
          "Honey",
          "Peanuts",
          "Blue poppy",
          "sunflower seeds",
          "Banana",
        ],
        desc: "Delicious food recipe",
      },

      {
        id: 79,
        type: "recipes",
        name: "Video/food/190_m.jpg",
        link: "Video/food/190.mp4",
        category: "food",
        title: "Ricotta Cheesecake w/ Raspberries",
        ingredients: [
          "Raspberries",
          "Ricotta cheese",
          "butter",
          "lemon",
          "eggs",
        ],
        desc: "Delicious food recipe",
      },
    ],
  },
  games: {
    categories: [
      { cat: "shooter", name: "Shooter" },
      { cat: "arcade", name: "Arcade" },
      { cat: "strategy", name: "Strategy" },
      { cat: "racing", name: "Racing" },
      { cat: "brain_training", name: "Brain Training" },
      { cat: "other", name: "Random" },
    ],
    data: [
      {
        id: 0,
        type: "games",
        title: "zombie shooter",
        desc: "Play online game",
        name: "zombie_shooter/thumb.jpg",
        link: "zombie_shooter/index.html",
        category: "shooter",
      },
      {
        id: 1,
        type: "games",
        title: "zombie buster",
        desc: "Play online game",
        name: "zombie_buster/thumb.jpg",
        link: "zombie_buster/index.html",
        category: "shooter",
      },
      {
        id: 2,
        type: "games",
        title: "wordpuzzle",
        desc: "Play online game",
        name: "wordpuzzle/thumb.jpg",
        link: "wordpuzzle/index.html",
        category: "brain_training",
      },
      {
        id: 3,
        type: "games",
        title: "traffic",
        desc: "Play online game",
        name: "traffic/thumb.jpg",
        link: "traffic/index.html",
        category: "arcade",
      },
      {
        id: 4,
        type: "games",
        title: "touch ball",
        desc: "Play online game",
        name: "touch_ball/thumb.jpg",
        link: "touch_ball/index.html",
        category: "other",
      },
      {
        id: 5,
        type: "games",
        title: "the operators",
        desc: "Play online game",
        name: "the_operators/thumb.jpg",
        link: "the_operators/index.html",
        category: "brain_training",
      },
      {
        id: 6,
        type: "games",
        title: "tank wars",
        desc: "Play online game",
        name: "tank_wars/thumb.jpg",
        link: "tank_wars/index.html",
        category: "shooter",
      },
      {
        id: 7,
        type: "games",
        title: "tank defender",
        desc: "Play online game",
        name: "tank_defender/thumb.jpg",
        link: "tank_defender/index.html",
        category: "shooter",
      },
      {
        id: 8,
        type: "games",
        title: "swat vs zombies",
        desc: "Play online game",
        name: "swat_vs_zombies/thumb.jpg",
        link: "swat_vs_zombies/index.html",
        category: "arcade",
      },
      {
        id: 9,
        type: "games",
        title: "super pongoal",
        desc: "Play online game",
        name: "super_pongoal/thumb.jpg",
        link: "super_pongoal/index.html",
        category: "other",
      },
      {
        id: 10,
        type: "games",
        title: "super cowboy run",
        desc: "Play online game",
        name: "super_cowboy_run/thumb.jpg",
        link: "super_cowboy_run/index.html",
        category: "arcade",
      },
      {
        id: 11,
        type: "games",
        title: "super color lines",
        desc: "Play online game",
        name: "super_color_lines/thumb.jpg",
        link: "super_color_lines/index.html",
        category: "strategy",
      },
      {
        id: 12,
        type: "games",
        title: "supercars puzzle",
        desc: "Play online game",
        name: "supercars_puzzle/thumb.jpg",
        link: "supercars_puzzle/index.html",
        category: "strategy",
      },
      {
        id: 13,
        type: "games",
        title: "stick soldier",
        desc: "Play online game",
        name: "stick_soldier/thumb.jpg",
        link: "stick_soldier/index.html",
        category: "other",
      },
      {
        id: 14,
        type: "games",
        title: "stick monkey",
        desc: "Play online game",
        name: "stick_monkey/thumb.jpg",
        link: "stick_monkey/index.html",
        category: "other",
      },
      {
        id: 15,
        type: "games",
        title: "speed racer",
        desc: "Play online game",
        name: "speed_racer/thumb.jpg",
        link: "speed_racer/index.html",
        category: "racing",
      },
      {
        id: 16,
        type: "games",
        title: "space purge",
        desc: "Play online game",
        name: "space_purge/thumb.jpg",
        link: "space_purge/index.html",
        category: "arcade",
      },
      {
        id: 17,
        type: "games",
        title: "smiles",
        desc: "Play online game",
        name: "smiles/thumb.jpg",
        link: "smiles/index.html",
        category: "arcade",
      },
      {
        id: 18,
        type: "games",
        title: "shoot robbers",
        desc: "Play online game",
        name: "shoot_robbers/thumb.jpg",
        link: "shoot_robbers/index.html",
        category: "shooter",
      },
      {
        id: 19,
        type: "games",
        title: "schitalochka",
        desc: "Play online game",
        name: "schitalochka/thumb.jpg",
        link: "schitalochka/index.html",
        category: "strategy",
      },
      {
        id: 20,
        type: "games",
        title: "robotex",
        desc: "Play online game",
        name: "robotex/thumb.jpg",
        link: "robotex/index.html",
        category: "strategy",
      },
      {
        id: 21,
        type: "games",
        title: "ranger vs zombies",
        desc: "Play online game",
        name: "ranger_vs_zombies/thumb.jpg",
        link: "ranger_vs_zombies/index.html",
        category: "other",
      },
      {
        id: 22,
        type: "games",
        title: "quick dice",
        desc: "Play online game",
        name: "quick_dice/thumb.jpg",
        link: "quick_dice/index.html",
        category: "other",
      },
      {
        id: 23,
        type: "games",
        title: "professor bubble",
        desc: "Play online game",
        name: "professor_bubble/thumb.jpg",
        link: "professor_bubble/index.html",
        category: "other",
      },
      {
        id: 24,
        type: "games",
        title: "plumber",
        desc: "Play online game",
        name: "plumber/thumb.jpg",
        link: "plumber/index.html",
        category: "arcade",
      },
      {
        id: 25,
        type: "games",
        title: "ninja run 1",
        desc: "Play online game",
        name: "ninja_run_1/thumb.jpg",
        link: "ninja_run_1/index.html",
        category: "arcade",
      },
      {
        id: 26,
        type: "games",
        title: "ninja run",
        desc: "Play online game",
        name: "ninja_run/thumb.jpg",
        link: "ninja_run/index.html",
        category: "arcade",
      },
      {
        id: 27,
        type: "games",
        title: "ninja copter",
        desc: "Play online game",
        name: "ninja_copter/thumb.jpg",
        link: "ninja_copter/index.html",
        category: "arcade",
      },
      {
        id: 28,
        type: "games",
        title: "ninja blade original",
        desc: "Play online game",
        name: "ninja_blade_original/thumb.jpg",
        link: "ninja_blade_original/index.html",
        category: "other",
      },
      {
        id: 29,
        type: "games",
        title: "mummy candies",
        desc: "Play online game",
        name: "mummy_candies/thumb.jpg",
        link: "mummy_candies/index.html",
        category: "other",
      },
      {
        id: 30,
        type: "games",
        title: "memory game",
        desc: "Play online game",
        name: "memory_game/thumb.jpg",
        link: "memory_game/index.html",
        category: "brain_training",
      },
      {
        id: 31,
        type: "games",
        title: "memorygame",
        desc: "Play online game",
        name: "memorygame/thumb.jpg",
        link: "memorygame/index.html",
        category: "brain_training",
      },
      {
        id: 32,
        type: "games",
        title: "match game for kids",
        desc: "Play online game",
        name: "match_game_for_kids/thumb.jpg",
        link: "match_game_for_kids/index.html",
        category: "brain_training",
      },
      {
        id: 33,
        type: "games",
        title: "mad shark",
        desc: "Play online game",
        name: "mad_shark/thumb.jpg",
        link: "mad_shark/index.html",
        category: "other",
      },
      {
        id: 34,
        type: "games",
        title: "kids true color",
        desc: "Play online game",
        name: "kids_true_color/thumb.jpg",
        link: "kids_true_color/index.html",
        category: "other",
      },
      {
        id: 35,
        type: "games",
        title: "jelly match 3",
        desc: "Play online game",
        name: "jelly_match_3/thumb.jpg",
        link: "jelly_match_3/index.html",
        category: "brain_training",
      },
      {
        id: 36,
        type: "games",
        title: "Jellybreak",
        desc: "Play online game",
        name: "Jellybreak/thumb.jpg",
        link: "Jellybreak/index.html",
        category: "other",
      },
      {
        id: 37,
        type: "games",
        title: "indiara skill gold",
        desc: "Play online game",
        name: "indiara_skill_gold/thumb.jpg",
        link: "indiara_skill_gold/index.html",
        category: "other",
      },
      {
        id: 38,
        type: "games",
        title: "hot jewels",
        desc: "Play online game",
        name: "hot_jewels/thumb.jpg",
        link: "hot_jewels/index.html",
        category: "other",
      },
      {
        id: 39,
        type: "games",
        title: "halloween memory",
        desc: "Play online game",
        name: "halloween_memory/thumb.jpg",
        link: "halloween_memory/index.html",
        category: "brain_training",
      },
      {
        id: 40,
        type: "games",
        title: "halloween bubble shooter",
        desc: "Play online game",
        name: "halloween_bubble_shooter/thumb.jpg",
        link: "halloween_bubble_shooter/index.html",
        category: "shooter",
      },
      {
        id: 41,
        type: "games",
        title: "great air battles",
        desc: "Play online game",
        name: "great_air_battles/thumb.jpg",
        link: "great_air_battles/index.html",
        category: "other",
      },
      {
        id: 42,
        type: "games",
        title: "gold miner",
        desc: "Play online game",
        name: "gold_miner/thumb.jpg",
        link: "gold_miner/index.html",
        category: "other",
      },
      {
        id: 43,
        type: "games",
        title: "girl dress up",
        desc: "Play online game",
        name: "girl_dress_up/thumb.jpg",
        link: "girl_dress_up/index.html",
        category: "other",
      },
      {
        id: 44,
        type: "games",
        title: "fruit snake",
        desc: "Play online game",
        name: "fruit_snake/thumb.jpg",
        link: "fruit_snake/index.html",
        category: "arcade",
      },
      {
        id: 45,
        type: "games",
        title: "fruit slasher",
        desc: "Play online game",
        name: "fruit_slasher/thumb.jpg",
        link: "fruit_slasher/index.html",
        category: "other",
      },
      {
        id: 46,
        type: "games",
        title: "flappy bounce",
        desc: "Play online game",
        name: "flappy_bounce/thumb.jpg",
        link: "flappy_bounce/index.html",
        category: "arcade",
      },
      {
        id: 47,
        type: "games",
        title: "flappy ball",
        desc: "Play online game",
        name: "flappy_ball/thumb.jpg",
        link: "flappy_ball/index.html",
        category: "arcade",
      },
      {
        id: 48,
        type: "games",
        title: "fish world match 3",
        desc: "Play online game",
        name: "fish_world_match_3/thumb.jpg",
        link: "fish_world_match_3/index.html",
        category: "brain_training",
      },
      {
        id: 49,
        type: "games",
        title: "fishing frenzy",
        desc: "Play online game",
        name: "fishing_frenzy/thumb.jpg",
        link: "fishing_frenzy/index.html",
        category: "other",
      },
      {
        id: 50,
        type: "games",
        title: "easter memory",
        desc: "Play online game",
        name: "easter_memory/thumb.jpg",
        link: "easter_memory/index.html",
        category: "brain_training",
      },
      {
        id: 51,
        type: "games",
        title: "duck shooter",
        desc: "Play online game",
        name: "duck_shooter/thumb.jpg",
        link: "duck_shooter/index.html",
        category: "other",
      },
      {
        id: 52,
        type: "games",
        title: "duck hunter",
        desc: "Play online game",
        name: "duck_hunter/thumb.jpg",
        link: "duck_hunter/index.html",
        category: "other",
      },
      {
        id: 53,
        type: "games",
        title: "crazy runner",
        desc: "Play online game",
        name: "crazy_runner/thumb.jpg",
        link: "crazy_runner/index.html",
        category: "other",
      },
      {
        id: 54,
        type: "games",
        title: "corssword for kids",
        desc: "Play online game",
        name: "corssword_for_kids/thumb.jpg",
        link: "corssword_for_kids/index.html",
        category: "brain_training",
      },
      {
        id: 55,
        type: "games",
        title: "christmas panda run",
        desc: "Play online game",
        name: "christmas_panda_run/thumb.jpg",
        link: "christmas_panda_run/index.html",
        category: "other",
      },
      {
        id: 56,
        type: "games",
        title: "christmas match 3",
        desc: "Play online game",
        name: "christmas_match_3/thumb.jpg",
        link: "christmas_match_3/index.html",
        category: "brain_training",
      },
      {
        id: 57,
        type: "games",
        title: "christmas balloons",
        desc: "Play online game",
        name: "christmas_balloons/thumb.jpg",
        link: "christmas_balloons/index.html",
        category: "other",
      },
      {
        id: 58,
        type: "games",
        title: "casino cards memory",
        desc: "Play online game",
        name: "casino_cards_memory/thumb.jpg",
        link: "casino_cards_memory/index.html",
        category: "other",
      },
      {
        id: 59,
        type: "games",
        title: "cartoon candy",
        desc: "Play online game",
        name: "cartoon_candy/thumb.jpg",
        link: "cartoon_candy/index.html",
        category: "other",
      },
      {
        id: 60,
        type: "games",
        title: "candy super lines",
        desc: "Play online game",
        name: "candy_super_lines/thumb.jpg",
        link: "candy_super_lines/index.html",
        category: "other",
      },
      {
        id: 61,
        type: "games",
        title: "candy match 3",
        desc: "Play online game",
        name: "candy_match_3/thumb.jpg",
        link: "candy_match_3/index.html",
        category: "brain_training",
      },
      {
        id: 62,
        type: "games",
        title: "btr80",
        desc: "Play online game",
        name: "btr80/thumb.jpg",
        link: "btr80/index.html",
        category: "other",
      },
      {
        id: 63,
        type: "games",
        title: "brick out",
        desc: "Play online game",
        name: "brick_out/thumb.jpg",
        link: "brick_out/index.html",
        category: "other",
      },
      {
        id: 64,
        type: "games",
        title: "brainspeed",
        desc: "Play online game",
        name: "brainspeed/thumb.jpg",
        link: "brainspeed/index.html",
        category: "brain_training",
      },
      {
        id: 65,
        type: "games",
        title: "braingames",
        desc: "Play online game",
        name: "braingames/thumb.jpg",
        link: "braingames/index.html",
        category: "brain_training",
      },
      {
        id: 66,
        type: "games",
        title: "baloon paradise",
        desc: "Play online game",
        name: "baloon_paradise/thumb.jpg",
        link: "baloon_paradise/index.html",
        category: "other",
      },
      {
        id: 67,
        type: "games",
        title: "4 images 1 word",
        desc: "Play online game",
        name: "4_images_1_word/thumb.jpg",
        link: "4_images_1_word/index.html",
        category: "brain_training",
      },
    ],
  },
};

export const content_ua: any = {
  fashion: {
    categories: [
      { cat: "makeup_trends", name: "اتجاهات المكياج" },
      { cat: "hair_trends", name: "اتجاهات الشعر" },
      { cat: "photoshoots", name: "جلسة تصوير" },
      { cat: "model_interviews", name: "مقابلات النموذج" },
      { cat: "runway", name: "المدرج" },
      { cat: "first_look", name: "النظرة الأولى" },
      { cat: "victorias_secret_2017", name: "سر فيكتوريا" },
    ],
    data: [
      {
        id: 109,
        type: "fashion",
        name: "victorias_secret_2017/101.jpg",
        link: "victorias_secret_2017/101.mp4",
        category: "victorias_secret_2017",
        title: "* NEW * Victorias Secret",
        desc: "* NEW * Backstage at Victorias Secret - الجزء 1",
      },
      {
        id: 110,
        type: "fashion",
        name: "victorias_secret_2017/102.jpg",
        link: "victorias_secret_2017/102.mp4",
        category: "victorias_secret_2017",
        title: "* NEW * Victorias Secret",
        desc: "* NEW * Backstage at Victorias Secret - الجزء 2",
      },
      {
        id: 111,
        type: "fashion",
        name: "victorias_secret_2017/103.jpg",
        link: "victorias_secret_2017/103.mp4",
        category: "victorias_secret_2017",
        title: "* NEW * Victorias Secret",
        desc: "* NEW * Backstage at Victorias Secret - الجزء 3",
      },
      {
        id: 112,
        type: "fashion",
        name: "victorias_secret_2017/104.jpg",
        link: "victorias_secret_2017/104.mp4",
        category: "victorias_secret_2017",
        title: "* NEW * Victorias Secret",
        desc: "* NEW * Backstage at Victorias Secret - الجزء 4",
      },
      {
        id: 113,
        type: "fashion",
        name: "victorias_secret_2017/105.jpg",
        link: "victorias_secret_2017/105.mp4",
        category: "victorias_secret_2017",
        title: "* NEW * Victorias Secret",
        desc: "* NEW * Backstage at Victorias Secret - الجزء 5",
      },
      {
        id: 114,
        type: "fashion",
        name: "victorias_secret_2017/106.jpg",
        link: "victorias_secret_2017/106.mp4",
        category: "victorias_secret_2017",
        title: "* NEW * Victorias Secret",
        desc: "* NEW * Backstage at Victorias Secret - الجزء 6",
      },
      {
        id: 115,
        type: "fashion",
        name: "victorias_secret_2017/107.jpg",
        link: "victorias_secret_2017/107.mp4",
        category: "victorias_secret_2017",
        title: "* NEW * Victorias Secret",
        desc: "* NEW * Backstage at Victorias Secret - الجزء 7",
      },
      {
        id: 116,
        type: "fashion",
        name: "victorias_secret_2017/108.jpg",
        link: "victorias_secret_2017/108.mp4",
        category: "victorias_secret_2017",
        title: "* NEW * Victorias Secret",
        desc: "* NEW * Backstage at Victorias Secret - الجزء 8",
      },
      {
        id: 117,
        type: "fashion",
        name: "victorias_secret_2017/109.jpg",
        link: "victorias_secret_2017/109.mp4",
        category: "victorias_secret_2017",
        title: "* NEW * Victorias Secret",
        desc: "* NEW * Backstage at Victorias Secret - الجزء 9",
      },
      {
        id: 118,
        type: "fashion",
        name: "victorias_secret_2017/110.jpg",
        link: "victorias_secret_2017/110.mp4",
        category: "victorias_secret_2017",
        title: "* NEW * Victorias Secret",
        desc: "* NEW * Backstage at Victorias Secret - الجزء 10",
      },
      {
        id: 119,
        type: "fashion",
        name: "victorias_secret_2017/111.jpg",
        link: "victorias_secret_2017/111.mp4",
        category: "makeup_trends",
        title: "* NEW * اتجاهات الماكياج - ربيع / صيف 2018",
        desc: "* NEW * اتجاهات المكياج - ربيع / صيف 2018 - الجزء الأول",
      },
      {
        id: 120,
        type: "fashion",
        name: "victorias_secret_2017/112.jpg",
        link: "victorias_secret_2017/112.mp4",
        category: "makeup_trends",
        title: "* NEW * اتجاهات الماكياج - ربيع / صيف 2018",
        desc: "* NEW * اتجاهات المكياج - ربيع / صيف 2018 - الجزء الثاني",
      },
      {
        id: 121,
        type: "fashion",
        name: "victorias_secret_2017/113.jpg",
        link: "victorias_secret_2017/113.mp4",
        category: "makeup_trends",
        title: "* NEW * اتجاهات الماكياج - ربيع / صيف 2018",
        desc: "* NEW * اتجاهات المكياج - ربيع / صيف 2018 - الجزء الثالث",
      },
      {
        id: 122,
        type: "fashion",
        name: "victorias_secret_2017/114.jpg",
        link: "victorias_secret_2017/114.mp4",
        category: "makeup_trends",
        title: "* NEW * اتجاهات الماكياج - ربيع / صيف 2018",
        desc: "* NEW * اتجاهات المكياج - ربيع / صيف 2018 - الجزء الرابع",
      },
      {
        id: 123,
        type: "fashion",
        name: "victorias_secret_2017/115.jpg",
        link: "victorias_secret_2017/115.mp4",
        category: "runway",
        title: "* NEW * Milan 2018 - Runway Show",
        desc: "* NEW * عرض مدرج في عرض أزياء ميلان 2018 - 1/4",
      },
      {
        id: 124,
        type: "fashion",
        name: "victorias_secret_2017/116.jpg",
        link: "victorias_secret_2017/116.mp4",
        category: "runway",
        title: "* NEW * Milan 2018 - Runway Show",
        desc: "* NEW * عرض Runway في عرض أزياء ميلان 2018 - 2/4",
      },
      {
        id: 125,
        type: "fashion",
        name: "victorias_secret_2017/117.jpg",
        link: "victorias_secret_2017/117.mp4",
        category: "runway",
        title: "* NEW * Milan 2018 - Runway Show",
        desc: "* NEW * عرض مدرج في عرض أزياء ميلان 2018 - 3/4",
      },
      {
        id: 126,
        type: "fashion",
        name: "victorias_secret_2017/118.jpg",
        link: "victorias_secret_2017/118.mp4",
        category: "runway",
        title: "* NEW * Milan 2018 - Runway Show",
        desc: "* NEW * عرض Runway في عرض أزياء ميلان 2018 - 4/4",
      },
      {
        id: 127,
        type: "fashion",
        name: "victorias_secret_2017/119.jpg",
        link: "victorias_secret_2017/119.mp4",
        category: "runway",
        title: "* NEW * عرض أزياء - ربيع وصيف 2018",
        desc: "* NEW * عرض أزياء اتجاهات الموضة لربيع وصيف 2018",
      },
      {
        id: 128,
        type: "fashion",
        name: "victorias_secret_2017/120.jpg",
        link: "victorias_secret_2017/120.mp4",
        category: "runway",
        title: "* NEW * عرض أزياء - ربيع وصيف 2018",
        desc: "* NEW * عرض المدرج لربيع وصيف 2018 الجزء الثاني من اتجاهات الموضة",
      },
      {
        id: 129,
        type: "fashion",
        name: "victorias_secret_2017/121.jpg",
        link: "victorias_secret_2017/121.mp4",
        category: "runway",
        title: "* NEW * عرض أزياء - ربيع وصيف 2018",
        desc: "* جديد * عرض المدرج لربيع وصيف 2018 الجزء الثالث من اتجاهات الموضة",
      },
      {
        id: 130,
        type: "fashion",
        name: "victorias_secret_2017/122.jpg",
        link: "victorias_secret_2017/122.mp4",
        category: "runway",
        title: "* NEW * عرض أزياء - ربيع وصيف 2018",
        desc: "* NEW * عرض المدرج لربيع وصيف 2018 الجزء الرابع من اتجاهات الموضة",
      },
      {
        id: 1,
        type: "fashion",
        name: "first_look/101.jpg",
        link: "first_look/101.mp4",
        category: "first_look",
        title: "فيرست لوك باريس",
        desc: "الربيع / الصيف الجزء 1.",
      },
      {
        id: 2,
        type: "fashion",
        name: "first_look/102.jpg",
        link: "first_look/102.mp4",
        category: "first_look",
        title: "فيرست لوك باريس - 2",
        desc: "الربيع / الصيف الجزء 2.",
      },
      {
        id: 3,
        type: "fashion",
        name: "first_look/103.jpg",
        link: "first_look/103.mp4",
        category: "first_look",
        title: "فيرست لوك باريس - التقرير الكامل",
        desc: "الربيع / الصيف الجزء 3.",
      },
      {
        id: 4,
        type: "fashion",
        name: "first_look/104.jpg",
        link: "first_look/104.mp4",
        category: "first_look",
        title: "مقابلة كارولين دي ميغريت",
        desc: "عرض الأزياء الحضرية العصرية الجزء 1",
      },
      {
        id: 5,
        type: "fashion",
        name: "first_look/105.jpg",
        link: "first_look/105.mp4",
        category: "first_look",
        title: "مقابلة كارولين دي ميغريت II",
        desc: "عرض الأزياء الحضرية العصرية الجزء 2",
      },
      {
        id: 6,
        type: "fashion",
        name: "first_look/106.jpg",
        link: "first_look/106.mp4",
        category: "first_look",
        title: "النظرة الأولى",
        desc: "المدرج الجزء 1",
      },
      {
        id: 7,
        type: "fashion",
        name: "first_look/107.jpg",
        link: "first_look/107.mp4",
        category: "first_look",
        title: "النظرة الأولى",
        desc: "المدرج الجزء 2",
      },
      {
        id: 8,
        type: "fashion",
        name: "first_look/108.jpg",
        link: "first_look/108.mp4",
        category: "first_look",
        title: "فيرست لوك كوتور",
        desc: "كوتور رانواي الجزء 1",
      },
      {
        id: 9,
        type: "fashion",
        name: "first_look/109.jpg",
        link: "first_look/109.mp4",
        category: "first_look",
        title: "فيرست لوك كوتور",
        desc: "كوتور رانواي الجزء 2",
      },
      {
        id: 10,
        type: "fashion",
        name: "first_look/110.jpg",
        link: "first_look/110.mp4",
        category: "first_look",
        title: "أول نظرة لربيع / صيف",
        desc: "عرض أزياء ربيع وصيف 2017",
      },
      {
        id: 11,
        type: "fashion",
        name: "first_look/111.jpg",
        link: "first_look/111.mp4",
        category: "first_look",
        title: "أول نظرة لربيع / صيف",
        desc: "عرض أزياء ربيع وصيف 2017 - الجزء الثاني",
      },
      {
        id: 12,
        type: "fashion",
        name: "first_look/112.jpg",
        link: "first_look/112.mp4",
        category: "first_look",
        title: "First Look Couture Backstage",
        desc: "مقابلة مع ديبورا هونغ",
      },
      {
        id: 13,
        type: "fashion",
        name: "first_look/113.jpg",
        link: "first_look/113.mp4",
        category: "first_look",
        title: "أول نظرة لربيع / صيف",
        desc: "مقابلة مع بلانكا لي",
      },
      {
        id: 14,
        type: "fashion",
        name: "first_look/114.jpg",
        link: "first_look/114.mp4",
        category: "first_look",
        title: "أول نظرة لربيع / صيف",
        desc: "اللحظات الأخيرة من عرض أزياء أول نظرة لربيع وصيف باريس.",
      },
      {
        id: 15,
        type: "fashion",
        name: "makeup_trends/101.jpg",
        link: "makeup_trends/101.mp4",
        category: "makeup_trends",
        title: "أول نظرة خريف / شتاء",
        desc: "مقابلة ماركو دي فينتشنزو",
      },
      {
        id: 16,
        type: "fashion",
        name: "makeup_trends/102.jpg",
        link: "makeup_trends/102.mp4",
        category: "makeup_trends",
        title: "اتجاهات الماكياج خريف / شتاء الجزء 1",
        desc: "مظهر المكياج الطبيعي",
      },
      {
        id: 17,
        type: "fashion",
        name: "makeup_trends/103.jpg",
        link: "makeup_trends/103.mp4",
        category: "makeup_trends",
        title: "اتجاهات الماكياج خريف / شتاء الجزء 2",
        desc: "أحمر شفاه داخل المظهر",
      },
      {
        id: 18,
        type: "fashion",
        name: "makeup_trends/104.jpg",
        link: "makeup_trends/104.mp4",
        category: "makeup_trends",
        title: "اتجاهات الماكياج خريف / شتاء الجزء 3",
        desc: "حواجب قوية - نظرة من الداخل!",
      },
      {
        id: 19,
        type: "fashion",
        name: "makeup_trends/105.jpg",
        link: "makeup_trends/105.mp4",
        category: "makeup_trends",
        title: "اتجاهات الماكياج خريف / شتاء الجزء 4",
        desc: "عيون قوية - نظرة من الداخل!",
      },
      {
        id: 20,
        type: "fashion",
        name: "model_interviews/101.jpg",
        link: "model_interviews/101.mp4",
        category: "model_interviews",
        title: "نموذج مقابلات باريس",
        desc: "Taylor Hill - IMG",
      },
      {
        id: 21,
        type: "fashion",
        name: "model_interviews/102.jpg",
        link: "model_interviews/102.mp4",
        category: "model_interviews",
        title: "نموذج مقابلات باريس",
        desc: "Luna Bijl - OUI",
      },
      {
        id: 22,
        type: "fashion",
        name: "model_interviews/103.jpg",
        link: "model_interviews/103.mp4",
        category: "model_interviews",
        title: "نموذج مقابلات باريس",
        desc: "مايوا نيكولاس - النخبة",
      },
      {
        id: 23,
        type: "fashion",
        name: "model_interviews/104.jpg",
        link: "model_interviews/104.mp4",
        category: "model_interviews",
        title: "نموذج مقابلات باريس",
        desc: "نشوة",
      },
      {
        id: 24,
        type: "fashion",
        name: "model_interviews/105.jpg",
        link: "model_interviews/105.mp4",
        category: "model_interviews",
        title: "نموذج مقابلات باريس",
        desc: "لويس فيرداد نيويورك",
      },
      {
        id: 25,
        type: "fashion",
        name: "photoshoots/101.jpg",
        link: "photoshoots/101.mp4",
        category: "photoshoots",
        title: "جينا كيلي",
        desc: "تصوير أزياء غرفة النوم",
      },
      {
        id: 26,
        type: "fashion",
        name: "photoshoots/102.jpg",
        link: "photoshoots/102.mp4",
        category: "photoshoots",
        title: "ماري جريس ملابس السباحة",
        desc: "تصوير أزياء بيكيني",
      },
      {
        id: 27,
        type: "fashion",
        name: "photoshoots/103.jpg",
        link: "photoshoots/103.mp4",
        category: "photoshoots",
        title: "إمبراطورة الصين",
        desc: "المكياج والأزياء الشرقية",
      },
      {
        id: 28,
        type: "fashion",
        name: "photoshoots/104.jpg",
        link: "photoshoots/104.mp4",
        category: "photoshoots",
        title: "بدون لمسة",
        desc: "تصوير أزياء بانورامي للمناظر الطبيعية الجميلة.",
      },
      {
        id: 29,
        type: "fashion",
        name: "photoshoots/105.jpg",
        link: "photoshoots/105.mp4",
        category: "photoshoots",
        title: "ديزيريه كندريكس",
        desc: "تصوير أزياء ملونة مع ديزيريه كندريكس",
      },
      {
        id: 30,
        type: "fashion",
        name: "photoshoots/106.jpg",
        link: "photoshoots/106.mp4",
        category: "photoshoots",
        title: "أميرة الفضاء يوري",
        desc: "تصوير فني ثلاثي الأبعاد بواسطة ميستوكي كينوشي",
      },
      {
        id: 31,
        type: "fashion",
        name: "photoshoots/107.jpg",
        link: "photoshoots/107.mp4",
        category: "photoshoots",
        title: "جمال زائد الحجم",
        desc: "تصوير ملابس السباحة الشاطئية للموديلات ذات الأحجام الكبيرة.",
      },
      {
        id: 32,
        type: "fashion",
        name: "photoshoots/108.jpg",
        link: "photoshoots/108.mp4",
        category: "photoshoots",
        title: "LMNO.",
        desc: "انظر وراء الكواليس في جلسة تصوير أزياء",
      },
      {
        id: 33,
        type: "fashion",
        name: "photoshoots/109.jpg",
        link: "photoshoots/109.mp4",
        category: "photoshoots",
        title: "شلال بيكيني",
        desc: "التقطت جلسة تصوير للبيكيني وقطعة واحدة من ملابس السباحة في موقع عند شلال.",
      },
      {
        id: 34,
        type: "fashion",
        name: "photoshoots/110.jpg",
        link: "photoshoots/110.mp4",
        category: "photoshoots",
        title: "أزياء نوير",
        desc: "تصوير أزياء أبيض وأسود",
      },
      {
        id: 35,
        type: "fashion",
        name: "photoshoots/111.jpg",
        link: "photoshoots/111.mp4",
        category: "photoshoots",
        title: "نيون بيوتي",
        desc: "تصوير أزياء عصرية وحضرية نيون",
      },

      {
        id: 36,
        type: "fashion",
        name: "runway/101.jpg",
        link: "runway/101.mp4",
        category: "runway",
        title: "أكوا كلارا",
        desc: "رانواي - ملابس السباحة ميامي بيتش فانكشيون أكوا كلارا",
      },
      {
        id: 37,
        type: "fashion",
        name: "runway/102.jpg",
        link: "runway/102.mp4",
        category: "runway",
        title: "لوم",
        desc: "رانواي - ملابس السباحة ميامي بيتش فانكشيون لوم",
      },
      {
        id: 38,
        type: "fashion",
        name: "runway/103.jpg",
        link: "runway/103.mp4",
        category: "runway",
        title: "شغب الشاطئ",
        desc: "Runway - Miami Beach Funkshion Beach Riot Swimwear",
      },
      {
        id: 39,
        type: "fashion",
        name: "runway/104.jpg",
        link: "runway/104.mp4",
        category: "runway",
        title: "الحياة الزرقاء",
        desc: "Runway - Miami Beach Funkshion Blue Life Swimwear",
      },
      {
        id: 40,
        type: "fashion",
        name: "runway/105.jpg",
        link: "runway/105.mp4",
        category: "runway",
        title: "كاي لاني",
        desc: "Runway - Miami Beach Funkshion Kai Lani Swimwear",
      },
      {
        id: 41,
        type: "fashion",
        name: "runway/106.jpg",
        link: "runway/106.mp4",
        category: "runway",
        title: "موسكينو",
        desc: "أزياء Moschino Bizarre Futurustic Fashion",
      },
      {
        id: 42,
        type: "fashion",
        name: "runway/107.jpg",
        link: "runway/107.mp4",
        category: "runway",
        title: "موسكينو - إي",
        desc: "أزياء موسكينو الغريبة المستقبلية الجزء 2",
      },
      {
        id: 43,
        type: "fashion",
        name: "runway/108.jpg",
        link: "runway/108.mp4",
        category: "runway",
        title: "موسكينو الثالث",
        desc: "أزياء موسكينو غريبة المستقبل الجزء 3",
      },
      {
        id: 44,
        type: "fashion",
        name: "runway/109.jpg",
        link: "runway/109.mp4",
        category: "runway",
        title: "Lingerie Mon Amour",
        desc: "أزياء الملابس الداخلية الفرنسية الجديدة",
      },
      {
        id: 45,
        type: "fashion",
        name: "runway/110.jpg",
        link: "runway/110.mp4",
        category: "runway",
        title: "Lingerie Mon Amour - II",
        desc: "أزياء الملابس الداخلية الفرنسية الحديثة الجزء 2",
      },
      {
        id: 46,
        type: "fashion",
        name: "runway/111.jpg",
        link: "runway/111.mp4",
        category: "runway",
        title: "لانجيري Mon Amour - III",
        desc: "أزياء الملابس الداخلية الفرنسية الحديثة الجزء 3",
      },
      {
        id: 47,
        type: "fashion",
        name: "runway/112.jpg",
        link: "runway/112.mp4",
        category: "runway",
        title: "Desigual New York",
        desc: "عرض أزياء Desigual NY",
      },
      {
        id: 48,
        type: "fashion",
        name: "runway/113.jpg",
        link: "runway/113.mp4",
        category: "runway",
        title: "Desigual New York - III",
        desc: "Desigual NY Fashion Runway Show Part 2",
      },
      {
        id: 49,
        type: "fashion",
        name: "runway/114.jpg",
        link: "runway/114.mp4",
        category: "runway",
        title: "لا بيرلا نيويورك",
        desc: "عرض أزياء La Perla NY",
      },
      {
        id: 50,
        type: "fashion",
        name: "first_look/115.jpeg",
        link: "first_look/115.mp4",
        category: "first_look",
        title: "فيرست لوك ميلان",
        desc: "إطلالات الخريف والشتاء من عرض أزياء ميلانو",
      },
      {
        id: 51,
        type: "fashion",
        name: "first_look/116.jpeg",
        link: "first_look/116.mp4",
        category: "first_look",
        title: "أول نظرة ميلان الجزء 2",
        desc: "إطلالات الخريف والشتاء من عرض أزياء ميلانو",
      },
      {
        id: 52,
        type: "fashion",
        name: "first_look/117.jpeg",
        link: "first_look/117.mp4",
        category: "first_look",
        title: "فيرست لوك ميلان 2017",
        desc: "إطلالات خريف / شتاء من عرض أزياء ميلانو 2017",
      },
      {
        id: 53,
        type: "fashion",
        name: "first_look/118.jpeg",
        link: "first_look/118.mp4",
        category: "first_look",
        title: "مقابلة مع المصممة شارلوت ستوكديل",
        desc: "مقابلة مع شارلوت ستوكديل من عرض أزياء ميلان",
      },
      {
        id: 54,
        type: "fashion",
        name: "first_look/119.jpeg",
        link: "first_look/119.mp4",
        category: "first_look",
        title: "أول نظرة ميلان الجزء 3",
        desc: "إطلالات الخريف والشتاء من عرض أزياء ميلانو - الجزء 3",
      },
      {
        id: 55,
        type: "fashion",
        name: "first_look/120.jpeg",
        link: "first_look/120.mp4",
        category: "first_look",
        title: "Stefano Tonchi Interwiew",
        desc: "مقابلة مع مصمم الأزياء ستيفانو توتشي من عرض أزياء ميلانو",
      },
      {
        id: 56,
        type: "fashion",
        name: "first_look/121.jpeg",
        link: "first_look/121.mp4",
        category: "first_look",
        title: "إطلالات خريف / شتاء 2017",
        desc: "أحدث تسريحات الشعر والملابس من عرض أزياء ميلانو.",
      },
      {
        id: 57,
        type: "fashion",
        name: "first_look/122.jpeg",
        link: "first_look/122.mp4",
        category: "first_look",
        title: "إطلالات خريف / شتاء 2017 - II",
        desc: "أحدث تسريحات الشعر والملابس من عرض أزياء ميلانو - الجزء الثاني",
      },
      {
        id: 58,
        type: "fashion",
        name: "first_look/123.jpeg",
        link: "first_look/123.mp4",
        category: "first_look",
        title: "إطلالات خريف / شتاء 2017 - III",
        desc: "أحدث تسريحات الشعر والملابس من عرض أزياء ميلانو - الجزء الثالث",
      },
      {
        id: 59,
        type: "fashion",
        name: "first_look/124.jpeg",
        link: "first_look/124.mp4",
        category: "first_look",
        title: "إطلالات خريف / شتاء 2017 - IV",
        desc: "أحدث تسريحات الشعر والملابس من عرض أزياء ميلانو - الجزء الرابع",
      },
      {
        id: 60,
        type: "fashion",
        name: "first_look/125.jpeg",
        link: "first_look/125.mp4",
        category: "first_look",
        title: "إطلالات خريف / شتاء 2017 - V",
        desc: "أحدث تسريحات الشعر والملابس من عرض أزياء ميلانو - الجزء الخامس",
      },
      {
        id: 61,
        type: "fashion",
        name: "first_look/126.jpeg",
        link: "first_look/126.mp4",
        category: "first_look",
        title: "إطلالات خريف / شتاء 2017 - السادس",
        desc: "أحدث تسريحات الشعر والملابس من عرض أزياء ميلانو - الجزء السادس",
      },
      {
        id: 62,
        type: "fashion",
        name: "first_look/127.jpeg",
        link: "first_look/127.mp4",
        category: "first_look",
        title: "إطلالات خريف / شتاء 2017 - الجزء السابع",
        desc: "أحدث تسريحات الشعر والملابس من عرض أزياء ميلانو - الجزء السابع",
      },
      {
        id: 63,
        type: "fashion",
        name: "first_look/128.jpeg",
        link: "first_look/128.mp4",
        category: "first_look",
        title: "إطلالات خريف / شتاء 2017 - الثامن",
        desc: "أحدث تسريحات الشعر والملابس من عرض أزياء ميلانو - الجزء الثامن",
      },
      {
        id: 64,
        type: "fashion",
        name: "first_look/129.jpeg",
        link: "first_look/129.mp4",
        category: "first_look",
        title: "إطلالات خريف / شتاء 2017 - التقرير الكامل",
        desc: "أحدث تسريحات الشعر والملابس من عرض أزياء ميلانو - مقابلة مع جويدو باولو (مصفف شعر)",
      },
      {
        id: 65,
        type: "fashion",
        name: "first_look/130.jpeg",
        link: "first_look/130.mp4",
        category: "first_look",
        title: "إطلالات خريف / شتاء 2017 - التاسع",
        desc: "أحدث تسريحات الشعر والملابس من عرض أزياء ميلانو - الجزء 9",
      },
      {
        id: 66,
        type: "fashion",
        name: "first_look/131.jpeg",
        link: "first_look/131.mp4",
        category: "first_look",
        title: "إطلالات خريف / شتاء 2017 - X",
        desc: "أحدث تسريحات الشعر والملابس من عرض أزياء ميلانو - الجزء 10",
      },
      {
        id: 67,
        type: "fashion",
        name: "first_look/132.jpeg",
        link: "first_look/132.mp4",
        category: "first_look",
        title: "إطلالات خريف / شتاء 2017 - الحادي عشر",
        desc: "أحدث تسريحات الشعر والملابس من عرض أزياء ميلانو - الجزء 11",
      },
      {
        id: 68,
        type: "fashion",
        name: "hair_trends/101.jpeg",
        link: "hair_trends/101.mp4",
        category: "hair_trends",
        title: "اتجاهات الشعر - 2017",
        desc: "أحدث تسريحات الشعر لربيع / صيف 2017",
      },
      {
        id: 69,
        type: "fashion",
        name: "hair_trends/102.jpeg",
        link: "hair_trends/102.mp4",
        category: "hair_trends",
        title: "اتجاهات الشعر - 2017",
        desc: "أحدث تسريحات الشعر لربيع وصيف 2017 بالإضافة إلى مقابلة إضافية مع دين ودان كاتين!",
      },
      {
        id: 70,
        type: "fashion",
        name: "hair_trends/103.jpeg",
        link: "hair_trends/103.mp4",
        category: "hair_trends",
        title: "أحدث صيحات تصفيفة الشعر",
        desc: "مقابلة وراء الكواليس مع توم مارسيرو وآخر تسريحات الشعر لربيع وصيف 2017",
      },
      {
        id: 71,
        type: "fashion",
        name: "hair_trends/104.jpeg",
        link: "hair_trends/104.mp4",
        category: "hair_trends",
        title: "أحدث قصات الشعر",
        desc: "تسريحات الشعر المبلل لربيع وصيف 2017",
      },
      {
        id: 72,
        type: "fashion",
        name: "makeup_trends/107.jpeg",
        link: "makeup_trends/107.mp4",
        category: "makeup_trends",
        title: "اتجاهات المكياج - 2017",
        desc: "مقابلة مع جولي هومانز من NEXT",
      },
      {
        id: 73,
        type: "fashion",
        name: "makeup_trends/108.jpeg",
        link: "makeup_trends/108.mp4",
        category: "makeup_trends",
        title: "مقابلة: توم بيشو",
        desc: "مقابلة مع توم بيشو ، خبير التجميل",
      },
      {
        id: 74,
        type: "fashion",
        name: "makeup_trends/109.jpeg",
        link: "makeup_trends/109.mp4",
        category: "makeup_trends",
        title: "اتجاهات المكياج - الشفاه الوردية الشاحبة",
        desc: "جميع العارضات على المدرج يرتدين أحمر شفاه وردي باهت",
      },
      {
        id: 75,
        type: "fashion",
        name: "makeup_trends/110.jpeg",
        link: "makeup_trends/110.mp4",
        category: "makeup_trends",
        title: "اتجاهات المكياج - أحمر الشفاه",
        desc: "جميع العارضات على المدرج يرتدين أحمر شفاه أحمر",
      },
      {
        id: 76,
        type: "fashion",
        name: "photoshoots/112.jpeg",
        link: "photoshoots/112.mp4",
        category: "photoshoots",
        title: "الجمال عبر العصور",
        desc: "كل التأثيرات والثقافات الماضية التي صنعت الجمال الحديث.",
      },
      {
        id: 77,
        type: "fashion",
        name: "photoshoots/113.jpeg",
        link: "photoshoots/113.mp4",
        category: "photoshoots",
        title: "التقطت الصور بالأبيض والأسود مع نموذج ملابس السباحة",
        desc: "جمال امرأة سمراء في ملابس البحر",
      },
      {
        id: 78,
        type: "fashion",
        name: "photoshoots/114.jpeg",
        link: "photoshoots/114.mp4",
        category: "photoshoots",
        title: "مقابلة في مدينة نيويورك مع عارضة أزياء.",
        desc: "عارضة أزياء مذهلة تناقش وظيفتها اليومية في جلسة تصوير فوق سطح أحد المنازل في نيويورك",
      },
      {
        id: 79,
        type: "fashion",
        name: "photoshoots/115.jpeg",
        link: "photoshoots/115.mp4",
        category: "photoshoots",
        title: "ماري جريس ملابس السباحة",
        desc: "نموذج مذهل يستعرض على الشاطئ بملابس سباحة ماري جريس الجديدة",
      },
      {
        id: 80,
        type: "fashion",
        name: "photoshoots/116.jpeg",
        link: "photoshoots/116.mp4",
        category: "photoshoots",
        title: "ماري جريس ملابس السباحة الجزء 2",
        desc: "العارضة المذهلة تستعرض على الشاطئ بملابس ماري جريس الجديدة - الجزء 2",
      },
      {
        id: 81,
        type: "fashion",
        name: "photoshoots/117.jpeg",
        link: "photoshoots/117.mp4",
        category: "photoshoots",
        title: "ماري جريس ملابس السباحة الجزء 3",
        desc: "العارضة المذهلة تستعرض على الشاطئ بملابس ماري جريس الجديدة - الجزء 3",
      },
      {
        id: 82,
        type: "fashion",
        name: "photoshoots/118.jpeg",
        link: "photoshoots/118.mp4",
        category: "photoshoots",
        title: "ماري جريس ملابس السباحة الجزء 4",
        desc: "العارضة المذهلة تستعرض على الشاطئ بملابس ماري جريس الجديدة - الجزء 4",
      },
      {
        id: 83,
        type: "fashion",
        name: "photoshoots/119.jpeg",
        link: "photoshoots/119.mp4",
        category: "photoshoots",
        title: "عامله مثل زجاجه",
        desc: "جلسة تصوير أزياء على شاطئ البحر",
      },
      {
        id: 84,
        type: "fashion",
        name: "photoshoots/120.jpeg",
        link: "photoshoots/120.mp4",
        category: "photoshoots",
        title: "تصوير حضري بجانب السرير",
        desc: "تصوير شقة المدينة",
      },
      {
        id: 85,
        type: "fashion",
        name: "photoshoots/121.jpeg",
        link: "photoshoots/121.mp4",
        category: "photoshoots",
        title: "أزياء غير رسمية على جانب الطريق",
        desc: "اتجاهات الموضة العصرية غير الرسمية",
      },
      {
        id: 86,
        type: "fashion",
        name: "photoshoots/122.jpeg",
        link: "photoshoots/122.mp4",
        category: "photoshoots",
        title: " عالمي ",
        desc: "التقطت الصور في سيارة قوية",
      },
      {
        id: 87,
        type: "fashion",
        name: "photoshoots/123.jpeg",
        link: "photoshoots/123.mp4",
        category: "photoshoots",
        title: "Jonas B Photoshoot",
        desc: "عام في استعراض مع مجموعة من عارضات الأزياء",
      },
      {
        id: 88,
        type: "fashion",
        name: "photoshoots/124.jpeg",
        link: "photoshoots/124.mp4",
        category: "photoshoots",
        title: "التقاط الصور للبحيرة بالأبيض والأسود",
        desc: "جلسة تصوير عصرية بالأبيض والأسود بجانب بحيرة",
      },
      {
        id: 89,
        type: "fashion",
        name: "photoshoots/125.jpeg",
        link: "photoshoots/125.mp4",
        category: "photoshoots",
        title: "KS Photoshoot",
        desc: "جلسة تصوير نيويورك مع كانساس",
      },
      {
        id: 90,
        type: "fashion",
        name: "photoshoots/126.jpeg",
        link: "photoshoots/126.mp4",
        category: "photoshoots",
        title: "جلسة تصوير للملابس الداخلية باللون الأسود و",
        desc: "جلسة تصوير الملابس الداخلية الحديثة",
      },
      {
        id: 91,
        type: "fashion",
        name: "runway/115.jpeg",
        link: "runway/115.mp4",
        category: "runway",
        title: "عرض أزياء الملابس الداخلية - باريس",
        desc: "Salon de la lingerie ، باريس 2017",
      },
      {
        id: 92,
        type: "fashion",
        name: "runway/116.jpeg",
        link: "runway/116.mp4",
        category: "runway",
        title: "فيكس بولا هيرماني",
        desc: "Vix Paula Hermanny at the Miami Beach Funkshion",
      },
      {
        id: 93,
        type: "fashion",
        name: "runway/117.jpeg",
        link: "runway/117.mp4",
        category: "runway",
        title: "آنا كوستوروفا",
        desc: "آنا كوستوروفا في فندق Miami Beach Funkshion",
      },
      {
        id: 94,
        type: "fashion",
        name: "runway/118.jpeg",
        link: "runway/118.mp4",
        category: "runway",
        title: "لولي فاما",
        desc: "Luli Fama at the Miami Beach Funkshion",
      },
      {
        id: 95,
        type: "fashion",
        name: "runway/119.jpeg",
        link: "runway/119.mp4",
        category: "runway",
        title: "سينيسيا كارول",
        desc: "Sinesia Karol at the Miami Beach Funkshion",
      },
      {
        id: 96,
        type: "fashion",
        name: "runway/120.jpeg",
        link: "runway/120.mp4",
        category: "runway",
        title: "توري برافير",
        desc: "Tori Praver at the Miami Beach Funkshion",
      },
      {
        id: 97,
        type: "fashion",
        name: "runway/121.jpeg",
        link: "runway/121.mp4",
        category: "runway",
        title: "ماجي",
        desc: "Maaji at the Miami Beach Funkshion",
      },
      {
        id: 98,
        type: "fashion",
        name: "runway/122.jpeg",
        link: "runway/122.mp4",
        category: "runway",
        title: "ليلا نيكول",
        desc: "Lila Nikole at the Miami Beach Funkshion",
      },
      {
        id: 99,
        type: "fashion",
        name: "runway/123.jpeg",
        link: "runway/123.mp4",
        category: "runway",
        title: " سر فيكتوريا ",
        desc: "فيكتورياس سيكريت آت ذا بينك كاربت ، باريس",
      },
      {
        id: 100,
        type: "fashion",
        name: "runway/124.jpeg",
        link: "runway/124.mp4",
        category: "runway",
        title: "فيكتورياس سيكريت الجزء 2",
        desc: "فيكتورياس سيكريت آت ذا بينك كاربت ، باريس - الجزء 2",
      },
      {
        id: 101,
        type: "fashion",
        name: "runway/125.jpeg",
        link: "runway/125.mp4",
        category: "runway",
        title: "فيكتورياس سيكريت الجزء 3",
        desc: "فيكتورياس سيكريت آت ذا بينك كاربت ، باريس - الجزء 3",
      },
      {
        id: 102,
        type: "fashion",
        name: "runway/126.jpeg",
        link: "runway/126.mp4",
        category: "runway",
        title: "فيكتورياس سيكريت الجزء 4",
        desc: "فيكتورياس سيكريت آت ذا بينك كاربت ، باريس - الجزء 4",
      },
      {
        id: 103,
        type: "fashion",
        name: "runway/127.jpeg",
        link: "runway/127.mp4",
        category: "runway",
        title: "صالون دي لا لانجري",
        desc: "صالون دي لا لانجري ، باريس 2017 - الجزء 1",
      },
      {
        id: 104,
        type: "fashion",
        name: "runway/128.jpeg",
        link: "runway/128.mp4",
        category: "runway",
        title: "صالون دي لا لانجري",
        desc: "صالون دي لا لانجري ، باريس 2017 - الجزء 2",
      },
      {
        id: 105,
        type: "fashion",
        name: "runway/129.jpeg",
        link: "runway/129.mp4",
        category: "runway",
        title: "للحب والليمون",
        desc: "For Love & Lemons at the Miami Beach Funkshion",
      },
      {
        id: 106,
        type: "fashion",
        name: "runway/130.jpeg",
        link: "runway/130.mp4",
        category: "runway",
        title: "صالون دي لا لانجري",
        desc: "صالون دي لا لانجري في باريس 2017",
      },
      {
        id: 107,
        type: "fashion",
        name: "runway/131.jpeg",
        link: "runway/131.mp4",
        category: "runway",
        title: "صالون دي لا لانجري",
        desc: "صالون دي لا لانجري في باريس 2017 - الجزء 3",
      },
      {
        id: 108,
        type: "fashion",
        name: "runway/132.jpeg",
        link: "runway/132.mp4",
        category: "runway",
        title: "صالون دي لا لانجري",
        desc: "صالون دي لا لانجري في باريس 2017 - الجزء 4",
      },
    ],
  },
  fitness: {
    categories: [{ cat: "fitness", name: "سلسلة اللياقة البدنية" }],
    data: [
      {
        id: 1,
        type: "fitness",
        name: "Videos/101_m.jpg",
        link: "Videos/101.mp4",
        category: "fitness",
        title: "احصل على مكاسب أسرع!",
        desc: "3 نصائح وحيل سريعة للحصول على مكاسب أكبر للعضلات.",
      },
      {
        id: 2,
        type: "fitness",
        name: "Videos/102_m.jpg",
        link: "Videos/102.mp4",
        category: "fitness",
        title: "أكبر أخطاء دمبل",
        desc: "هذه قائمة بأكبر الأخطاء التي ارتكبها المبتدئين",
      },
      {
        id: 3,
        type: "fitness",
        name: "Videos/103_m.jpg",
        link: "Videos/103.mp4",
        category: "fitness",
        title: "احصل على عضلات بطن رائعة في 10 أيام",
        desc: "تمرين سهل للحصول على عضلات بطن رائعة",
      },
      {
        id: 4,
        type: "fitness",
        name: "Videos/104_m.jpg",
        link: "Videos/104.mp4",
        category: "fitness",
        title: "أخطاء المبتدئين يجب تجنبها!",
        desc: "طرق يجب تجنبها لتحقيق أقصى استفادة من التمرين",
      },
      {
        id: 5,
        type: "fitness",
        name: "Videos/105_m.jpg",
        link: "Videos/105.mp4",
        category: "fitness",
        title: "أفضل تمرين ثلاثي الرؤوس",
        desc: "احصل على عضلات ثلاثية الرؤوس أكبر وأكثر تحديدًا اليوم!",
      },
      {
        id: 6,
        type: "fitness",
        name: "Videos/106_m.jpg",
        link: "Videos/106.mp4",
        category: "fitness",
        title: "تفقد تلك الرجل الثدي",
        desc: "أفضل تمارين لبناء عضلات الصدر",
      },
      {
        id: 7,
        type: "fitness",
        name: "Videos/107_m.jpg",
        link: "Videos/107.mp4",
        category: "fitness",
        title: "أكبر أخطاء تمرين الضغط",
        desc: "حقق أقصى استفادة من تمارين الضغط باستخدام هذه التقنية",
      },
      {
        id: 8,
        type: "fitness",
        name: "Videos/108_m.jpg",
        link: "Videos/108.mp4",
        category: "fitness",
        title: "نحت صدرك الآن",
        desc: "احصل على بيكس الذي طالما حلمت به الآن",
      },
      {
        id: 9,
        type: "fitness",
        name: "Videos/109_m.jpg",
        link: "Videos/109.mp4",
        category: "fitness",
        title: "تدريب القلب الشديد",
        desc: "احرق أكثر من 650 سعرة حرارية بسهولة!",
      },
      {
        id: 10,
        type: "fitness",
        name: "Videos/110_m.jpg",
        link: "Videos/110.mp4",
        category: "fitness",
        title: "أفضل 5 تمارين القرفصاء",
        desc: "تعلم كيفية القرفصاء بشكل صحيح والحصول على عضلات الألوية المحددة",
      },
      {
        id: 11,
        type: "fitness",
        name: "Videos/111_m.jpg",
        link: "Videos/111.mp4",
        category: "fitness",
        title: "تمرين الزومبا",
        desc: "ارقص بنفسك مع هذا التمرين الممتع الذي يمكنك القيام به في المنزل",
      },
      {
        id: 12,
        type: "fitness",
        name: "Videos/112_m.jpg",
        link: "Videos/112.mp4",
        category: "fitness",
        title: "روتين أب مطلق",
        desc: "احصل على القيمة المطلقة التي تحلم بها الآن مع هذه المجموعات من تمارين تفجير البطن الصعبة.",
      },
      {
        id: 13,
        type: "fitness",
        name: "Videos/113_m.jpg",
        link: "Videos/113.mp4",
        category: "fitness",
        title: "أفضل تمارين Kettlebell",
        desc: "تحقق من هذا التمرين الروتيني kettlebell الآن!",
      },
      {
        id: 14,
        type: "fitness",
        name: "Videos/114_m.jpg",
        link: "Videos/114.mp4",
        category: "fitness",
        title: "16 تمارين البدلاء للمرأة",
        desc: "16 تمرينًا أساسيًا رائعًا لعضلات بطنك يمكنك القيام بها في أي وقت على مقعد العمل",
      },
      {
        id: 15,
        type: "fitness",
        name: "Videos/115_m.jpg",
        link: "Videos/115.mp4",
        category: "fitness",
        title: "المرأة الصدر تجريب!",
        desc: "ينمو صدرك مع تمارين عضلات الصدر هذه",
      },
      {
        id: 16,
        type: "fitness",
        name: "Videos/116_m.jpg",
        link: "Videos/116.mp4",
        category: "fitness",
        title: "XTREME Pecs تجريب !!",
        desc: "احصل على مظهر محفور في وقت قصير جدًا باستخدام هذه الحيل البسيطة لأداء تمارين الصدر.",
      },
      {
        id: 17,
        type: "fitness",
        name: "Videos/117.jpg",
        link: "Videos/117.mp4",
        category: "fitness",
        title: "تمرين العجل السريع",
        desc: "احصل على عجول محددة في 5 دقائق فقط!",
      },
      {
        id: 18,
        type: "fitness",
        name: "Videos/118.jpg",
        link: "Videos/118.mp4",
        category: "fitness",
        title: "7 تمارين لشد البطن",
        desc: "7 تمارين بنصائح وحيل رائعة لإشراك قلبك والحصول على بطن مشدود",
      },
      {
        id: 19,
        type: "fitness",
        name: "Videos/119.jpg",
        link: "Videos/119.mp4",
        category: "fitness",
        title: "8 Minute Morning Workout",
        desc: "أطلق السعرات الحرارية مع هذا التمرين المكثف لمدة 8 دقائق",
      },
      {
        id: 20,
        type: "fitness",
        name: "Videos/120.jpg",
        link: "Videos/120.mp4",
        category: "fitness",
        title: "حرق الدهون في الجسم بالكامل!",
        desc: "تمرين رائع يمكنك القيام به في المنزل لرفع معدل ضربات قلبك",
      },
      {
        id: 21,
        type: "fitness",
        name: "Videos/121.jpg",
        link: "Videos/121.mp4",
        category: "fitness",
        title: "الحصول على أفخاذ أرق",
        desc: "شد فخذيك بهذه التمارين السهلة!",
      },
      {
        id: 22,
        type: "fitness",
        name: "Videos/122.jpg",
        link: "Videos/122.mp4",
        category: "fitness",
        title: "Ultimate Quad Workout",
        desc: "استهدف وابني كل عضلاتك الرباعية الأربعة",
      },
      {
        id: 23,
        type: "fitness",
        name: "Videos/123.jpg",
        link: "Videos/123.mp4",
        category: "fitness",
        title: "5 تمارين الكتف",
        desc: "احصل على أكتاف قوية ومبنية جيدًا مع تمارين سوبرمان هذه",
      },
      {
        id: 24,
        type: "fitness",
        name: "Videos/124.jpg",
        link: "Videos/124.mp4",
        category: "fitness",
        title: "أفضل الساعدين في العالم؟",
        desc: "هل هذه هي أفضل الساعدين في العالم؟ شاهد واكتشف!",
      },
      {
        id: 25,
        type: "fitness",
        name: "Videos/125.jpg",
        link: "Videos/125.mp4",
        category: "fitness",
        title: "أفضل 5 تمارين ثلاثية الرؤوس",
        desc: "لا تكتمل الذراعين بدون العضلة ثلاثية الرؤوس القوية. تعرف على كيفية الحصول عليها في هذا الفيديو!",
      },
      {
        id: 26,
        type: "fitness",
        name: "Videos/126.jpg",
        link: "Videos/126.mp4",
        category: "fitness",
        title: "Bicep Peaks - كيفية بناء الكتلة!",
        desc: "بناء قمم شاهقة في هذا التمرين المكثف للعضلة ذات الرأسين",
      },
      {
        id: 27,
        type: "fitness",
        name: "Videos/127.jpg",
        link: "Videos/127.mp4",
        category: "fitness",
        title: "تمرين وضعية أفضل",
        desc: "المشي باستقامة مع هذا روتين التمرين الجيد لتحسين وضعك.",
      },
      {
        id: 28,
        type: "fitness",
        name: "Videos/128.jpg",
        link: "Videos/128.mp4",
        category: "fitness",
        title: "مكاسب أفضل لعضلات لات!",
        desc: "احصل على عضلات أقوى وأكثر تحديدًا من خلال إشراك هذه العضلات بدلاً من ذلك",
      },
      {
        id: 29,
        type: "fitness",
        name: "Videos/129.jpg",
        link: "Videos/129.mp4",
        category: "fitness",
        title: "خدعة واحدة غريبة للقيمة المطلقة!",
        desc: "الأطباء يكرهونه! اكتشف كيف اكتشفت هذه الأم المكونة من 6 أفراد هذه الحيلة الغريبة لتحسين عضلات البطن",
      },
      {
        id: 30,
        type: "fitness",
        name: "Videos/130.jpg",
        link: "Videos/130.mp4",
        category: "fitness",
        title: "30 يومًا من الدهون لتناسب الجسم",
        desc: "احرق طنًا من الدهون مع هذا الروتين التدريبي المكثف لضخ العرق!",
      },
    ],
  },
  recipes: {
    categories: [{ cat: "food", name: "سلسلة الغذاء" }],
    data: [
      {
        id: 1,
        type: "recipes",
        name: "Video/food/101_m.JPG",
        link: "Video/food/101.mp4",
        category: "food",
        title: "سلطة دجاج بالعنب",
        ingredients: [
          "Walnuts,",
          "Pine Nuts,",
          "Chicken Fillet,",
          "Red Grapes,",
          "Ground Black Pepper,",
          "Olive Oil,",
          "Mustard,",
          "Capers,",
          "Oregano,",
          "Sea Salt.",
        ],
        desc: "وصفة طعام لذيذة",
      },
      {
        id: 2,
        type: "recipes",
        name: "Video/food/102_m.JPG",
        link: "Video/food/102.mp4",
        category: "food",
        title: "أومليت مع هام سوفليه",
        ingredients: [
          "Cream 20%",
          "Eggs",
          "Butter",
          "Ham",
          "Sour Cream",
          "Cheese",
          "Paprika",
          "Paray Onion",
          "Sea Salt",
          "Ground Black Pepper",
        ],
        desc: "وصفة طعام لذيذة",
      },
      {
        id: 3,
        type: "recipes",
        name: "Video/food/103_m.JPG",
        link: "Video/food/103.mp4",
        category: "food",
        title: "راتاتوي",
        ingredients: [
          "Zucchini",
          "Bay Leaves",
          "Garlic",
          "Butter",
          "Aubergine",
          "Parmesan",
          "Onions",
          "Tomatoes",
          "Bulgarian Pepper",
          "Ground Sea Salt",
          "Ground Black pepper",
          "Olive Oil",
        ],
        desc: "وصفة طعام لذيذة",
      },
      {
        id: 4,
        type: "recipes",
        name: "Video/food/104_m.JPG",
        link: "Video/food/104.mp4",
        category: "food",
        title: "سلطة نيسواز مع جرجير",
        ingredients: [
          "Red Peppers",
          "Olives",
          "Olive Oil",
          "Red onion",
          "Tomatoes",
          "Green Beans",
          "Capers",
          "Lemons",
          "Anchovy fillets",
          "Radish",
          "Balsamic sauce",
        ],
        desc: "وصفة طعام لذيذة",
      },
      {
        id: 5,
        type: "recipes",
        name: "Video/food/105_m.JPG",
        link: "Video/food/105.mp4",
        category: "food",
        title: "بيض مشوي مع الجمبري",
        ingredients: [
          "Eggs",
          "Shrimp",
          "Cream 30%",
          "Sour cream",
          "Olive Oil",
          "Butter",
          "Ground black pepper",
          "Sea Salt",
        ],
        desc: "وصفة طعام لذيذة",
      },
      {
        id: 6,
        type: "recipes",
        name: "Video/food/106_m.JPG",
        link: "Video/food/106.mp4",
        category: "food",
        title: "فيش بروفنسال",
        ingredients: [
          "Green Beans",
          "Red Onions",
          "Dijon Mustard",
          "Lemons",
          "Parsley",
          "Ground Black Pepper",
          "Olive Oil",
          "White Wine",
          "Tomatoes",
          "White Fish Fillets",
        ],
        desc: "وصفة طعام لذيذة",
      },
      {
        id: 7,
        type: "recipes",
        name: "Video/food/107_m.JPG",
        link: "Video/food/107.mp4",
        category: "food",
        title: "الفطر من اليونان",
        ingredients: [
          "Mushrooms",
          "Parsley",
          "Dill",
          "Celery",
          "Lemons",
          "Fennel",
          "Thyme",
          "Coriander",
          "Sea Salt",
        ],
        desc: "وصفة طعام لذيذة",
      },
      {
        id: 8,
        type: "recipes",
        name: "Video/food/108_m.JPG",
        link: "Video/food/108.mp4",
        category: "food",
        title: "بيكو دي غالو",
        ingredients: [
          "Jalapeno Pepper",
          "Olive Oil",
          "Cilantro",
          "Tomatoes",
          "Red Onions",
          "Corn",
          "Avocado",
          "Lemons",
        ],
        desc: "وصفة طعام لذيذة",
      },
      {
        id: 9,
        type: "recipes",
        name: "Video/food/109_m.JPG",
        link: "Video/food/109.mp4",
        category: "food",
        title: "بيستو",
        ingredients: [
          "Black basil",
          "Green basil",
          "Garlic",
          "Parmesan",
          "Oliven oil",
        ],
        desc: "وصفة طعام لذيذة",
      },
      {
        id: 10,
        type: "recipes",
        name: "Video/food/110_m.JPG",
        link: "Video/food/110.mp4",
        category: "food",
        title: "موس شوكولاتة البرتقال المر",
        ingredients: [
          "Chocolate",
          "Butter",
          "Eggs",
          "Cream 40%",
          "Orange Liqueur",
          "Orange Zest",
        ],
        desc: "وصفة طعام لذيذة",
      },
      {
        id: 11,
        type: "recipes",
        name: "Video/food/111_m.JPG",
        link: "Video/food/111.mp4",
        category: "food",
        title: "أومليت بالجبنة غرويير",
        ingredients: [
          "Eggs",
          "Gruyere Cheese",
          "Baguette",
          "Garlic",
          "Parsley",
          "Olive Oil",
        ],
        desc: "وصفة طعام لذيذة",
      },
      {
        id: 12,
        type: "recipes",
        name: "Video/food/112_m.JPG",
        link: "Video/food/112.mp4",
        category: "food",
        title: "كيشي مع السبانخ",
        ingredients: [
          "Spinach",
          "Eggs",
          "Zucchini",
          "Puff Pastry",
          "Ground Black Pepper",
          "Cream 30%",
          "Butter",
          "Sea Salt",
          "Lemons",
        ],
        desc: "وصفة طعام لذيذة",
      },
      {
        id: 13,
        type: "recipes",
        name: "Video/food/113_m.JPG",
        link: "Video/food/113.mp4",
        category: "food",
        title: "سلطة بالموز",
        ingredients: [
          "Apples 200g",
          "Bananas 200g",
          "Celery 200g",
          "Yoghurt 50g",
          "Sour Cream 50g",
          "Mint Leaves",
        ],
        desc: "وصفة طعام لذيذة",
      },
      {
        id: 14,
        type: "recipes",
        name: "Video/food/114_m.JPG",
        link: "Video/food/114.mp4",
        category: "food",
        title: "ساندويتش مع الكمثرى والجبن البري",
        ingredients: ["Bun", "Brie Cheese", "Pear", "Parma Ham", "Olive Oil"],
        desc: "وصفة طعام لذيذة",
      },
      {
        id: 15,
        type: "recipes",
        name: "Video/food/115_m.JPG",
        link: "Video/food/115.mp4",
        category: "food",
        title: "معكرونة كاربونارا",
        ingredients: [
          "Eggs",
          "Cream 20%",
          "Bacon",
          "Spaghetti",
          "White Onionl",
          "Ground Black Pepper",
          "Sea Salt",
          "Parmesan",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 16,
        type: "recipes",
        name: "Video/food/116_m.jpg",
        link: "Video/food/116_m.mp4",
        category: "food",
        title: "كعكة الجبن محلية الصنع",
        ingredients: [
          "Sugar 25g",
          "Semolina 50g",
          "Cottage Cheese 200g",
          "Flour 200g",
          "Jam",
          "Joghurt",
          "Mint 10g",
          "Dessicated Coconut 10g",
          "2 Eggs",
          "Olive Oil",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 17,
        type: "recipes",
        name: "Video/food/117_m.jpg",
        link: "Video/food/117_m.mp4",
        category: "food",
        title: "فطيرة الشوفان مع الكاكاو",
        ingredients: [
          "Nuts 100g",
          "Sugar 25g",
          "Oatmeal 250g",
          "Eggs 3",
          "Cinammon",
          "Cocoa 50g",
          "Vanillin 20g",
          "Milk",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 18,
        type: "recipes",
        name: "Video/food/118_m.jpg",
        link: "Video/food/118_m.mp4",
        category: "food",
        title: "توست اسباني وعصير برتقال",
        ingredients: [
          "Buns",
          "7 Quail Eggs",
          "Olive Oil",
          "Lettuce",
          "Fresh chilli peppers",
          "Ham",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 19,
        type: "recipes",
        name: "Video/food/119_m.jpg",
        link: "Video/food/119_m.mp4",
        category: "food",
        title: "الفطائر مع التفاح",
        ingredients: [
          "Cinammon",
          "Coconuts",
          "2 Apples",
          "Flour 200g",
          "2 Eggs",
          "Sugar 50g",
          "Olive Oil",
          "Baking Soda",
          "Milk 200ml",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 20,
        type: "recipes",
        name: "Video/food/120_m.jpg",
        link: "Video/food/120_m.mp4",
        category: "food",
        title: "بيض مخفوق بالثوم",
        ingredients: [
          "Hard cheese",
          "2 cucumbers",
          "Lemons",
          "Parsley",
          "Sugar 25g",
          "Garlic",
          "Butter",
          "Olive Oil",
          "Buns",
          "3 Eggs",
          "Soda Water",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 21,
        type: "recipes",
        name: "Video/food/121_m.jpg",
        link: "Video/food/121_m.mp4",
        category: "food",
        title: "الشوفان مع التفاح والشاي الأسود لاتيه",
        ingredients: [
          "Cinammon",
          "Oatmeal",
          "2 Apples",
          "Butter",
          "Sugar",
          "Yoghurt",
          "Honey",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 22,
        type: "recipes",
        name: "Video/food/122_m.jpg",
        link: "Video/food/122_m.mp4",
        category: "food",
        title: "ساندويتش دنماركي مع عصير توت الرنجة",
        ingredients: [
          "Lemons",
          "Onion",
          "Red Onion",
          "2 Potatoes",
          "Green Onions",
          "Buns",
          "Arugula",
          "Salted Salmon",
          "Herring Fillet",
          "Mustard",
          "Olive Oil",
          "Honey",
          "Hot Pepper",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 23,
        type: "recipes",
        name: "Video/food/123_m.jpg",
        link: "Video/food/123_m.mp4",
        category: "food",
        title: "توست مع البازلاء الخضراء وعصير التفاح",
        ingredients: [
          "Green peas 150g",
          "2 Tomatoes",
          "Lemons",
          "Butter 20g",
          "Toast (3 slices)",
          "2 Eggs",
          "Garlic",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 24,
        type: "recipes",
        name: "Video/food/124_m.jpg",
        link: "Video/food/124.mp4",
        category: "food",
        title: "سلطة الصيف الايطالية",
        ingredients: [
          "Lettuce",
          "2 Oranges",
          "Lemons",
          "Basil 20g",
          "2 Tomatoes",
          "Salad 50g",
          "Parma ham 20g",
          "Mozzarella",
          "Ciabatta",
          "Olive Oil",
          "Balsamic",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 25,
        type: "recipes",
        name: "Video/food/125_m.jpg",
        link: "Video/food/125.mp4",
        category: "food",
        title: "سلطة مع الفاصوليا البيضاء",
        ingredients: [
          "Celery 200g",
          "Carrots 100g",
          "1 Onion",
          "White beans 100g",
          "4 Slices of Ham",
          "Garlic",
          "Grapefruit",
          "Red Onion",
          "Wine Vinegar",
          "Black Pepper",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 26,
        type: "recipes",
        name: "Video/food/126_m.jpg",
        link: "Video/food/126.mp4",
        category: "food",
        title: "بروشيتا مع طماطم",
        ingredients: [
          "Ham 25g",
          "Cane Sugar 15g",
          "1 Tomato",
          "1 Lemons",
          "Mint",
          "Basil",
          "Quail Eggs",
          "1 Lime",
          "Balsamic",
          "Garlic",
          "Baguette",
          "Olive Oil",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 27,
        type: "recipes",
        name: "Video/food/127_m.jpg",
        link: "Video/food/127.mp4",
        category: "food",
        title: "شكشوكس مع عصير البرتقال والليمون",
        ingredients: [
          "Tumeric 5gr",
          "1 Tomato",
          "Garlic",
          "Onion",
          "Parsley 25g",
          "1 Chilli",
          "Olive Oil",
          "2 Eggs",
          "Cumin",
          "2 Paprika",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 28,
        type: "recipes",
        name: "Video/food/128_m.jpg",
        link: "Video/food/128.mp4",
        category: "food",
        title: "بان كيك الموز وعصير التفاح الجاف",
        ingredients: [
          "2 Bananas",
          "Sugar 25g",
          "Dry Apple Juice 100g",
          "Bran Flour 150g",
          "Nuts 25g",
          "Walnuts 25g",
          "Honey",
          "Milk 100ml",
          "Olive Oil 15ml",
          "2 Eggs",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 29,
        type: "recipes",
        name: "Video/food/129_m.jpg",
        link: "Video/food/129.mp4",
        category: "food",
        title: "موسلي كوكيز وشاي ماسالا",
        ingredients: [
          "Milk 200ml",
          "Müsli 300g",
          "2 Eggs",
          "Jam 50g",
          "Ginger",
          "Sugar 20g",
          "Cardamom",
          "Instant Coffee",
          "Nutmeg",
          "Butter 15g",
          "Cloves",
          "Cinammon",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 30,
        type: "recipes",
        name: "Video/food/130_m.jpg",
        link: "Video/food/130.mp4",
        category: "food",
        title: "برجر محلي الصنع",
        ingredients: [
          "Chicken breast 200g",
          "Buns",
          "Lettuce 100g",
          "Mushrooms 200g",
          "Butter 100g",
          "2 Tomatoes",
          "Sugar 50g",
          "Wine Vinegar",
        ],
        desc: "وصفة طعام لذيذة",
      },
      {
        id: 31,
        type: "recipes",
        name: "Video/food/139_m.jpg",
        link: "Video/food/139.mp4",
        category: "food",
        title: "بروشيتا و / بيستو",
        ingredients: [
          "red pepper",
          "yellow pepper",
          "baguette",
          "pine nuts",
          "garlic",
          "olive oil",
        ],
        desc: "وصفة طعام لذيذة",
      },
      {
        id: 32,
        type: "recipes",
        name: "Video/food/140_m.jpg",
        link: "Video/food/140.mp4",
        category: "food",
        title: "معكرونة بالطماطم والجبن",
        ingredients: [
          "Pasta",
          "cheese",
          "Tomatoes  ",
          "garlic",
          "Yoghurt ",
          "ground black pepper",
        ],
        desc: "وصفة طعام لذيذة",
      },
      {
        id: 33,
        type: "recipes",
        name: "Video/food/141_m.jpg",
        link: "Video/food/141.mp4",
        category: "food",
        title: "باستا مع التونة والسبانخ",
        ingredients: [
          "Tagliattele Pasta",
          " Spinach",
          "tuna in oil",
          "olive oil",
          "garlic",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 34,
        type: "recipes",
        name: "Video/food/142_m.jpg",
        link: "Video/food/142.mp4",
        category: "food",
        title: "سلطة مع جرجير و بارميزان",
        ingredients: [
          "Fennel",
          "Parmesan",
          "Arugula",
          "Pine Nuts",
          "ground black pepper",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 35,
        type: "recipes",
        name: "Video/food/143_m.jpg",
        link: "Video/food/143.mp4",
        category: "food",
        title: "تاجلياتيل مع كوسا",
        ingredients: [
          "Tagliatelle  pasta",
          "zucchini",
          "Chili Pepper ",
          "garlic",
          "lemon",
          "olive oil",
          "Parmesan  ",
          "ground black pepper",
          "sea salt",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 36,
        type: "recipes",
        name: "Video/food/145_m.jpg",
        link: "Video/food/145.mp4",
        category: "food",
        title: "سلطة يونانية مع مكرونة وتونة",
        ingredients: [
          "tuna in oil",
          "lemon",
          "cucumber",
          "cherry tomatoes",
          "feta cheese",
          "olive oil",
          "onion",
          "ground black pepper",
          "sea salt",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 37,
        type: "recipes",
        name: "Video/food/146_m.jpg",
        link: "Video/food/146.mp4",
        category: "food",
        title: "معكرونة مخبوزة مع كوسة وجبنة",
        ingredients: [
          "Tagliatelle Pasta",
          "Olive oil",
          "Chili Pepper",
          "Thyme",
          "butter",
          "lemon",
          "ground black pepper",
          "sea salt",
        ],
        desc: "وصفة طعام لذيذة",
      },
      {
        id: 38,
        type: "recipes",
        name: "Video/food/147_m.jpg",
        link: "Video/food/147.mp4",
        category: "food",
        title: "سلطة ايطالية مع المكرونة والتونة",
        ingredients: [
          "Bulgarian  Pepper",
          "olive oil",
          "Arugula",
          "Sun dried tomatoes",
          "Mozzarella cheese",
          "tuna in oil",
          "Pasta farfalle",
          "ground black pepper",
          "sea salt",
        ],
        desc: "وصفة طعام لذيذة",
      },
      {
        id: 39,
        type: "recipes",
        name: "Video/food/148_m.jpg",
        link: "Video/food/148.mp4",
        category: "food",
        title: "شوربة السبانخ مع كرات اللحم",
        ingredients: [
          "spinach",
          "milk",
          "Parmesan",
          "baguette",
          "minced beef",
          "egg",
          "ground black pepper",
          "sea salt",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 40,
        type: "recipes",
        name: "Video/food/149_m.jpg",
        link: "Video/food/149.mp4",
        category: "food",
        title: "سلطة مع بنجر مشوي",
        ingredients: [
          "Beets",
          "olive oil",
          "pine nuts",
          "ground black pepper",
          "sea salt",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 41,
        type: "recipes",
        name: "Video/food/150_m.jpg",
        link: "Video/food/150.mp4",
        category: "food",
        title: "سلمون مشوي مع سلطة جرجير",
        ingredients: [
          "Fresh salmon",
          "cherry tomatoes",
          "arugula",
          "red onion",
          "olive oil",
          "lemon",
          "ground black pepper",
          "sea salt",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 42,
        type: "recipes",
        name: "Video/food/151_m.jpg",
        link: "Video/food/151.mp4",
        category: "food",
        title: "حساء الخبز",
        ingredients: [
          "White bread",
          "red onion",
          "cucumber",
          "tomatoes",
          "yoghurt",
          "Balsamic  vinegar",
          "ground black pepper",
          "sea salt",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 43,
        type: "recipes",
        name: "Video/food/152_m.jpg",
        link: "Video/food/152.mp4",
        category: "food",
        title: "سلطة أرز",
        ingredients: [
          "Red onion",
          "rice",
          "Bulgarian pepper",
          "green basil",
          "olive oil",
          "Balsamic  vinegar",
          "sea salt",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 44,
        type: "recipes",
        name: "Video/food/153_m.jpg",
        link: "Video/food/153.mp4",
        category: "food",
        title: "سلطة سمك بالبرتقال",
        ingredients: [
          "oranges",
          "lemon",
          "leek",
          "walnuts",
          "red wine vinegar",
          "chili paste",
          "smoked salmon",
          "sour cream",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 45,
        type: "recipes",
        name: "Video/food/154_m.jpg",
        link: "Video/food/154.mp4",
        category: "food",
        title: "سلطة الذرة",
        ingredients: [
          "Corn",
          "cherry tomatoes",
          "apples",
          "olives",
          "olive oil",
          "red wine vinegar",
          "ground black pepper",
          "sea salt",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 46,
        type: "recipes",
        name: "Video/food/155_m.jpg",
        link: "Video/food/155.mp4",
        category: "food",
        title: "سلطة الجبن والحمضيات",
        ingredients: [
          "lemon",
          "Grapefruit",
          "oranges",
          "cheese lambert",
          "lime",
          "canned artichokes",
          "cinnamon",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 47,
        type: "recipes",
        name: "Video/food/156_m.jpg",
        link: "Video/food/156.mp4",
        category: "food",
        title: "سمك ابيض مع سلمون مدخن",
        ingredients: [
          "smoked salmon",
          "white fish fillets",
          "green basil",
          "sour cream",
          "olive oil",
          "Balsamic  vinegar",
          "ground black pepper",
          "sea salt",
          "white wine",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 48,
        type: "recipes",
        name: "Video/food/157_m.jpg",
        link: "Video/food/157.mp4",
        category: "food",
        title: "جمبري بالثوم",
        ingredients: [
          "Shrimp",
          "Garlic",
          "Lemon",
          "Chili Papper",
          "Parsley",
          "olive oil",
          "Sea Salt",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 49,
        type: "recipes",
        name: "Video/food/158_m.jpg",
        link: "Video/food/158.mp4",
        category: "food",
        title: "سمان مطهو ببطء بالزعتر",
        ingredients: [
          "Thyme",
          "Quail",
          "bay Leaf",
          "White onion",
          "garlic",
          "Chili Pepper",
          "mustard",
          "olive oil",
          "red wine vinegar",
          "ground black pepper",
          "sea salt",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 50,
        type: "recipes",
        name: "Video/food/159_m.jpg",
        link: "Video/food/159.mp4",
        category: "food",
        title: "شوربة الخيار والبطيخ الباردة",
        ingredients: [
          "Melon",
          "Garlic",
          "Lemon",
          "Olive Oil",
          "Chili Pepper",
          "Arugula",
          "Mint",
          "Cucumber",
          "Yoghurt",
          "ground black pepper",
          "sea salt",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 51,
        type: "recipes",
        name: "Video/food/160_m.jpg",
        link: "Video/food/160.mp4",
        category: "food",
        title: "كرات السمك مع صلصة الفول السوداني",
        ingredients: [
          "White fish fillet",
          "Onion",
          "Eggs",
          "Parsley",
          "White Bread",
          "Wheat Flour",
          "Olive oil",
          "Ground Black Pepper",
          "Almonds",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 52,
        type: "recipes",
        name: "Video/food/161_m.jpg",
        link: "Video/food/161.mp4",
        category: "food",
        title: "بينتكسوس بادرون",
        ingredients: [
          "Carrot",
          "Chicken Fillet",
          "Lemon juice",
          "olive oil",
          "Mushrooms ",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 53,
        type: "recipes",
        name: "Video/food/162_m.jpg",
        link: "Video/food/162.mp4",
        category: "food",
        title: "ترتيليا",
        ingredients: [
          "Onion",
          "Eggs",
          "Chili Pepper",
          "Green basil",
          "Olive oil",
          "ground black pepper",
          "sea salt",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 54,
        type: "recipes",
        name: "Video/food/163_m.jpg",
        link: "Video/food/163.mp4",
        category: "food",
        title: "ترتيليا مع كوسا",
        ingredients: [
          "Zucchini",
          "Onion",
          "Eggs",
          "Green Basil",
          "olive oil",
          "Chili Pepper",
          "ground black pepper",
          "sea salt",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 55,
        type: "recipes",
        name: "Video/food/164_m.jpg",
        link: "Video/food/164.mp4",
        category: "food",
        title: "قرنبيط مع جبن بارميزان",
        ingredients: [
          "Parmesan",
          "Cauliflower",
          "Cream 40%",
          "ground black pepper",
          "sea salt",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 56,
        type: "recipes",
        name: "Video/food/166_m.jpg",
        link: "Video/food/166.mp4",
        category: "food",
        title: "سلطة كوسة مشوية",
        ingredients: [
          "Zucchini ",
          "Parsley",
          "Garlic",
          "Parmesan",
          "Olive oil",
          "Red wine vinegar",
          "ground black pepper",
          "sea salt",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 57,
        type: "recipes",
        name: "Video/food/167_m.jpg",
        link: "Video/food/167.mp4",
        category: "food",
        title: "سلمون مدخن بصلصة برتقال",
        ingredients: [
          "smoked salmon",
          "onion",
          "parsley",
          "saffron",
          "oranges",
          "butter",
          "olive oil",
          "lemon",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 58,
        type: "recipes",
        name: "Video/food/168_m.jpg",
        link: "Video/food/168.mp4",
        category: "food",
        title: "شوربة مينستروني",
        ingredients: [
          "Yellow pepper",
          "tomatoes",
          "paray onion",
          "peas",
          "green beans",
          "olive oil",
          "sea salt",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 59,
        type: "recipes",
        name: "Video/food/169_m.jpg",
        link: "Video/food/169.mp4",
        category: "food",
        title: "صلصة التارتار",
        ingredients: [
          "Capers",
          "Yoghurt",
          "Parsely",
          "White Onion",
          "Thyme",
          "Lemon Juice",
          "Tabasco Sauce",
          "Mustard",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 60,
        type: "recipes",
        name: "Video/food/170_m.jpg",
        link: "Video/food/170.mp4",
        category: "food",
        title: "سلطة البطاطس مع التونة",
        ingredients: [
          "Tuna in oil",
          "Red onion",
          "Pine nuts",
          "Parsley",
          "Garlic",
          "boiled beans",
          "Paprika",
          "olive oil",
          "potatoes",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 61,
        type: "recipes",
        name: "Video/food/171_m.jpg",
        link: "Video/food/171.mp4",
        category: "food",
        title: "ليمون مملح",
        ingredients: [
          "Lemons",
          "Bay Leaf",
          "Olive Oil",
          "Sea salt",
          "Ground black pepper",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 62,
        type: "recipes",
        name: "Video/food/172_m.jpg",
        link: "Video/food/172.mp4",
        category: "food",
        title: "سلطة العدس المتوسطية",
        ingredients: [
          "Lentils",
          "Tomatoes",
          "Garlic",
          "Bulgarian pepper",
          "Onions",
          "olive oil",
          "Balsamic vinegar",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 63,
        type: "recipes",
        name: "Video/food/173_m.jpg",
        link: "Video/food/173.mp4",
        category: "food",
        title: "تبولة سلطة شرقية",
        ingredients: [
          "Rice",
          "Tomatoes",
          "Couscous",
          "Lemon",
          "Cucubmber",
          "ground black pepper",
          "sea salt",
          "olive oil",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 64,
        type: "recipes",
        name: "Video/food/174_m.jpg",
        link: "Video/food/174.mp4",
        category: "food",
        title: "عجة مخبوزة مع بازلاء",
        ingredients: [
          "Peas",
          "Eggs",
          "Parmesan",
          "butter",
          "eggs",
          "olive oil",
          "Milk",
          "Green string beans",
          "ground black pepper",
          "sea salt",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 65,
        type: "recipes",
        name: "Video/food/175_m.jpg",
        link: "Video/food/175.mp4",
        category: "food",
        title: "كسكس مع الخضار",
        ingredients: [
          "Couscous",
          "Avocado",
          "Lemon",
          "Olive Oil",
          "Sea Salt",
          "Chicken Fillet",
          "Cherry Tomatoes",
          "Tomatoes",
          "Lime",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 66,
        type: "recipes",
        name: "Video/food/176_m.jpg",
        link: "Video/food/176.mp4",
        category: "food",
        title: "كاستر فريتاتا",
        ingredients: [
          "Tomatoes",
          "Eggs",
          "Offal Lamb and Turkey",
          "Butter",
          "Olive Oil",
          "ground black pepper",
          "sea salt",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 67,
        type: "recipes",
        name: "Video/food/177_m.jpg",
        link: "Video/food/177.mp4",
        category: "food",
        title: "ريزوتو مع السلمون",
        ingredients: [
          "Rice",
          "Olive Oil",
          "Butter",
          "Onion",
          "TOmatoes",
          "Red Pepper",
          "salmon",
          "cucumber",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 68,
        type: "recipes",
        name: "Video/food/178_m.jpg",
        link: "Video/food/178.mp4",
        category: "food",
        title: "سلطة التونة مع الشمر",
        ingredients: [
          "White Onion",
          "Red pepper",
          "Yellow Pepper",
          "Lettuce",
          "Fennel",
          "Tuna",
          "Celery",
          "Lemon",
          "Olive Oil",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 69,
        type: "recipes",
        name: "Video/food/179_m.jpg",
        link: "Video/food/179.mp4",
        category: "food",
        title: "كمثرى مع صلصة الشوكولاتة",
        ingredients: ["Pear", "Chocolate", "Cream 20%", "Parmesan"],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 70,
        type: "recipes",
        name: "Video/food/180_m.jpg",
        link: "Video/food/180.mp4",
        category: "food",
        title: "ساندوتش دانش",
        ingredients: [
          "Red onion",
          "Mustard",
          "Salmon",
          "Green onion",
          "rye bread",
          "Arugula",
          "Lemon",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 71,
        type: "recipes",
        name: "Video/food/181_m.jpg",
        link: "Video/food/181.mp4",
        category: "food",
        title: "عصير الكيوي",
        ingredients: ["Strawberry", "Kiwi", "Bananas"],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 72,
        type: "recipes",
        name: "Video/food/182_m.jpg",
        link: "Video/food/182.mp4",
        category: "food",
        title: "انتشار الأفوكادو والزيتون",
        ingredients: [
          "olives",
          "Avocado  ",
          "Garlic",
          "Breadcrumbs",
          "olive oil",
          "sea salt",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 73,
        type: "recipes",
        name: "Video/food/183_m.jpg",
        link: "Video/food/183.mp4",
        category: "food",
        title: "ستيك رول مع الأفوكادو",
        ingredients: [
          "Red onion",
          "avocado",
          "garlic",
          "sour cream",
          "paprika",
          "olive oil",
          "horseradish",
          "steak",
          "arugula",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 74,
        type: "recipes",
        name: "Video/food/184_m.jpg",
        link: "Video/food/184.mp4",
        category: "food",
        title: "شاي أحمر بارد",
        ingredients: ["Lemons", "Pomegranate", "Hibiscus", "Tea"],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 75,
        type: "recipes",
        name: "Video/food/185_m.jpg",
        link: "Video/food/185.mp4",
        category: "food",
        title: "الموز في الشوكولاتة",
        ingredients: ["Bananas", "Chocolate", "Peanuts"],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 76,
        type: "recipes",
        name: "Video/food/187_m.jpg",
        link: "Video/food/187.mp4",
        category: "food",
        title: "سوفليه كريمة الفراولة",
        ingredients: ["Milk", "Strawberry", "Gelatin"],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 77,
        type: "recipes",
        name: "Video/food/188_m.jpg",
        link: "Video/food/188.mp4",
        category: "food",
        title: "كريم رافائيللو",
        ingredients: ["White Chocolate", "Coconut", "Condensed Milk", "butter"],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 78,
        type: "recipes",
        name: "Video/food/189_m.jpg",
        link: "Video/food/189.mp4",
        category: "food",
        title: "حلويات بالعسل والبذور",
        ingredients: [
          "Honey",
          "Peanuts",
          "Blue poppy",
          "sunflower seeds",
          "Banana",
        ],
        desc: "وصفة طعام لذيذة",
      },

      {
        id: 79,
        type: "recipes",
        name: "Video/food/190_m.jpg",
        link: "Video/food/190.mp4",
        category: "food",
        title: "ريكوتا تشيز كيك مع توت العليق",
        ingredients: [
          "Raspberries",
          "Ricotta cheese",
          "butter",
          "lemon",
          "eggs",
        ],
        desc: "وصفة طعام لذيذة",
      },
    ],
  },
  games: {
    categories: [
      { cat: "shooter", name: "Shooter" },
      { cat: "arcade", name: "Arcade" },
      { cat: "strategy", name: "Strategy" },
      { cat: "racing", name: "Racing" },
      { cat: "brain_training", name: "Brain Training" },
      { cat: "other", name: "Random" },
    ],
    data: [
      {
        id: 0,
        type: "games",
        title: "zombie shooter",
        desc: "Play online game",
        name: "zombie_shooter/thumb.jpg",
        link: "zombie_shooter/index.html",
        category: "shooter",
      },
      {
        id: 1,
        type: "games",
        title: "zombie buster",
        desc: "Play online game",
        name: "zombie_buster/thumb.jpg",
        link: "zombie_buster/index.html",
        category: "shooter",
      },
      {
        id: 2,
        type: "games",
        title: "wordpuzzle",
        desc: "Play online game",
        name: "wordpuzzle/thumb.jpg",
        link: "wordpuzzle/index.html",
        category: "brain_training",
      },
      {
        id: 3,
        type: "games",
        title: "traffic",
        desc: "Play online game",
        name: "traffic/thumb.jpg",
        link: "traffic/index.html",
        category: "arcade",
      },
      {
        id: 4,
        type: "games",
        title: "touch ball",
        desc: "Play online game",
        name: "touch_ball/thumb.jpg",
        link: "touch_ball/index.html",
        category: "other",
      },
      {
        id: 5,
        type: "games",
        title: "the operators",
        desc: "Play online game",
        name: "the_operators/thumb.jpg",
        link: "the_operators/index.html",
        category: "brain_training",
      },
      {
        id: 6,
        type: "games",
        title: "tank wars",
        desc: "Play online game",
        name: "tank_wars/thumb.jpg",
        link: "tank_wars/index.html",
        category: "shooter",
      },
      {
        id: 7,
        type: "games",
        title: "tank defender",
        desc: "Play online game",
        name: "tank_defender/thumb.jpg",
        link: "tank_defender/index.html",
        category: "shooter",
      },
      {
        id: 8,
        type: "games",
        title: "swat vs zombies",
        desc: "Play online game",
        name: "swat_vs_zombies/thumb.jpg",
        link: "swat_vs_zombies/index.html",
        category: "arcade",
      },
      {
        id: 9,
        type: "games",
        title: "super pongoal",
        desc: "Play online game",
        name: "super_pongoal/thumb.jpg",
        link: "super_pongoal/index.html",
        category: "other",
      },
      {
        id: 10,
        type: "games",
        title: "super cowboy run",
        desc: "Play online game",
        name: "super_cowboy_run/thumb.jpg",
        link: "super_cowboy_run/index.html",
        category: "arcade",
      },
      {
        id: 11,
        type: "games",
        title: "super color lines",
        desc: "Play online game",
        name: "super_color_lines/thumb.jpg",
        link: "super_color_lines/index.html",
        category: "strategy",
      },
      {
        id: 12,
        type: "games",
        title: "supercars puzzle",
        desc: "Play online game",
        name: "supercars_puzzle/thumb.jpg",
        link: "supercars_puzzle/index.html",
        category: "strategy",
      },
      {
        id: 13,
        type: "games",
        title: "stick soldier",
        desc: "Play online game",
        name: "stick_soldier/thumb.jpg",
        link: "stick_soldier/index.html",
        category: "other",
      },
      {
        id: 14,
        type: "games",
        title: "stick monkey",
        desc: "Play online game",
        name: "stick_monkey/thumb.jpg",
        link: "stick_monkey/index.html",
        category: "other",
      },
      {
        id: 15,
        type: "games",
        title: "speed racer",
        desc: "Play online game",
        name: "speed_racer/thumb.jpg",
        link: "speed_racer/index.html",
        category: "racing",
      },
      {
        id: 16,
        type: "games",
        title: "space purge",
        desc: "Play online game",
        name: "space_purge/thumb.jpg",
        link: "space_purge/index.html",
        category: "arcade",
      },
      {
        id: 17,
        type: "games",
        title: "smiles",
        desc: "Play online game",
        name: "smiles/thumb.jpg",
        link: "smiles/index.html",
        category: "arcade",
      },
      {
        id: 18,
        type: "games",
        title: "shoot robbers",
        desc: "Play online game",
        name: "shoot_robbers/thumb.jpg",
        link: "shoot_robbers/index.html",
        category: "shooter",
      },
      {
        id: 19,
        type: "games",
        title: "schitalochka",
        desc: "Play online game",
        name: "schitalochka/thumb.jpg",
        link: "schitalochka/index.html",
        category: "strategy",
      },
      {
        id: 20,
        type: "games",
        title: "robotex",
        desc: "Play online game",
        name: "robotex/thumb.jpg",
        link: "robotex/index.html",
        category: "strategy",
      },
      {
        id: 21,
        type: "games",
        title: "ranger vs zombies",
        desc: "Play online game",
        name: "ranger_vs_zombies/thumb.jpg",
        link: "ranger_vs_zombies/index.html",
        category: "other",
      },
      {
        id: 23,
        type: "games",
        title: "professor bubble",
        desc: "Play online game",
        name: "professor_bubble/thumb.jpg",
        link: "professor_bubble/index.html",
        category: "other",
      },
      {
        id: 24,
        type: "games",
        title: "plumber",
        desc: "Play online game",
        name: "plumber/thumb.jpg",
        link: "plumber/index.html",
        category: "arcade",
      },
      {
        id: 25,
        type: "games",
        title: "ninja run 1",
        desc: "Play online game",
        name: "ninja_run_1/thumb.jpg",
        link: "ninja_run_1/index.html",
        category: "arcade",
      },
      {
        id: 26,
        type: "games",
        title: "ninja run",
        desc: "Play online game",
        name: "ninja_run/thumb.jpg",
        link: "ninja_run/index.html",
        category: "arcade",
      },
      {
        id: 27,
        type: "games",
        title: "ninja copter",
        desc: "Play online game",
        name: "ninja_copter/thumb.jpg",
        link: "ninja_copter/index.html",
        category: "arcade",
      },
      {
        id: 28,
        type: "games",
        title: "ninja blade original",
        desc: "Play online game",
        name: "ninja_blade_original/thumb.jpg",
        link: "ninja_blade_original/index.html",
        category: "other",
      },
      {
        id: 29,
        type: "games",
        title: "mummy candies",
        desc: "Play online game",
        name: "mummy_candies/thumb.jpg",
        link: "mummy_candies/index.html",
        category: "other",
      },
      {
        id: 31,
        type: "games",
        title: "memorygame",
        desc: "Play online game",
        name: "memorygame/thumb.jpg",
        link: "memorygame/index.html",
        category: "brain_training",
      },
      {
        id: 32,
        type: "games",
        title: "match game for kids",
        desc: "Play online game",
        name: "match_game_for_kids/thumb.jpg",
        link: "match_game_for_kids/index.html",
        category: "brain_training",
      },
      {
        id: 33,
        type: "games",
        title: "mad shark",
        desc: "Play online game",
        name: "mad_shark/thumb.jpg",
        link: "mad_shark/index.html",
        category: "other",
      },
      {
        id: 34,
        type: "games",
        title: "kids true color",
        desc: "Play online game",
        name: "kids_true_color/thumb.jpg",
        link: "kids_true_color/index.html",
        category: "other",
      },
      {
        id: 35,
        type: "games",
        title: "jelly match 3",
        desc: "Play online game",
        name: "jelly_match_3/thumb.jpg",
        link: "jelly_match_3/index.html",
        category: "brain_training",
      },
      {
        id: 36,
        type: "games",
        title: "Jellybreak",
        desc: "Play online game",
        name: "Jellybreak/thumb.jpg",
        link: "Jellybreak/index.html",
        category: "other",
      },
      {
        id: 37,
        type: "games",
        title: "indiara skill gold",
        desc: "Play online game",
        name: "indiara_skill_gold/thumb.jpg",
        link: "indiara_skill_gold/index.html",
        category: "other",
      },
      {
        id: 38,
        type: "games",
        title: "hot jewels",
        desc: "Play online game",
        name: "hot_jewels/thumb.jpg",
        link: "hot_jewels/index.html",
        category: "other",
      },
      {
        id: 41,
        type: "games",
        title: "great air battles",
        desc: "Play online game",
        name: "great_air_battles/thumb.jpg",
        link: "great_air_battles/index.html",
        category: "other",
      },
      {
        id: 42,
        type: "games",
        title: "gold miner",
        desc: "Play online game",
        name: "gold_miner/thumb.jpg",
        link: "gold_miner/index.html",
        category: "other",
      },
      {
        id: 43,
        type: "games",
        title: "girl dress up",
        desc: "Play online game",
        name: "girl_dress_up/thumb.jpg",
        link: "girl_dress_up/index.html",
        category: "other",
      },
      {
        id: 44,
        type: "games",
        title: "fruit snake",
        desc: "Play online game",
        name: "fruit_snake/thumb.jpg",
        link: "fruit_snake/index.html",
        category: "arcade",
      },
      {
        id: 45,
        type: "games",
        title: "fruit slasher",
        desc: "Play online game",
        name: "fruit_slasher/thumb.jpg",
        link: "fruit_slasher/index.html",
        category: "other",
      },
      {
        id: 46,
        type: "games",
        title: "flappy bounce",
        desc: "Play online game",
        name: "flappy_bounce/thumb.jpg",
        link: "flappy_bounce/index.html",
        category: "arcade",
      },
      {
        id: 47,
        type: "games",
        title: "flappy ball",
        desc: "Play online game",
        name: "flappy_ball/thumb.jpg",
        link: "flappy_ball/index.html",
        category: "arcade",
      },
      {
        id: 48,
        type: "games",
        title: "fish world match 3",
        desc: "Play online game",
        name: "fish_world_match_3/thumb.jpg",
        link: "fish_world_match_3/index.html",
        category: "brain_training",
      },
      {
        id: 49,
        type: "games",
        title: "fishing frenzy",
        desc: "Play online game",
        name: "fishing_frenzy/thumb.jpg",
        link: "fishing_frenzy/index.html",
        category: "other",
      },
      {
        id: 51,
        type: "games",
        title: "duck shooter",
        desc: "Play online game",
        name: "duck_shooter/thumb.jpg",
        link: "duck_shooter/index.html",
        category: "other",
      },
      {
        id: 52,
        type: "games",
        title: "duck hunter",
        desc: "Play online game",
        name: "duck_hunter/thumb.jpg",
        link: "duck_hunter/index.html",
        category: "other",
      },
      {
        id: 53,
        type: "games",
        title: "crazy runner",
        desc: "Play online game",
        name: "crazy_runner/thumb.jpg",
        link: "crazy_runner/index.html",
        category: "other",
      },
      {
        id: 54,
        type: "games",
        title: "corssword for kids",
        desc: "Play online game",
        name: "corssword_for_kids/thumb.jpg",
        link: "corssword_for_kids/index.html",
        category: "brain_training",
      },
      {
        id: 59,
        type: "games",
        title: "cartoon candy",
        desc: "Play online game",
        name: "cartoon_candy/thumb.jpg",
        link: "cartoon_candy/index.html",
        category: "other",
      },
      {
        id: 60,
        type: "games",
        title: "candy super lines",
        desc: "Play online game",
        name: "candy_super_lines/thumb.jpg",
        link: "candy_super_lines/index.html",
        category: "other",
      },
      {
        id: 61,
        type: "games",
        title: "candy match 3",
        desc: "Play online game",
        name: "candy_match_3/thumb.jpg",
        link: "candy_match_3/index.html",
        category: "brain_training",
      },
      {
        id: 62,
        type: "games",
        title: "btr80",
        desc: "Play online game",
        name: "btr80/thumb.jpg",
        link: "btr80/index.html",
        category: "other",
      },
      {
        id: 63,
        type: "games",
        title: "brick out",
        desc: "Play online game",
        name: "brick_out/thumb.jpg",
        link: "brick_out/index.html",
        category: "other",
      },
      {
        id: 64,
        type: "games",
        title: "brainspeed",
        desc: "Play online game",
        name: "brainspeed/thumb.jpg",
        link: "brainspeed/index.html",
        category: "brain_training",
      },
      {
        id: 65,
        type: "games",
        title: "braingames",
        desc: "Play online game",
        name: "braingames/thumb.jpg",
        link: "braingames/index.html",
        category: "brain_training",
      },
      {
        id: 66,
        type: "games",
        title: "baloon paradise",
        desc: "Play online game",
        name: "baloon_paradise/thumb.jpg",
        link: "baloon_paradise/index.html",
        category: "other",
      },
      {
        id: 67,
        type: "games",
        title: "4 images 1 word",
        desc: "Play online game",
        name: "4_images_1_word/thumb.jpg",
        link: "4_images_1_word/index.html",
        category: "brain_training",
      },
    ],
  },
};
export const appBrands = [
  {
    id: 0,
    title: "Viewbie",
    name: "viewbie",
    primary_color: "#67db8b",
    secondary_color: "#ffffff",
    tertiary_color: "#101517",
    domain: "https://viewbie.com/",
    terms_link: "https://viewbie.com/terms",
    privacy_link: "https://viewbie.com/privacy",
    refund_link: "https://viewbie.com/refund",
    unsub_link: "https://viewbie.com/unsubscribe",
    contact_link: "https://viewbie.com/contact",
  },
  {
    id: 1,
    title: "Castle Vid",
    name: "castlevid",
    primary_color: "#87478b",
    secondary_color: "#ffffff",
    tertiary_color: "#101517",
    domain: "https://castlevid.com/",
    terms_link: "https://cslvid.com/terms",
    privacy_link: "https://cslvid.com/privacy",
    refund_link: "https://cslvid.com/refund",
    unsub_link: "https://cslvid.com/unsubscribe",
    contact_link: "https://cslvid.com/contact",
  },
  {
    id: 2,
    title: "Umbrella Vid",
    name: "umbrellavid",
    primary_color: "#894e87",
    secondary_color: "#ffffff",
    tertiary_color: "#101517",
    domain: "https://umbrellavid.com/",
    terms_link: "https://ublavd.com/terms",
    privacy_link: "https://ublavd.com/privacy",
    refund_link: "https://ublavd.com/refund",
    unsub_link: "https://ublavd.com/unsubscribe",
    contact_link: "https://ublavd.com/contact",
  },
  {
    id: 3,
    title: "Variety Vid",
    name: "varietyvid",
    primary_color: "#BA9FFF",
    secondary_color: "#1d1d1d",
    tertiary_color: "#ffffff",
    domain: "https://varietyvid.com/",
    terms_link: "https://vrtyvd.com/terms",
    privacy_link: "https://vrtyvd.com/privacy",
    refund_link: "https://vrtyvd.com/refund",
    unsub_link: "https://vrtyvd.com/unsubscribe",
    contact_link: "https://vrtyvd.com/contact",
  },
  {
    id: 4,
    title: "Flixive",
    name: "flixive",
    primary_color: "#DB67B4",
    secondary_color: "#0C4889 ",
    tertiary_color: "#ffffff",
    domain: "https://flixive.com/",
    terms_link: "https://flixive.com/terms",
    privacy_link: "https://flixive.com/privacy",
    refund_link: "https://flixive.com/refund",
    unsub_link: "https://flixive.com/unsubscribe",
    contact_link: "https://flixive.com/contact",
  },
  {
    id: 5,
    title: "Zontyy",
    name: "zontyy",
    primary_color: "#fdd05a",
    secondary_color: "#f18711",
    tertiary_color: "#101517",
    domain: "https://zontyy.com/",
    terms_link: "https://zontyy.com/terms",
    privacy_link: "https://zontyy.com/privacy",
    refund_link: "https://zontyy.com/refund",
    unsub_link: "https://zontyy.com/unsubscribe",
    contact_link: "https://zontyy.com/contact",
  },
  {
    id: 6,
    title: "Funfact TV",
    name: "funfacttv",
    primary_color: "#f6921e",
    secondary_color: "#ec562e",
    tertiary_color: "#101517",
    domain: "https://funfacttv.com/",
    terms_link: "https://funfacttv.com/terms",
    privacy_link: "https://funfacttv.com/privacy",
    refund_link: "https://funfacttv.com/refund",
    unsub_link: "https://funfacttv.com/unsubscribe",
    contact_link: "https://funfacttv.com/contact",
  },
  {
    id: 7,
    title: "BoxTVision",
    name: "boxtvision",
    primary_color: "#0de3ac",
    secondary_color: "#98e05f",
    tertiary_color: "#101517",
    domain: "https://boxtvision.com/",
    terms_link: "https://boxtvision.com/terms",
    privacy_link: "https://boxtvision.com/privacy",
    refund_link: "https://boxtvision.com/refund",
    unsub_link: "https://boxtvision.com/unsubscribe",
    contact_link: "https://boxtvision.com/contact",
  },
  {
    id: 8,
    title: "FoxTVision",
    name: "foxtvision",
    primary_color: "#f6921e",
    secondary_color: "#ec562e",
    tertiary_color: "#101517",
    domain: "https://foxtvision.com/",
    terms_link: "https://foxtvision.com/terms",
    privacy_link: "https://foxtvision.com/privacy",
    refund_link: "https://foxtvision.com/refund",
    unsub_link: "https://foxtvision.com/unsubscribe",
    contact_link: "https://foxtvision.com/contact",
  },
  {
    id: 9,
    title: "FernFlix",
    name: "fernflix",
    primary_color: "#98e05f",
    secondary_color: "#0de3ac",
    tertiary_color: "#101517",
    domain: "https://fernflix.com/",
    terms_link: "https://fernflix.com/terms",
    privacy_link: "https://fernflix.com/privacy",
    refund_link: "https://fernflix.com/refund",
    unsub_link: "https://fernflix.com/unsubscribe",
    contact_link: "https://fernflix.com/contact",
  },
  {
    id: 10,
    title: "Flixozone",
    name: "flixozone",
    primary_color: "#f6c7c7",
    secondary_color: "#a08dce",
    tertiary_color: "#101517",
    domain: "https://flixozone.com/",
    terms_link: "https://flixozone.com/terms",
    privacy_link: "https://flixozone.com/privacy",
    refund_link: "https://flixozone.com/refund",
    unsub_link: "https://flixozone.com/unsubscribe",
    contact_link: "https://flixozone.com/contact",
  },
  {
    id: 11,
    title: "Finest Screen",
    name: "bh.finestscreen",
    primary_color: "#fe914c",
    secondary_color: "#ef740a",
    tertiary_color: "#101517",
    domain: "https://finestscreen.com/",
    terms_link: "https://finestscreen.com/terms",
    privacy_link: "https://finestscreen.com/privacy",
    refund_link: "https://finestscreen.com/refund",
    unsub_link: "https://finestscreen.com/unsubscribe",
    contact_link: "https://finestscreen.com/contact",
  },
  {
    id: 12,
    title: "Finest Screen",
    name: "localhost",
    primary_color: "#fe914c",
    secondary_color: "#ef740a",
    tertiary_color: "#101517",
    domain: "https://finestscreen.com/",
    terms_link: "https://finestscreen.com/terms",
    privacy_link: "https://finestscreen.com/privacy",
    refund_link: "https://finestscreen.com/refund",
    unsub_link: "https://finestscreen.com/unsubscribe",
    contact_link: "https://finestscreen.com/contact",
  },
  {
    id: 13,
    title: "Boxplay FR",
    name: "fr.boxplay",
    primary_color: "#007ced",
    secondary_color: "#0978dd",
    tertiary_color: "#201d33",
    domain: "https://boxplay.fun/",
    terms_link: "https://boxplay.fun/termsfr",
    privacy_link: "https://boxplay.fun/privacyfr",
    refund_link: "https://boxplay.fun",
    unsub_link: "https://boxplay.fun/unsubscribefr",
    contact_link: "https://boxplay.fun/contactfr",
  },
  {
    id: 14,
    title: "Boxplay PL",
    name: "pl.boxplay",
    primary_color: "#007ced",
    secondary_color: "#0978dd",
    tertiary_color: "#201d33",
    domain: "https://boxplay.fun/",
    terms_link: "https://boxplay.fun/termspl",
    privacy_link: "https://boxplay.fun/privacypl",
    refund_link: "https://boxplay.fun",
    unsub_link: "https://boxplay.fun/unsubscribepl",
    contact_link: "https://boxplay.fun/contactpl",
  },
  {
    id: 15,
    title: "Boxplay Iraq",
    name: "iq.boxplay",
    primary_color: "#007ced",
    secondary_color: "#0978dd",
    tertiary_color: "#201d33",
    domain: "https://boxplay.fun/",
    terms_link: "https://boxplay.fun/termsiq",
    privacy_link: "https://boxplay.fun/privacyiq",
    refund_link: "https://boxplay.fun",
    unsub_link: "https://boxplay.fun/unsubscribeiq",
    contact_link: "https://boxplay.fun/contactiq",
  },
  {
    id: 16,
    title: "FinestScreen ZA",
    name: "za.finestscreen",
    primary_color: "#007ced",
    secondary_color: "#0978dd",
    tertiary_color: "#201d33",
    domain: "https://finestscreen.com/",
    terms_link: "https://finestscreen.com/termsza",
    privacy_link: "https://finestscreen.com/privacyza",
    refund_link: "https://finestscreen.com",
    unsub_link: "https://finestscreen.com/unsubscribeza",
    contact_link: "https://finestscreen.com/contactza",
  },
  {
    id: 17,
    title: "Netzone ZA",
    name: "za.netzone",
    primary_color: "#5c5c5c",
    secondary_color: "#bab8b5",
    tertiary_color: "#35383b",
    domain: "https://netzone.io/",
    terms_link: "https://netzone.io/terms",
    privacy_link: "https://netzone.io/privacy",
    refund_link: "https://netzone.io",
    unsub_link: "https://netzone.io/unsubscribe",
    contact_link: "https://netzone.io/contact",
  },
  {
    id: 18,
    title: "Boxplay Croatia",
    name: "hr.boxplay",
    primary_color: "#2f608d",
    secondary_color: "#0978dd",
    tertiary_color: "#201d33",
    domain: "https://boxplay.fun/",
    terms_link: "https://boxplay.fun/termshr",
    privacy_link: "https://boxplay.fun/privacyhr",
    refund_link: "https://boxplay.fun",
    unsub_link: "https://boxplay.fun/unsubscribehr",
    contact_link: "https://boxplay.fun/contacthr",
  },
  {
    id: 19,
    title: "Boxplay Finland",
    name: "fi.boxplay",
    primary_color: "#2f608d",
    secondary_color: "#0978dd",
    tertiary_color: "#201d33",
    domain: "https://boxplay.fun/",
    terms_link: "https://boxplay.fun/termsfi",
    privacy_link: "https://boxplay.fun/privacyfi",
    refund_link: "https://boxplay.fun",
    unsub_link: "https://boxplay.fun/unsubscribefi",
    contact_link: "https://boxplay.fun/contactfi",
  },
  {
    id: 20,
    title: "FinestScreen FR",
    name: "fr.finestscreen",
    primary_color: "#fe914c",
    secondary_color: "#ef740a",
    tertiary_color: "#101517",
    domain: "https://finestscreen.com/",
    terms_link: "https://finestscreen.com/termsfr",
    privacy_link: "https://finestscreen.com/privacyfr",
    refund_link: "https://finestscreen.com",
    unsub_link: "https://finestscreen.com/unsubscribefr",
    contact_link: "https://finestscreen.com/contactfr",
  },
  {
    id: 21,
    title: "FinestScreen PL",
    name: "pl.finestscreen",
    primary_color: "#fe914c",
    secondary_color: "#ef740a",
    tertiary_color: "#101517",
    domain: "https://finestscreen.com/",
    terms_link: "https://finestscreen.com/termspl",
    privacy_link: "https://finestscreen.com/privacypl",
    refund_link: "https://finestscreen.com",
    unsub_link: "https://finestscreen.com/unsubscribepl",
    contact_link: "https://finestscreen.com/contactpl",
  },
  {
    id: 22,
    title: "Flix+One",
    name: "app.flixplusone",
    primary_color: "#af569f",
    secondary_color: "#bab8b5",
    tertiary_color: "#35383b",
    domain: "https://flixplusone.com/",
    terms_link: "https://flixplusone.com/terms",
    privacy_link: "https://flixplusone.com/privacy",
    refund_link: "https://flixplusone.com",
    unsub_link: "https://flixplusone.com/unsubscribe",
    contact_link: "https://flixplusone.com/contact",
  },
  {
    id: 23,
    title: "OnVidDemand",
    name: "app.onvidemand",
    primary_color: "#981e11",
    secondary_color: "#bab8b5",
    tertiary_color: "#201d33",
    domain: "https://onvidemand.com/",
    terms_link: "https://onvidemand.com/terms",
    privacy_link: "https://onvidemand.com/privacy",
    refund_link: "https://onvidemand.com",
    unsub_link: "https://onvidemand.com/unsubscribe",
    contact_link: "https://onvidemand.com/contact",
  },
  {
    id: 24,
    title: "Web VidFlix",
    name: "app.webvidflix",
    primary_color: "#5faf92",
    secondary_color: "#bab8b5",
    tertiary_color: "#35383b",
    domain: "https://webvidflix.com/",
    terms_link: "https://webvidflix.com/terms",
    privacy_link: "https://webvidflix.com/privacy",
    refund_link: "https://webvidflix.com",
    unsub_link: "https://webvidflix.com/unsubscribe",
    contact_link: "https://webvidflix.com/contact",
  },
  {
    id: 25,
    title: "Web Vido",
    name: "app.webvido",
    primary_color: "#315fba",
    secondary_color: "#bab8b5",
    tertiary_color: "#201d33",
    domain: "https://webvido.com/",
    terms_link: "https://webvido.com/terms",
    privacy_link: "https://webvido.com/privacy",
    refund_link: "https://webvido.com",
    unsub_link: "https://webvido.com/unsubscribe",
    contact_link: "https://webvido.com/contact",
  },
  {
    id: 26,
    title: "Next VidFlix",
    name: "app.nextvidflix",
    primary_color: "#7ccdd8",
    secondary_color: "#bab8b5",
    tertiary_color: "#35383b",
    domain: "https://nextvidflix.com/",
    terms_link: "https://nextvidflix.com/terms",
    privacy_link: "https://nextvidflix.com/privacy",
    refund_link: "https://nextvidflix.com",
    unsub_link: "https://nextvidflix.com/unsubscribe",
    contact_link: "https://nextvidflix.com/contact",
  },
  {
    id: 27,
    title: "Vid Exprezz",
    name: "app.videxprezz",
    primary_color: "#646bbc",
    secondary_color: "#bab8b5",
    tertiary_color: "#201d33i",
    domain: "https://videxprezz.com/",
    terms_link: "https://videxprezz.com/terms",
    privacy_link: "https://videxprezz.com/privacy",
    refund_link: "https://videxprezz.com",
    unsub_link: "https://videxprezz.com/unsubscribe",
    contact_link: "https://videxprezz.com/contact",
  },
  {
    id: 28,
    title: "NowFlixx",
    name: "app.nowflixx",
    primary_color: "#646bbc",
    secondary_color: "#bab8b5",
    tertiary_color: "#201d33i",
    domain: "https://nowflixx.com/",
    terms_link: "https://nowflixx.com/terms",
    privacy_link: "https://nowflixx.com/privacy",
    refund_link: "https://nowflixx.com",
    unsub_link: "https://nowflixx.com/unsubscribe",
    contact_link: "https://nowflixx.com/contact",
  },
  {
    id: 29,
    title: "Vessel Vid",
    name: "app.vesselvid",
    primary_color: "#646bbc",
    secondary_color: "#bab8b5",
    tertiary_color: "#201d33i",
    domain: "https://vesselvid.com/",
    terms_link: "https://vesselvid.com/terms",
    privacy_link: "https://vesselvid.com/privacy",
    refund_link: "https://vesselvid.com",
    unsub_link: "https://vesselvid.com/unsubscribe",
    contact_link: "https://vesselvid.com/contact",
  },
  {
    id: 30,
    title: "Finest Screen",
    name: "m.ae.finestscreen",
    primary_color: "#fe914c",
    secondary_color: "#ef740a",
    tertiary_color: "#101517",
    domain: "https://finestscreen.com/",
    terms_link: "https://finestscreen.com/terms",
    privacy_link: "https://finestscreen.com/privacy",
    refund_link: "https://finestscreen.com/refund",
    unsub_link: "https://finestscreen.com/unsubscribe",
    contact_link: "https://finestscreen.com/contact",
  },
];
