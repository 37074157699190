import { createContext, Dispatch, useContext } from "react";
import { User } from "../models/GeneralModels";
import { getUser } from "./data";
import { store } from "./storage";

// Fuck this context stuff, use IONIC STORAGE AND WE COOL.

/**
 * Context interface for AuthAuthentication/Authorization
 *
 * @property isAuthenticated
 * @property dispatch
 *
 * @interface
 */
interface AuthDefaultContext {
  isAuthenticated: any;
  setIsAuthenticated: Dispatch<any>;
}

/**
 * Authentication/Authorization context for managing
 * authenticating/ed and authorizing/ed users
 */
export const AuthContext = createContext<AuthDefaultContext>({
  isAuthenticated: null,
  setIsAuthenticated: () => {},
});

export const login = async (username: string) => {
  const user = await getUser(username);
  if (user.status) {
    await store.set("user", user);
    return await isLoggedIn();
  }
  return false;
};

export const isLoggedIn = async () => {
  const user: User = await store.get("user");
  if (user && user.status) {
    return true;
  }
  return false;
};

export const logout = async () => {
  await store.remove("user");
  return true;
};
