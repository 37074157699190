import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonText,
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import { Redirect } from "react-router";
import { useForm } from "react-hook-form";
import "./Login.css";
import { Config } from "../services/config";
import { useContext, useEffect, useMemo } from "react";
import { AuthContext, login } from "../services/auth";
const Login: React.FC = () => {
  useIonViewDidEnter(() => {
    console.log("ionViewDidEnter event fired");
  });

  useIonViewDidLeave(() => {
    console.log("ionViewDidLeave event fired");
  });

  useIonViewWillEnter(() => {
    console.log("ionViewWillEnter event fired");
  });

  useIonViewWillLeave(() => {
    console.log("ionViewWillLeave event fired");
  });

  const authContext = useContext(AuthContext);
  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();
  const urlParams: any = new URLSearchParams(window.location.search);
  const onSubmit = async () => {
    const val = getValues();
    if (await triggerValidation()) {
      const status = await login(val.username);
      if (status) {
        authContext.setIsAuthenticated(status);
      } else {
        alert("Invalid username or password");
      }
    }
  };

  useEffect(() => {
    const checkAuth = async () => {
      if (urlParams.get("m")) {
        const status = await login(urlParams.get("m"));
        if (status) {
          authContext.setIsAuthenticated(status);
        } else {
          alert("Invalid username or password");
        }
      }
    };
    checkAuth();
    // eslint-disable-next-line
  }, []);

  const triggerValidation = async () => {
    return await trigger("username");
  };

  // Better UX for the user
  const ignoreEnter = (e: { preventDefault: () => void; key: any }) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const submitOnEnter = (e: { preventDefault: () => void; key: any }) => {
    console.log("submitOnEnter");
    if (e.key === "Enter") {
      onSubmit();
      e.preventDefault();
    }
  };

  const language = {
    en: {
      header: "Login to",
      label: "Email / Phone number",
      description:
        "Your login email or phone number is asossiated with your email used at signup.",
      login: "Login",
    },
    pl: {
      header: "Zaloguj się do",
      label: "E-mail / numer telefonu",
      description:
        "Twój adres e-mail logowania lub numer telefonu jest powiązany z adresem e-mail użytym podczas rejestracji.",
      login: "Zaloguj",
    },
    fr: {
      header: "Se connecter à",
      label: "E-mail / Numéro de téléphone",
      description:
        "Votre e-mail de connexion ou votre numéro de téléphone est associé à votre e-mail utilisé lors de l'inscription.",
      login: "Connexion",
    },
    ua: {
      header: "تسجيل الدخول إلى",
      label: "رقم البريد الإلكتروني / الهاتف",
      description:
        "يرتبط البريد الإلكتروني لتسجيل الدخول أو رقم الهاتف بالبريد الإلكتروني المستخدم عند التسجيل.",
      login: "تسجيل الدخول",
    },
    hr: {
      header: "Prijava na",
      label: "E-pošta / telefonski broj",
      description:
        "Vaša e-pošta za prijavu ili telefonski broj asosicirani su vašom e-poštom koja se koristi prilikom registracije.",
      login: "Prijava",
    },
    fi: {
      header: "Kirjaudu sisään",
      label: "Sähköpostiosoite / puhelinnumero",
      description:
        "Kirjautumissähköpostiosoitteesi tai puhelinnumerosi yhdistetään rekisteröitymisen yhteydessä käyttämääsi sähköpostiosoitteeseen.",
      login: "Sisäänkirjautuminen",
    },
  };

  let languagetext = language.en;

  if (Config().brand.name === "pl.boxplay") {
    languagetext = language.pl;
  }
  if (Config().brand.name === "pl.finestscreen") {
    languagetext = language.pl;
  }
  if (Config().brand.name === "fr.finestscreen") {
    languagetext = language.fr;
  }
  if (Config().brand.name === "fr.boxplay") {
    languagetext = language.fr;
  }
  if (Config().brand.name === "iq.boxplay") {
    languagetext = language.ua;
  }
  if (Config().brand.name === "hr.boxplay") {
    languagetext = language.hr;
  }
  if (Config().brand.name === "fi.boxplay") {
    languagetext = language.fi;
  }

  return authContext.isAuthenticated ? (
    <Redirect to="/home" />
  ) : (
    <IonContent fullscreen>
      <IonGrid class="login" fixed>
        <IonRow class="testrow ion-justify-content-center">
          <IonCol
            class="ion-align-self-center"
            size="12"
            size-md="6"
            size-lg="4"
          >
            <div>
              <section>
                <header className="ion-text-center ion-padding-bottom">
                  {languagetext.header} {Config().brand.title}
                </header>
                <form>
                  <IonItem fill="outline" mode="md">
                    <IonLabel position="stacked">{languagetext.label}</IonLabel>
                    <IonInput
                      {...register("username", {
                        required: "Email/ phone number is a required field",
                      })}
                      onKeyDown={(e) => ignoreEnter(e)}
                    />

                    {/* <IonInput type='text' required autofocus {...register("username", {})} onKeyPress={keyboardEvents} ></IonInput> */}
                  </IonItem>
                  <IonButton
                    className="ion-margin-top"
                    expand="block"
                    mode="md"
                    onKeyDown={(e) => {
                      submitOnEnter(e);
                    }}
                    onClick={handleSubmit(onSubmit)}
                  >
                    {languagetext.login}
                  </IonButton>
                </form>
              </section>
              <IonText color="dark">
                <p className="small ion-text-center">
                  {languagetext.description}
                </p>
                {Config().brand.name === "pl.boxplay" && (
                  <p className="small ion-text-center">
                    Jeśli jeszcze nie masz dostępu, odwiedź: <br />
                    <a href="https://w.boxplay.fun/I64">
                      https://w.boxplay.fun/I64
                    </a>
                  </p>
                )}
                {Config().brand.name === "hr.boxplay" && (
                  <p className="small ion-text-center">
                    Ako još nemate pristup, posjetite: <br />
                    <a href="https://w.boxplay.fun/I64">
                      https://w.boxplay.fun/I64
                    </a>
                  </p>
                )}
                {Config().brand.name === "fi.boxplay" && (
                  <p className="small ion-text-center">
                    Jos sinulla ei vielä ole käyttöoikeutta, käy osoitteessa:{" "}
                    <br />
                    <a href="https://w.boxplay.fun/I64">
                      https://w.boxplay.fun/I64
                    </a>
                  </p>
                )}
              </IonText>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

export default Login;
